import React, { useState, useRef } from 'react';
import { Calendar } from 'react-multi-date-picker';
import moment from 'moment';
import { PrimaryButton } from '.';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { LuClock5 } from 'react-icons/lu';
import { constants } from '../../helpers';
import './DateTimePickerBW.scss';

const DateTimePickerBW = ({
  date,
  minDate,
  time,
  timeZone,
  onSubmit,
  onCancel,
  rangeShow,
}) => {
  let [selectedDate, setSelectedDate] = useState(
    timeZone
      ? moment
          .tz(moment(date, 'YYYY/MM/DD hh:mm:ss A'), timeZone)
          .format('YYYY/MM/DD hh:mm:ss A')
      : date,
  );
  let [selectedTime, setSelectedTime] = useState(time);
  const timePickerRef = useRef();
  const today = new Date();
  const threeDaysAgo = new Date();
  threeDaysAgo.setHours(0, 0, 0, 0);
  threeDaysAgo.setDate(today.getDate() - 3);
  const oneDayAgo = new Date();
  oneDayAgo.setHours(23, 59, 59, 0);
  oneDayAgo.setDate(today.getDate() - 1);
  const minDateCalendar = new Date('1990-01-01');

  const onApply = () => {
    const time = new Date(selectedTime);
    const originalSelectedDate = new Date(selectedDate);
    originalSelectedDate.setHours(time.getHours()); // set selected hours in selected date
    originalSelectedDate.setMinutes(time.getMinutes()); // set selected minutes in selected date
    originalSelectedDate.setSeconds(time.getSeconds()); // set selected seconds in selected date

    /*
      1. parse date and time according to selected timezone (i.e device timezone) without changing selected date and time.
      2. get date as per the current timezone 
    */
    const convertedDate = new Date(
      moment(originalSelectedDate)
        .parseZone()
        .tz(timeZone, true)
        .toISOString(true),
    );
    onSubmit({
      selectedDate: convertedDate,
      selectedTime: time.getTime(),
      filterdate: convertedDate,
    });
  };

  const onRangeApply = () => {
    if (selectedDate?.length === 0) {
      selectedDate = [];
      onSubmit({ selectedDate });
    } else {
      onSubmit({ selectedDate });
    }
  };

  const onClear = () => {
    setSelectedDate('');
  };

  const onClickTimePicker = () => {
    if (!timePickerRef.current.isOpen) {
      timePickerRef.current.openCalendar();
    } else {
      timePickerRef.current.closeCalendar();
    }
  };

  const onTimeChange = (selectedTime) => {
    setSelectedTime(selectedTime || new Date().setHours(0, 0, 0));
  };

  const checkSelectedDate = () => {
    if (selectedDate?.length === 0 && rangeShow) {
      return date?.length === 0;
    } else {
      return !(selectedDate?.length === 2);
    }
  };

  return (
    <div className="date-time-picker-bw">
      <div
        className={
          rangeShow ? 'date-time-picker-main-range' : 'date-time-picker-main'
        }
      >
        {rangeShow ? (
          <Calendar
            range
            value={selectedDate?.length > 0 ? selectedDate : []}
            onChange={setSelectedDate}
            minDate={
              minDate
                ? new Date(minDate)
                : new Date(moment(new Date(minDateCalendar)))
            }
            monthYearSeparator=" "
            maxDate={new Date()}
            shadow={false}
            highlightToday={true}
            weekStartDayIndex={1}
            headerOrder={['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON']}
            className="custom-calendar-range timeline-calendar"
          />
        ) : (
          <Calendar
            value={selectedDate}
            onChange={setSelectedDate}
            minDate={
              minDate
                ? new Date(minDate)
                : new Date(moment(new Date()).subtract({ days: 90 }))
            }
            monthYearSeparator=" "
            maxDate={date}
            shadow={false}
            highlightToday={true}
            weekStartDayIndex={1}
            headerOrder={['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON']}
            className="custom-calendar timeline-calendar"
          />
        )}
      </div>
      {!rangeShow && (
        <div className="select-time-label">
          {constants.CAMERA_CALENDER_SELECT_TIME_LABEL}
        </div>
      )}
      {!rangeShow && (
        <div className="time-container">
          <DatePicker
            value={selectedTime}
            onChange={(e) => {
              onTimeChange(e);
            }}
            disableDayPicker
            format="hh:mm:ss A"
            plugins={[<TimePicker />]}
            className="custom-timepicker"
            ref={timePickerRef}
            multiple={false}
            editable={false}
          />
          <LuClock5
            size={16}
            color={'black'}
            onClick={() => onClickTimePicker()}
          />
        </div>
      )}
      <div className="line-separator"></div>
      <div>
        {rangeShow ? (
          <div className="button-container">
            {selectedDate?.length === 2 ? (
              <PrimaryButton
                className="btn btn-primary"
                height="44px"
                fontSize="0.875rem"
                onClick={onClear}
                width="76px"
                borderWidth="0px"
                hoverBorderWidth="0px"
                borderRadius="12px"
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                hoverColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                hoverBackgroundColor="transparent"
                backgroundColor="transparent"
                lineHeight="1.25rem"
              >
                {constants.BANDWIDTH_DATE_PICKER_CLEAR_BTN}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                className="btn btn-primary"
                height="44px"
                fontSize="0.875rem"
                onClick={onCancel}
                width="86px"
                borderWidth="0px"
                hoverBorderWidth="0px"
                borderRadius="12px"
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                hoverColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('--primary_40')}
                hoverBackgroundColor="transparent"
                backgroundColor="transparent"
                lineHeight="1.25rem"
              >
                {constants.BANDWIDTH_DATE_PICKER_CANCEL_BTN}
              </PrimaryButton>
            )}
            <PrimaryButton
              className="btn btn-primary"
              height="44px"
              fontSize="0.875rem"
              onClick={onRangeApply}
              disabled={checkSelectedDate()}
              width="60px"
              borderWidth="0px"
              hoverBorderWidth="0px"
              borderRadius="12px"
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              hoverColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              hoverBackgroundColor="transparent"
              backgroundColor="transparent"
              lineHeight="1.25rem"
            >
              {constants.BANDWIDTH_DATE_PICKER_OK_BTN}
            </PrimaryButton>
          </div>
        ) : (
          <PrimaryButton
            className="btn btn-primary mt-4"
            type="submit"
            width="100%"
            height="56px"
            fontSize="1.125rem"
            lineHeight="1.5rem"
            onClick={onApply}
            disabled={!selectedDate || !selectedTime}
          >
            {constants.CAMERA_APPLY_DATE_FILTER}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};
export default DateTimePickerBW;
