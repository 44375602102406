import { constants, Utils } from '../../../helpers';
import { PrimaryButton, SiteModal } from '../../../components/common';
import { deleteBandwidthEstimation } from './BandwidthHelper';
import { useState } from 'react';

const DeleteBWEstimatePopup = ({
  orgId,
  estimateId,
  onHideModal,
  onDeletedSuccess,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteBtnClick = async () => {
    try {
      setLoading(true);
      const resData = await deleteBandwidthEstimation(orgId, estimateId);
      if (resData?.meta.code === 200) {
        onDeletedSuccess();
      } else if (resData?.meta?.userMsg) {
        Utils.vmsLogger().log(resData?.meta?.userMsg);
      }
    } catch (err) {
      Utils.vmsLogger().error(
        'Error while deleting bandwidth estimation:',
        err,
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <SiteModal
      key={'save-bw-estimation-modal'}
      classes="save-bw-estimation-modal bandwidth-popup"
      wrapperClass="bw-estimation-modal-wrapper"
      modalTitle={constants.BANDWIDTH_DELETE_MODAL_TITLE}
      showModal={true}
      hideModal={onHideModal}
      modalFooter={
        <div className="bw-estimation-button-group mt-4">
          <PrimaryButton
            className="btn btn-primary"
            type="submit"
            minHeight="56px"
            height="56px"
            fontSize="1.125rem"
            lineHeight="1.5rem"
            fontWeight="600"
            onClick={onHideModal}
          >
            {constants.BANDWIDTH_DELETE_MODAL_CANCEL_BTN}
          </PrimaryButton>
          <PrimaryButton
            className="btn btn-primary-outline mt-2 delete-save-btn"
            type="button"
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            hoverBorderColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40',
            )}
            hoverColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--brand_white')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            backgroundColor="transparent"
            minHeight="56px"
            height="56px"
            fontSize="1.125rem"
            lineHeight="1.5rem"
            fontWeight="600"
            onClick={handleDeleteBtnClick}
            loader={loading}
          >
            {constants.BANDWIDTH_DELETE_MODAL_DELETE_BTN}
          </PrimaryButton>
        </div>
      }
    >
      <div className="unsaved-changes-message mt-3 mb-4">
        {constants.BANDWIDTH_DELETE_MODAL_MESSAGE}
      </div>
    </SiteModal>
  );
};

export default DeleteBWEstimatePopup;
