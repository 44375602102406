import AvatarBW from './AvatarBW';
import { constants, Utils } from '../../../helpers';
import { ReactComponent as UserIcon } from '../../../assets/images/icons/DefaultAvatarUser.svg';
import './AvatarNameDetails.scss';
import React, { useEffect, useState } from 'react';

const AvatarNameDetails = React.memo(
  ({ allUsers, accountId, size = 'small' }) => {
    const [user, setUser] = useState();
    useEffect(() => {
      const u = allUsers.find((user) => user.accountId === accountId);
      setUser(u);
    }, [allUsers, accountId]);

    const checkAvatarURLValid = (user) => {
      if (user?.avatarURL) {
        if (user?.urlExpiry < Date.now()) {
          return false;
        }
        return Utils.checkURLValidity(user?.avatarURL);
      }
      return false;
    };

    const avatarValueType = checkAvatarURLValid(user) ? 'icon' : 'text';

    const avatarValue = checkAvatarURLValid(user)
      ? user?.avatarURL
      : user?.initials;

    return (
      <div className={`avatar-name-wrapper ${size}`}>
        {user ? (
          <>
            <AvatarBW
              valueType={avatarValueType}
              value={avatarValue}
              size={size}
              avatarStyle="roundedCircle"
            />
            <span
              className={`initials-name ${size}`}
            >{`${user?.firstName} ${user?.lastName}`}</span>
          </>
        ) : (
          <>
            <UserIcon className={`user-icon ${size}`} />
            <span
              className={`initials-name ${size}`}
            >{`${constants.TOOLS_PAGE_REMOVED_USER}`}</span>
          </>
        )}
      </div>
    );
  },
);

export default AvatarNameDetails;
