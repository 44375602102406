import { useState, useEffect } from 'react';
import One from './Steps/StepOneEventType';
import Two from './Steps/StepTwoEventTrigger';
import Three from './Steps/StepThreeDevices';
import Four from './Steps/StepFourSheduled';
import Five from './Steps/StepFiveUsers';
import Six from './Steps/StepSixNotiType';
import { useOrganizations } from '../../../store/OrganizationsStore';
import { useSelector } from 'react-redux';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import { useCustomerOrgLocations } from '../../../store/CustomerOrgLocationsStore';
import {
  AppDefaults,
  roles,
  Utils,
  customEventName,
  getVirtualArea,
} from '../../../helpers';
import { isObject } from 'lodash';
import { useNotificationStore } from '../../../store/CreateNotificationStore';
import { getAllDevicesDataNotifiction } from '../../../store/AccountStoreIDB';
import { getCustomerOrgData } from '../../../store/OrganizationsStoreIDB';
import { getLoggedInUserRole } from '../../../store/reducers/OrganizationsReducer';
import { usePoliciesStore } from '../../../store/policiesStore';

// import {
//   getAllDevicesDataNotifiction,
// } from '../../../store/reducers/AccountReducer';

const NotificationSteps = (props) => {
  const [step, setstep] = useState(props.step);
  const [eventType, setEventType] = useState('systemEvent');
  const [isDeviceExist, setIsDeviceExist] = useState(false);
  const [customLocations, setCustomLocations] = useState([]);
  const [virtualLinesData, setVirtualLinesData] = useState([]);
  const [virtualAreasData, setVirtualAreasData] = useState([]);
  const [customLocationId, setCustomLocationId] = useState('');
  const [custOrgData, setCustOrgData] = useState();
  const [allVirtualLineLocations, setAllVirtualLineLocations] = useState([]);
  const [allVirtualAreaLocations, setAllVirtualAreaLocations] = useState([]);
  const hideModal = () => props.hideModal();
  // const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const loggedInUserRole = useSelector(getLoggedInUserRole);
  // const devicesDetails = useSelector(getAllDevicesDataNotifiction);
  const [devicesDetails, setDevicesDetails] = useState([]);
  const { getSelectedLocation } = useCustomerOrgLocations();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );
  const getEditFlowStatus = useNotificationStore(
    (state) => state.getEditFlowStatus,
  );
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies,
  );
  const getStepsData = useNotificationStore((state) => state.getStepsData);

  const showUserStep = getCustomerOrgPolicies()?.share_notification
    ? true
    : false;
  const showUserCustomNotification = getCustomerOrgPolicies()
    ?.create_custom_event_notification
    ? true
    : false;

  const selectedLocation = getSelectedLocation(loggedInUserData?.accountId);
  const [currentLocationDevices, setCurrentLocationDevices] = useState([]);
  const [stepData, setStepData] = useState([]);
  const nextStep = (data) => {
    if (data != undefined) {
      setStepData(data);
    }
    setstep(step + 1);
  };
  const prevStep = () => {
    setstep(step - 1);
  };

  useEffect(() => {
    if (
      currentLocationDevices?.length &&
      props?.notificationData?.devices?.length
    ) {
      const deviceData = currentLocationDevices.find((item) => {
        return item.deviceId === props?.notificationData?.devices[0]?.deviceId;
      });
      deviceData?.locationId && setCustomLocationId(deviceData?.locationId);
    }
  }, [custOrgData?.locations]);

  useEffect(() => {
    const fetchDevices = async () => {
      const allDevices = await getAllDevicesDataNotifiction();
      allDevices?.length && setIsDeviceExist(true);
      const orgs = await getCustomerOrgData();
      const devices = allDevices || [];
      setCustOrgData(orgs?.[0] || {});
      setDevicesDetails(devices);
      setCurrentLocationDevices(devices);
    };

    fetchDevices();
  }, []);

  useEffect(() => {
    if (props?.notificationData?.triggers?.length) {
      customEventName.includes(props?.notificationData?.triggers[0]?.type) &&
        setEventType('customEvent');
    }
  }, [props?.notificationData]);

  useEffect(() => {
    setstep(step);
  }, [step]);

  useEffect(() => {
    const deviceList = getCurrentLocationDevices();
    if (deviceList?.length > 0) {
      setCurrentLocationDevices(deviceList);
    }
  }, [getSelectedLocation(loggedInUserData?.accountId)]);

  const getCurrentLocationDevices = () => {
    const orgDevice = devicesDetails;
    return orgDevice;
  };

  function getVirtualLinesWithElements(data, setState) {
    if (!Array.isArray(data) || data.length === 0) {
      return null;
    }

    const collectedVirtualLines = [];
    for (const obj of data) {
      const properties = obj.properties;
      if (properties && properties.hasOwnProperty('virtual-line')) {
        const virtualLinesString = properties['virtual-line'];
        if (typeof virtualLinesString === 'string') {
          try {
            const virtualLinesArray =
              JSON.parse(virtualLinesString)?.['virtual-lines'];
            if (
              Array.isArray(virtualLinesArray) &&
              virtualLinesArray.length > 0
            ) {
              if (setState) {
                setState((locationId) => [...locationId, obj.locationId]);
              }
              const newLineArray = virtualLinesArray.map((item) => ({
                ...item,
                areaName: obj.areaName,
                cameraName: obj.deviceName,
                deviceId: obj.deviceId,
                locationId: obj.locationId,
                orgId: obj.orgId,
                locationAreaNames: obj.locationAreaNames,
                connectionStatus: obj.connectionStatus,
                gatewayId: obj.gatewayId,
                ...obj,
              }));
              collectedVirtualLines.push(...newLineArray);
            }
          } catch (e) {
            console.error('Failed to parse virtual-line string:', e);
          }
        }
      }
    }
    return collectedVirtualLines.length > 0 ? collectedVirtualLines : null;
  }

  function getVirtualAreasWithElements(data, setState, customEventType) {
    if (!Array.isArray(data) || data.length === 0) {
      return null;
    }
    const collectedVirtualAreas = [];
    for (const obj of data) {
      const properties = obj.properties;
      if (
        properties &&
        properties.hasOwnProperty('include-area') &&
        customEventType === 'md-include-area'
      ) {
        const virtualLineArea = properties['include-area'];
        if (typeof virtualLineArea === 'string') {
          try {
            const virtualAreasArray =
              JSON.parse(virtualLineArea)?.['include-areas'];
            if (
              Array.isArray(virtualAreasArray) &&
              virtualAreasArray.length > 0
            ) {
              if (setState) {
                setState((locationId) => [...locationId, obj.locationId]);
              }
              const newAreaArray = virtualAreasArray.map((item) => ({
                ...item,
                areaName: obj.areaName,
                cameraName: obj.deviceName,
                deviceId: obj.deviceId,
                locationId: obj.locationId,
                orgId: obj.orgId,
                locationAreaNames: obj.locationAreaNames,
                connectionStatus: obj.connectionStatus,
                gatewayId: obj.gatewayId,
                ...obj,
              }));
              collectedVirtualAreas.push(...newAreaArray);
            }
          } catch (e) {
            console.error('Failed to parse virtual-line string:', e);
          }
        }
      }
      if (
        properties &&
        properties.hasOwnProperty('virtual-area') &&
        customEventType !== 'md-include-area'
      ) {
        const virtualLineArea = properties['virtual-area'];
        if (typeof virtualLineArea === 'string') {
          try {
            const virtualAreasArray =
              JSON.parse(virtualLineArea)?.['virtual-areas'];
            if (
              Array.isArray(virtualAreasArray) &&
              virtualAreasArray.length > 0
            ) {
              if (setState) {
                setState((locationId) => [...locationId, obj.locationId]);
              }
              const newAreaArray = virtualAreasArray
                .filter((item) => item[customEventType] === 'true')
                .map((item) => ({
                  ...item,
                  areaName: obj.areaName,
                  cameraName: obj.deviceName,
                  deviceId: obj.deviceId,
                  locationId: obj.locationId,
                  orgId: obj.orgId,
                  locationAreaNames: obj.locationAreaNames,
                  connectionStatus: obj.connectionStatus,
                  gatewayId: obj.gatewayId,
                  ...obj,
                }));
              collectedVirtualAreas.push(...newAreaArray);
            }
          } catch (e) {
            console.error('Failed to parse virtual-line string:', e);
          }
        }
      }
    }
    return collectedVirtualAreas.length > 0 ? collectedVirtualAreas : null;
  }

  useEffect(() => {
    if (devicesDetails.length) {
      getVirtualLinesWithElements(devicesDetails, setAllVirtualLineLocations);
      getVirtualAreaData(devicesDetails);
    }
  }, [devicesDetails]);

  useEffect(() => {
    if (custOrgData?.locations.length) {
      const locations = custOrgData?.locations.filter((item) =>
        allVirtualLineLocations.includes(item.locationId),
      );
      if (locations.length) {
        const newLocations = locations.map((item) => ({
          locationName: item.locationName,
          locationId: item.locationId,
        }));
        setCustomLocations(newLocations);
      }
    }
  }, [allVirtualLineLocations, custOrgData?.locations]);

  const handleSelectCustomLocation = (locationId) => {
    const locationsdata = devicesDetails.filter(
      (item) =>
        item.locationId === locationId && item.deviceStatus !== 'DEACTIVATED',
    );
    setVirtualLinesData(getVirtualLinesWithElements(locationsdata));
    getVirtualAreaData(locationsdata);
  };

  const getVirtualAreaData = (locationsdata) => {
    if (eventType === 'customEvent' && getStepsData()?.triggers?.length) {
      const selectedType =
        getStepsData()?.triggers[0]?.properties?.eventClass?.[0];
      const customEventType = getVirtualArea()[selectedType];
      if (customEventType && locationsdata.length) {
        setVirtualAreasData(
          getVirtualAreasWithElements(
            locationsdata,
            setAllVirtualAreaLocations,
            customEventType,
          ),
        );
      }
    }
  };

  const loadStepsNotification = () => {
    Utils.vmsLogger(
      'Permission-share-notification',
      getCustomerOrgPolicies()?.share_notification,
    );
    if (loggedInUserRole != roles.ROLE1VMS) {
      if (step === 1) {
        return (
          <>
            <One
              hideModal={() => hideModal()}
              nextStep={nextStep}
              step={step}
              isEdit={getEditFlowStatus()}
              eventType={eventType}
              setEventType={setEventType}
              showUserCustomNotification={
                showUserCustomNotification && customLocations.length > 0
              }
              isDeviceExist={isDeviceExist}
            />
          </>
        );
      }
      if (step === 2) {
        return (
          <>
            <Two
              hideModal={() => hideModal()}
              nextStep={nextStep}
              prevstep={prevStep}
              step={step}
              isEdit={getEditFlowStatus()}
              eventType={eventType}
              setEventType={setEventType}
              setNotificationData={props?.setNotificationData}
              setVirtualAreasData={setVirtualAreasData}
              setCustomLocationId={setCustomLocationId}
            />
          </>
        );
      }
      if (step === 3) {
        return (
          <>
            <Three
              hideModal={() => hideModal()}
              nextStep={nextStep}
              prevstep={prevStep}
              step={step}
              currentLocationDevices={currentLocationDevices}
              stepData={stepData}
              isEdit={getEditFlowStatus()}
              eventType={eventType}
              setEventType={setEventType}
              devicesDetails={devicesDetails}
              customLocations={customLocations}
              handleSelectCustomLocation={handleSelectCustomLocation}
              virtualLinesData={virtualLinesData}
              setVirtualLinesData={setVirtualLinesData}
              virtualAreasData={virtualAreasData}
              setVirtualAreasData={setVirtualAreasData}
              customLocationId={customLocationId}
              setCustomLocationId={setCustomLocationId}
              notificationData={props?.notificationData}
              customEventType={
                getVirtualArea()[
                  getStepsData()?.triggers[0]?.properties?.eventClass?.[0]
                ]
              }
            />
          </>
        );
      }
      if (step === 4) {
        return (
          <>
            <Four
              hideModal={() => hideModal()}
              nextStep={nextStep}
              prevstep={prevStep}
              step={step}
              stepData={stepData}
              role={roles.ROLE2VMS}
              isEdit={getEditFlowStatus()}
            />
          </>
        );
      }
      if (step === 5 && showUserStep) {
        return (
          <>
            <Five
              hideModal={() => hideModal()}
              nextStep={nextStep}
              prevstep={prevStep}
              step={step}
              stepData={stepData}
              isEdit={getEditFlowStatus()}
            />
          </>
        );
      } else if (step === 5 && !showUserStep) {
        return (
          <>
            <Six
              hideModal={() => hideModal()}
              prevstep={prevStep}
              step={step}
              stepData={stepData}
              isEdit={getEditFlowStatus()}
            />
          </>
        );
      }
      if (step === 6) {
        return (
          <>
            <Six
              hideModal={() => hideModal()}
              prevstep={prevStep}
              step={step}
              stepData={stepData}
              isEdit={getEditFlowStatus()}
            />
          </>
        );
      }
    }
  };

  return loadStepsNotification();
};

export default NotificationSteps;
