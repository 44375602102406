import {
  downloadImageForCameraDetails,
  downloadSnapshot,
  generatePlaybackPayload,
  getUnixDate,
  playbackErrorCodes,
} from '../../helpers/commonUtils';
import * as webRTCHandler from '../webRTC/webRTCwsHandler';
import { v4 as uuidv4 } from 'uuid';
import {
  setEndOfStream,
  setNoSnapshotError,
  setSnapShoDownloadLoading,
  setWSSConnection,
} from '../../store/reducers/StreamingReducer';
import store from '../../store/Store';
import { Utils, constants } from '../../helpers';
let wsClient;
let serverDetails;

let playback_uuid;
let correlation_id;
let organizationId;
let deviceID;
let accountID;
let deviceName;
let fetchedSnapShotURL;
let cdnData;
let activeTime;

const handleIncomingError = async (error) => {
  try {
    if (error?.cid) {
      Utils.vmsLogger().log('Playback Error cid: ', error?.cid);
      Utils.vmsLogger().log(
        'Playback Error dev_id: ',
        error?.msg?.properties?.dev_id,
      );
      Utils.vmsLogger().log('Playback Error device name: ', deviceName);
      Utils.vmsLogger().log(
        'Playback Error org_id: ',
        error?.msg?.properties?.org_id,
      );
      Utils.vmsLogger().log(
        'Playback Error code: ',
        error?.msg?.properties?.code,
      );
      Utils.vmsLogger().log(
        'Playback Error description: ',
        error?.msg?.properties?.desc,
      );
    } else {
      Utils.vmsLogger().log('Playback Error', error);
    }

    const errorCode = error?.msg?.properties?.code;

    if (playbackErrorCodes()?.endOfStream?.includes(errorCode)) {
      // when we reach to the end of the stream
      store.dispatch(setEndOfStream(true));
    }
    if (playbackErrorCodes()?.snapShotError?.includes(errorCode)) {
      // when we got error for snapshot request
      if (fetchedSnapShotURL) {
        downloadSnapshot(fetchedSnapShotURL, deviceName, activeTime);
      } else {
        Utils.vmsLogger().log('fetching snapshot from s3 bucket');
        const isAPICalled = await downloadImageForCameraDetails(
          cdnData,
          deviceID,
          activeTime,
          deviceName,
        );
        if (isAPICalled === 'error') {
          store.dispatch(setNoSnapshotError(error?.msg?.properties?.desc));
        }
      }
      store.dispatch(setSnapShoDownloadLoading(false));
    }
  } catch (err) {
    store.dispatch(setSnapShoDownloadLoading(false));
    Utils.vmsLogger().log('handleIncomingError ~ err:', err);
  }
};

export const connectWithWebSocket = (cdnDetails, dId) => {
  serverDetails = cdnDetails;
  const playbackServer = serverDetails?.timeline_server;
  // wsClient = new WebSocket('wss://playback-json.duclo.net');
  wsClient = new WebSocket(
    `${playbackServer.protocol}://${playbackServer.host}:${playbackServer.port}`,
  );
  wsClient.binaryType = 'arraybuffer';

  wsClient.onerror = function (error) {
    // TODO: Delete later
    Utils.vmsLogger().log('Playback: Web Socket Connection Error', error);
  };
  wsClient.onopen = function () {
    store.dispatch(setWSSConnection(true));
  };

  wsClient.onclose = function (e) {
    store.dispatch(setWSSConnection(false));
    sendPauseCVR();
  };

  wsClient.onmessage = function (event) {
    try {
      const data = JSON.parse(event.data);

      switch (data.msg.properties.type) {
        case 'REGISTERED':
          Utils.vmsLogger().log(
            'Playback: Web socket registered for cid: ',
            data?.cid,
            ' ~ ',
            Utils.getTimesinmili(),
          );
          Utils.vmsLogger().log(
            'Playback: Web socket registered for device id: ',
            data?.from,
            ' ~ ',
            Utils.getTimesinmili(),
          );
          Utils.vmsLogger().log(
            'Playback: Web socket registered for organization Id: ',
            organizationId,
            ' ~ ',
            Utils.getTimesinmili(),
          );
          return;

        case 'READY':
          Utils.vmsLogger().log(
            'Playback: Ready State Received ',
            data?.from,
            ' ~ ',
            Utils.getTimesinmili(),
          );
          webRTCHandler.getLocalStream(serverDetails.p2p_server, data?.from);
          return;

        case 'answer':
          Utils.vmsLogger().log(
            'Playback: Answer Received ',
            data?.from,
            ' ~ ',
            Utils.getTimesinmili(),
          );
          webRTCHandler.handleAnswer(data.msg.properties);
          return;

        case 'ice':
          webRTCHandler.handleCandidate(data?.msg?.properties, data?.from);
          return;

        case 'snapshot':
          store.dispatch(setSnapShoDownloadLoading(false));
          const snapURL =
            'data:image/png;base64,' + data?.msg?.properties?.snapshot;
          downloadSnapshot(snapURL, deviceName, activeTime);
          return;

        case 'ERROR':
          handleIncomingError(data);
          return;

        default:
          // As of now no any default conditions
          return;
      }
    } catch {}
  };
};

export const sendRegisterCVR = (
  device_id,
  accountId,
  orgId,
  updated_token,
  devName,
) => {
  playback_uuid = uuidv4();
  correlation_id = uuidv4();
  organizationId = orgId;
  deviceID = device_id;
  accountID = accountId;
  deviceName = devName;

  const register_data = {
    playback_uuid,
    correlation_id,
    device_id,
    accountId,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_REGISTER,
    jwt_token: updated_token,
  };
  const register_payload = generatePlaybackPayload(register_data);
  if (wsClient && wsClient.readyState === 1) {
    wsClient.send(JSON.stringify(register_payload));
  }
};

export const sendPlayCVR = (time, id, speed, isJumpToDate) => {
  const actualTime = isJumpToDate
    ? getUnixDate(time)
    : getUnixDate(time) * 1000;
  correlation_id = uuidv4();
  const data = {
    playback_uuid,
    correlation_id,
    device_id: id ?? deviceID,
    accountId: accountID,
    actualTime,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_PLAY,
    orgId: organizationId,
    direction: constants.PLAYBACK_FORWARD_DIRECTION,
    speed,
  };
  const play_payload = generatePlaybackPayload(data);
  if (wsClient && wsClient.readyState === WebSocket.OPEN) {
    Utils.vmsLogger().log(
      'Playback: Play command send for device Id: ',
      data?.device_id,
      ' ~ ',
      Utils.getTimesinmili(),
    );
    Utils.vmsLogger().log(
      'Playback: Play command send for cid: ',
      data?.correlation_id,
      ' ~ ',
      Utils.getTimesinmili(),
    );
    Utils.vmsLogger().log(
      'Playback: Play command send for org Id: ',
      data?.orgId,
      ' ~ ',
      Utils.getTimesinmili(),
    );
    Utils.vmsLogger().log(
      'Playback: Play command send for timestamp: ',
      data?.actualTime,
      ' ~ ',
      Utils.getTimesinmili(),
    );
    wsClient.send(JSON.stringify(play_payload));
  }
};

export const sendPauseCVR = (id) => {
  const data = {
    playback_uuid,
    correlation_id,
    device_id: id ?? deviceID,
    accountId: accountID,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_STOP,
    orgId: organizationId,
  };
  const pause_payload = generatePlaybackPayload(data);
  webRTCHandler.handleLeaveCall();
  if (wsClient && wsClient.readyState === WebSocket.OPEN) {
    wsClient.send(JSON.stringify(pause_payload));
  }
};

export const sendSnapshotRequest = (id, snapshotTime, snapshotURL, cdnInfo) => {
  fetchedSnapShotURL = snapshotURL;
  cdnData = cdnInfo;
  activeTime = snapshotTime;
  const actualTime = getUnixDate(snapshotTime) * 1000;
  const data = {
    playback_uuid,
    correlation_id,
    device_id: id ?? deviceID,
    accountId: accountID,
    actualTime,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_SNAPSHOT,
    orgId: organizationId,
  };
  const snapshot_payload = generatePlaybackPayload(data);
  if (wsClient && wsClient.readyState === WebSocket.OPEN) {
    wsClient.send(JSON.stringify(snapshot_payload));
  }
};

// ========== TODO WILL REMOVE LATER ======================
// export const sendPauseTrickPlay = () => {
//   const data = {
//     playback_uuid,
//     correlation_id,
//     device_id: deviceID,
//     accountId: accountID,
//     type: constants.WEBSOCKET_PLAYBACK_EVENT_TRICK_PLAY,
//     orgId: organizationId,
//     mode: "PAUSE"
//   };
//   const pausetrick_payload = generatePlaybackPayload(data);
//   webRTCHandler.handleLeaveCall();
//   if (wsClient && wsClient.readyState === WebSocket.OPEN) {
//     wsClient.send(JSON.stringify(pausetrick_payload));
//   }
// }

// export const sendResumeTrickPlay = () => {
//   const data = {
//     playback_uuid,
//     correlation_id,
//     device_id: deviceID,
//     accountId: accountID,
//     type: constants.WEBSOCKET_PLAYBACK_EVENT_TRICK_PLAY,
//     orgId: organizationId,
//     mode: "RESUME"
//   };
//   const resume_payload = generatePlaybackPayload(data);
//   webRTCHandler.handleLeaveCall();
//   if (wsClient && wsClient.readyState === WebSocket.OPEN) {
//     wsClient.send(JSON.stringify(resume_payload));
//   }
// }

export const sendSpeedCommand = (speed) => {
  const data = {
    playback_uuid,
    correlation_id,
    device_id: deviceID,
    accountId: accountID,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_TRICK_PLAY,
    orgId: organizationId,
    speed: speed,
    direction: constants.PLAYBACK_FORWARD_DIRECTION,
  };
  const trickplay_payload = generatePlaybackPayload(data);
  if (speed) {
    wsClient.send(JSON.stringify(trickplay_payload));
  }
};

export const sendWebRTCOffer = (offer) => {
  const payloadData = {
    playback_uuid: playback_uuid,
    correlation_id: correlation_id,
    device_id: deviceID,
    accountId: accountID,
    orgId: organizationId,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_OFFER,
    sdp: offer.sdp,
  };
  Utils.vmsLogger().log(
    'Playback: offer send ',
    deviceID,
    ' ~ ',
    Utils.getTimesinmili(),
  );
  const offer_payload = generatePlaybackPayload(payloadData);
  wsClient.send(JSON.stringify(offer_payload));
};

export const sendWebRTCCandidate = (event) => {
  const payloadData = {
    playback_uuid: playback_uuid,
    correlation_id: correlation_id,
    device_id: deviceID,
    accountId: accountID,
    orgId: organizationId,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_ICE,
    candidate: event.candidate,
  };
  const candidate_payload = generatePlaybackPayload(payloadData);
  wsClient.send(JSON.stringify(candidate_payload));
};

// TODO : THIS CODE WILL BE UPDATED
// export const sendSkipForwardPayload = (skipSecond) => {
//   const data = {
//     playback_uuid,
//     correlation_id,
//     device_id: deviceID,
//     accountId: accountID,
//     type: constants.PLAYBACK_TRICK_PLAY,
//     orgId: organizationId,
//     skip: skipSecond
//   };
//   const skip_payload = generatePlaybackPayload(data);

//   if (wsClient && wsClient.readyState === WebSocket.OPEN) {
//     Utils.vmsLogger().log("skip_payload:", skip_payload);
//     wsClient.send(JSON.stringify(skip_payload));
//   }
// }

export const disconnectWithWebSocket = () => {
  if (wsClient) {
    wsClient.close();
  }
  webRTCHandler.handleLeaveCall();
};

export const checkWSSConnection = () => {
  if (wsClient) return true;
};
