import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './ViewDetails.scss';
import CameraAvailableDetails from './AnalyticsDetails/CameraAvailableDetails';
import { Row, Container, Col } from 'react-bootstrap';
import { RxCross1 } from 'react-icons/rx';
import CameraBitrateDetails from './AnalyticsDetails/CameraBitrateDetails';
import { useMemo } from 'react';
import OccupacyDetails from './AnalyticsDetails/OccupacyDetails';
import UserLoginDetials from './AnalyticsDetails/UserLoginDetials';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useSelector } from 'react-redux';
// import { getAllDevicesData } from '../../store/reducers/AccountReducer';
import { isObject } from 'lodash';
import { AppDefaults, Utils } from '../../helpers';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { getAllDevicesData } from '../../store/AccountStoreIDB';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import { Avatar } from '../../components/common';
import { HiUserCircle } from 'react-icons/hi';

const ViewDetails = ({
  deviceList,
  id,
  orgId,
  hideModal,
  pastRange,
  pastRangeLabel,
  duration,
  pastDurationLabel,
  parentFilter,
}) => {
  const myDivRef = useRef(null);
  let [searchParams] = useSearchParams();
  const location = useLocation();
  // const getCustomerOrgData = useOrganizations(
  //   (state) => state.getCustomerOrgData
  // );
  // const custOrgData = orgId
  //   ? getCustomerOrgData().find((user) => user.orgId === orgId)
  //   : getCustomerOrgData()[0];
  const [custOrgData, setCustOrgData] = useState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const { getSelectedLocation } = useCustomerOrgLocations();
  const accountId = loggedInUserData?.accountId;

  let currentSelectedLocation = getSelectedLocation(accountId);
  // const devicesDetails = useSelector(getAllDevicesData);
  const [devicesDetails, setDevicesDetails] = useState([]);
  const [devices, setDevices] = useState(deviceList ? deviceList : []);

  const navigate = useNavigate();

  useEffect(() => {
    const getCustOrgs = async () => {
      const orgs = await getCustomerOrgData();
      setCustOrgData(
        orgId ? orgs?.find((user) => user.orgId === orgId) : orgs?.[0]
      );
    };
    getCustOrgs();
  }, []);

  const getCurrentLocationDevices = () => {
    const orgDevice = devicesDetails;
    const location = currentSelectedLocation;
    if (isObject(location)) {
      const devicesList =
        location?.locationId === AppDefaults.ALL_LOCATIONS_ID
          ? orgDevice?.filter((device) => device?.deviceStatus === 'CLAIMED')
          : orgDevice?.filter(
              (device) =>
                device?.locationId === location?.locationId &&
                device?.deviceStatus === 'CLAIMED'
            );
      return devicesList;
    } else {
      return orgDevice?.filter((device) => device?.deviceStatus === 'CLAIMED');
    }
  };

  useEffect(() => {
    const fetchDevices = async () => {
      const devices = await getAllDevicesData();
      setDevicesDetails(devices);
    };
    fetchDevices();
  }, []);

  useEffect(() => {
    if (!devicesDetails || devicesDetails?.length === 0) {
      return;
    }
    const deviceList = getCurrentLocationDevices();
    if (!devices?.length && deviceList?.length > 0) {
      setDevices(deviceList);
    }
  }, [devicesDetails]);

  const getUserIcon = (row) => {
    if (!(row?.firstName && row?.lastName)) {
      return (
        <HiUserCircle
          style={{
            height: '30px',
            width: '30px',
            transform: 'translate(0, -2px)',
          }}
        />
      );
    }

    let initials = Utils.getInitialsFromFullName(
      `${row?.firstName?.trim()} ${row?.lastName?.trim()}`
    );
    if (Boolean(initials.length)) {
      return initials;
    }
  };

  const columnsData = useMemo(
    () => [
      {
        Header: 'Month, Year',
        accessor: 'monthYear',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
      {
        Header: 'Location, Area',
        accessor: 'locationArea',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
      {
        Header: 'Camera',
        accessor: 'deviceName',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
      {
        Header:
          id == 1
            ? 'Online Percentage'
            : id == 2
            ? 'Minutes Offline'
            : id == 3
            ? 'Average Bitrate'
            : id == 4 && 'People Count',
        accessor: 'percentageOnline',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
    ],
    []
  );

  const columnsDataLogin = useMemo(
    () => [
      {
        Header: 'Month, Year',
        accessor: 'monthYear',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
      {
        Header: 'Name',
        accessor: 'userName',
        Cell: (props) => {
          let dataRow = props?.row?.original;
          if (props.value) {
            return (
              <div className="username-cell">
                <Avatar
                  valueType={dataRow?.image ? 'icon' : 'text'}
                  value={dataRow?.image ? dataRow?.image : getUserIcon(dataRow)}
                  size="medium"
                  avatarStyle="roundedCircle"
                />
                {props.value}
              </div>
            );
          } else return '-';
        },
      },
      {
        Header: 'Login Count',
        accessor: 'userCount',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
    ],
    []
  );

  useEffect(() => {
    scrollToDiv();
  }, []);

  const scrollToDiv = () => {
    if (myDivRef?.current) {
      myDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div ref={myDivRef} className="App analytics">
      <div className="main-wrapper">
        <div className="analytics-background">
          <RxCross1
            className="icon-cross-btn"
            size={25}
            onClick={() => hideModal()}
          />
          <div>
            <Row className="justify-content-md-center">
              <Col xs lg="12">
                <Row className="analytics-wrapper">
                  <div className="analytics-container details">
                    {/* Retail Performance */}
                    {(id == 1 || id == 2) && (
                      <CameraAvailableDetails
                        selectedPastRange={pastRange}
                        selectedPastRangeLabel={pastRangeLabel}
                        selectedDuration={duration}
                        selectedPastDuration={pastDurationLabel}
                        custOrgData={custOrgData}
                        devices={devices}
                        id={id}
                        columnsData={columnsData}
                        parentFilter={parentFilter}
                      />
                    )}
                    {id == 3 && (
                      <CameraBitrateDetails
                        selectedPastRange={pastRange}
                        selectedPastRangeLabel={pastRangeLabel}
                        selectedDuration={duration}
                        selectedPastDuration={pastDurationLabel}
                        custOrgData={custOrgData}
                        devices={devices}
                        columnsData={columnsData}
                        parentFilter={parentFilter}
                      />
                    )}
                    {id == 4 && (
                      <OccupacyDetails
                        custOrgData={custOrgData}
                        devices={devices}
                        columnsData={columnsData}
                      />
                    )}
                    {id == 5 && (
                      <UserLoginDetials
                        selectedPastRange={pastRange}
                        selectedPastRangeLabel={pastRangeLabel}
                        selectedDuration={duration}
                        selectedPastDuration={pastDurationLabel}
                        custOrgData={custOrgData}
                        devices={devices}
                        columnsData={columnsDataLogin}
                        parentFilter={parentFilter}
                      />
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
