import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';
import { constants, Utils } from '../../helpers';
import { usePoliciesStore } from '../../store/policiesStore';
import DefaultPolicies from '../../store/reducers/DefaultPolicies';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useDispatch } from 'react-redux';
import { setOrgInfo } from '../../store/reducers/OrganizationsReducer';

const OrgListTable = ({ columns, data, defaultSortByData = [] }) => {
  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies,
  );
  const setCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.setCustomerOrgUsers,
  );
  const dispatch = useDispatch();
  // Use the useTable Hook to send the columns and data to build the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: defaultSortByData,
        },
      },
      useSortBy,
    );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const channel = new BroadcastChannel('organization-details-channel');

    channel.onmessage = (event) => {
      if (event.data?.url && event.data.url !== location.pathname) {
        navigate(event.data.url);
      }
    };

    return () => {
      channel.close();
    };
  }, [location?.pathname, navigate]);

  /*
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div className="table-responsive">
      <table className="table table-borderless" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={`${
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'columnheader-asc'
                        : 'columnheader-desc'
                      : 'columnheader-asc'
                  }`}
                  onClick={() => {
                    column.toggleSortBy(!column.isSortedDesc, false);
                  }}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSortedDesc ? (
                      <VscChevronDown size={16} strokeWidth="1px" />
                    ) : (
                      <VscChevronUp size={16} strokeWidth="1px" />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => {
                  let primaryOrgData = row?.original;
                  dispatch(
                    setOrgInfo({
                      orgId: primaryOrgData?.orgId,
                      orgName: primaryOrgData?.orgName,
                      shortName: primaryOrgData?.shortName,
                    }),
                  );
                  // === Making the customer users data blank so that new data can be loaded there in the detail page.
                  setCustomerOrgUsers([]);
                  let policies = row?.original?.policies;
                  let updatedPoliciesData = Utils.mapUserPolicies(
                    DefaultPolicies,
                    policies,
                  );
                  setCustomerOrgPolicies(updatedPoliciesData);

                  let redirectUrl = `/customers/dashboard.html?orgId=${row.original.orgId}&orgName=${row.original.orgName}`;

                  const channel = new BroadcastChannel(
                    'organization-details-channel',
                  );
                  channel.postMessage({ url: redirectUrl });
                  channel.close();

                  navigate(redirectUrl);
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
          {rows?.length === 0 && (
            <tr className="no-data-found">
              <td colSpan={columns?.length}>
                {constants.NO_MANAGE_ORG_CUSTOMER_FOUND_TITLE}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default OrgListTable;
