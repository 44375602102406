import axios from 'axios';
import { Utils } from '../../../helpers';

export const getBandwidthEstimation = async (orgId, estimateId) => {
  const abortControler = new AbortController();
  const signal = abortControler.signal;

  try {
    const route = `partner/orgs/${orgId}/bandwidth/estimate/${estimateId}`;
    const response = await axios.get(route, {
      ...Utils.requestHeader(),
      signal: signal,
    });
    return response?.data;
  } catch (error) {
    Utils.vmsLogger().error('Error while saving bandwidth estimation:', error);
    throw error;
  }
};

export const addBandwidthEstimation = async (orgId, reqBody) => {
  const abortControler = new AbortController();
  const signal = abortControler.signal;

  try {
    const route = `partner/orgs/${orgId}/bandwidth/estimate`;
    const response = await axios.post(route, reqBody, {
      ...Utils.requestHeader(),
      signal: signal,
    });
    return response?.data;
  } catch (error) {
    Utils.vmsLogger().error('Error while saving bandwidth estimation:', error);
    throw error;
  }
};

export const updateBandwidthEstimation = async (orgId, estimateId, reqBody) => {
  const abortControler = new AbortController();
  const signal = abortControler.signal;

  try {
    const route = `partner/orgs/${orgId}/bandwidth/estimate/${estimateId}`;
    const response = await axios.put(route, reqBody, {
      ...Utils.requestHeader(),
      signal: signal,
    });
    return response?.data;
  } catch (error) {
    Utils.vmsLogger().error('Error while saving bandwidth estimation:', error);
    throw error;
  }
};

export const deleteBandwidthEstimation = async (orgId, estimateId) => {
  const abortControler = new AbortController();
  const signal = abortControler.signal;

  try {
    const route = `partner/orgs/${orgId}/bandwidth/estimate/${estimateId}`;
    const response = await axios.delete(route, {
      ...Utils.requestHeader(),
      signal: signal,
    });
    return response?.data;
  } catch (error) {
    Utils.vmsLogger().error('Error while saving bandwidth estimation:', error);
    throw error;
  }
};
