import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { TextField } from '../../components/forms';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { HiArrowDownTray } from 'react-icons/hi2';
import { PiQuestionLight } from 'react-icons/pi';
import { FiCameraOff } from 'react-icons/fi';
import { TbTrash } from 'react-icons/tb';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { HiOutlinePencil } from 'react-icons/hi';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { MdOutlineAddAPhoto } from 'react-icons/md';
import { editViewButton } from '../../assets/images';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { ReactComponent as CameraIcon } from '../../assets/images/icons/Camera.svg';
import { ReactComponent as CameraDisabled } from '../../assets/images/icons/CameraDisabled.svg';
import { ReactComponent as VideoCameraIcon } from '../../assets/images/icons/VideoCamera.svg';
import { ReactComponent as VideoCameraIconDisabled } from '../../assets/images/icons/VideoCameraDisabled.svg';
import { ReactComponent as LicenseExpireWarning } from '../../assets/images/LicenseExpireWarning.svg';
import { ReactComponent as CheckedIcon } from '../../assets/images/CheckedBackgroundIcon.svg';
import { ReactComponent as CrossBackgroudIcon } from '../../assets/images/CrossBackgroudIcon.svg';
import axios from 'axios';
import {
  Header,
  PrimaryButton,
  SiteSpinner,
  SiteModal,
  SiteToast,
  TextBlock,
} from '../../components/common';

import {
  AppDefaults,
  constants,
  ClipTypeEnum,
  IncidentTypeEnum,
  LicenseStatuses,
  OfferingTypeEnum,
  ServiceTypeEnum,
  Utils,
  EntitlementsTypeEnum,
} from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useOrganizations } from '../../store/OrganizationsStore';
import useEventsStore from '../../store/EventsStore';
import useLicensesStore from '../../store/LicensesStore';
import { useAppsStore } from '../../store/AppsStore';
import {
  getCDNInfo,
  getPlatformInfo,
  getWSSConnections,
  removeMQTTPeerConnections,
  removeRemoteStreams,
  removeRemoteStreamsAudio,
  setMetaData,
  getMQTTConnection,
  resetRecievedOffers,
  setPlatformInfo,
  setIsActiveStream,
} from '../../store/reducers/StreamingReducer';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';

import './Cameras.scss';
import CameraWallDevices from './CameraWallDevices';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import {
  getIsEditModeActive,
  getSelectedView,
  getViewList,
  resetViewsData,
  setIsEditModeActive,
  setIsLeftMenuOpen,
  setIsOnDeviceWallPage,
  setSelectedView,
  setViewList,
} from '../../store/reducers/ViewsReducer';
import DeviceWallTimeline from './components/DeviceWallTimeline';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  getLatestEventsForDots,
  getMetaDataForEvents,
  // resetEvents,
  setLatestEventsForDots,
  setMetaDataForEvents,
} from '../../store/reducers/EventsReducer';
import { disconnectWithMQTT } from '../../utils/connection/mqttConnection';
import {
  connectWithWebSocket,
  disconnectWithWebSocket,
  sendPauseCVR,
} from '../multilive/components/playback/wssConnection/wssConnection';
import {
  setKeepAliveRequest,
  getKeepAliveRequest,
  setSubscribeAreasRequest,
  getTOSPolicyAcceptionFlag,
} from '../../store/reducers/AccountReducer';
import DeleteView from './modalForms/DeleteView';
import MaxViewDevices from './modalForms/MaxViewDevices';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { usePoliciesStore } from '../../store/policiesStore';
import { CiGrid41 } from 'react-icons/ci';
import {
  getCameraWallDevices,
  getCustomerOrgDevices,
} from '../../store/CustomerOrgDevicesStoreIDB';
import { setSelectedOrganization } from '../../store/AccountStoreIDB';
import { getCustomerOrgData } from '../../store/OrganizationsStoreIDB';
import { observerInstance } from '../../store/indexDB/observer';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import { setSnapshotImage } from '../../store/StreamingStoreIDB';
import { setSentOfferDevice } from '../multilive/components/live/webRTCHandler/webRTCmqttHandler';

const drawerWidth = 224;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Cameras = () => {
  const formikRef = useRef();
  const notificationAbortCtrlrRef = useRef(null);
  const getSavedViewAbortCtrlrRef = useRef(null);
  const addViewAbortCtrlrRef = useRef(null);
  const updateViewAbortCtrlrRef = useRef(null);
  const deleteViewAbortCtrlrRef = useRef(null);
  const renameViewAbortCtrlrRef = useRef(null);
  const moveToAbortCtrlrRef = useRef(null);
  const entitlementControllersRef = useRef([]);
  let fullscreenRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handle = useFullScreenHandle();
  const [searchParams] = useSearchParams();
  const cdnInfo = useSelector(getCDNInfo);
  const viewList = useSelector(getViewList);
  const selectedView = useSelector(getSelectedView);
  const eventCategory = useEventsStore((state) => state.eventCategory);
  const eventsData = useSelector(getLatestEventsForDots);
  const deviceMetaData = useSelector(getMetaDataForEvents);
  const isEditModeActive = useSelector(getIsEditModeActive);
  const [isUserViewLoading, setIsUserViewLoading] = useState(true);
  const [filteredExpiredLicenses, setFilteredExpiredLicenses] = useState([]);
  const [showExpiredLicenseWarning, setShowExpiredLicenseWarning] =
    useState(false);
  const [actionExpiredLicenseWarning, setActionExpiredLicenseWarning] =
    useState(false);
  const [warningShown, setWarningShown] = useState(false);
  const [devicesDetails, setDevicesDetails] = useState([]);
  const [customerOrgDevices, setCustomerOrgDevices] = useState([]);
  const wssConnections = useSelector(getWSSConnections);
  const [allStartDate, setAllStartDate] = useState([]);
  const [showLocation, setShowLocation] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [launchPadOnly, setLaunchPadOnly] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [categoryDashboard, setCategoryDashboard] = useState([]);
  const [open, setOpen] = useState(true);
  const [isOpenBeforeDragOver, setIsOpenBeforeDragOver] = useState(true);
  const [isDevicesOptionSelected, setIsDevicesOptionSelected] = useState(true);
  const [isAddNewViewClicked, setIsAddNewViewClicked] = useState(false);
  const [currentSession, setCurrentSession] = useState(uuidv4());
  const [editView, setEditView] = useState(null);
  const [selectedViewDevices, setSelectedViewDevices] = useState(
    [],
    // selectedView?.devices?.length ? selectedView?.devices : []
  );
  const [totalDeviceCount, setTotalDeviceCount] = useState(0);
  const [currentViewDeviceList, setCurrentViewDeviceList] = useState([]);
  const [isUpdatingView, setIsUpdatingView] = useState(false);
  const [isAddingView, setIsAddingView] = useState(false);
  const [isRenamingView, setIsRenamingView] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMaxDevciesModal, setShowMaxDevciesModal] = useState(false);
  const [showJumpToDateModal, setShowJumpToDateModal] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [newViewDragMode, setNewViewDragMode] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const {
    getLicenses,
    setLicenses,
    setLicensesExpiredPopUpShow,
    getLicensesExpiredPopUpShow,
  } = useLicensesStore();
  const TOSPolicyAcceptionFlag = useSelector(getTOSPolicyAcceptionFlag);
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData,
  );
  const accountId = loggedInUserData?.accountId;
  const getCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsers,
  );
  const { getCustomerOrgLocations, getCustomerOrgLocationsData } =
    useCustomerOrgLocations();
  const { getLoggedInUserPolicies } = usePoliciesStore();
  const userPolicies = getLoggedInUserPolicies();
  const setUserPushData = useOrganizations((state) => state.setUserPushData);
  const {
    getEventCVRMode,
    setSelectedEventStore,
    setEventFromSearch,
    setEventCVRMode,
    setLinkingData,
    getLinkingData,
  } = useEventsStore();
  const [orgDetails, setOrgDetails] = useState();
  const getPlatformDetails = useSelector(getPlatformInfo);
  const viewId = searchParams.get('viewId');
  const [cameraWallDevices, setCameraWallDevices] = useState([]);
  const MQTTConnectionStatus = useSelector(getMQTTConnection);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const keepAlive = useSelector(getKeepAliveRequest);
  const selectedViewTimerDeviceIds = useRef([]);
  let keepAliveTimer = null;
  const isTimerRunning = useRef(false);
  const getApps = useAppsStore((state) => state.getApps);
  let helpData = useAppsStore((state) => state.helpData);
  const [entitleData, setEntitleData] = useState();

  useEffect(() => {
    const setLicenseData = async () => {
      if (orgDetails?.orgId) {
        if (getLicenses()?.length === 0) {
          getLicensesCall();
        }
      }
    };
    setLicenseData();
  }, [orgDetails?.orgId]);

  useEffect(() => {
    updateSnapforSingleView();
    dispatch(setIsActiveStream(false));
  }, []);

  const updateSnapforSingleView = async () => {
    await setSnapshotImage({});
  };

  const loadCustomerOrgData = useCallback(async () => {
    const orgs = await getCustomerOrgData();
    setOrgDetails(orgs?.[0] || {});
  }, []);

  const getLicensesCall = async () => {
    await setLicenses(`/user/orgs/${orgDetails?.orgId}/licenses`);
  };

  const loadCustomerOrgDevices = useCallback(async () => {
    const devices = await getCustomerOrgDevices();
    setCustomerOrgDevices(devices);
    const camWallDevices = await getCameraWallDevices();
    setCameraWallDevices(camWallDevices);
    combineDeviceAndLocationData(camWallDevices);
  }, []);

  const debouncedLoadCustomerOrgData = useDebouncedCallback(
    loadCustomerOrgData,
    100,
  );
  const debouncedLoadCustomerOrgDevices = useDebouncedCallback(
    loadCustomerOrgDevices,
    100,
  );

  useEffect(() => {
    const handleUpdate = async (data) => {
      if (data.key === 'customerOrgData') {
        await debouncedLoadCustomerOrgData();
      }
      if (data.key === 'customerOrgDevices') {
        await debouncedLoadCustomerOrgDevices();
      }
    };

    observerInstance.addObserver(handleUpdate);
    debouncedLoadCustomerOrgData();
    debouncedLoadCustomerOrgDevices();

    return () => {
      observerInstance.removeObserver(handleUpdate);
    };
  }, [debouncedLoadCustomerOrgData, debouncedLoadCustomerOrgDevices]);

  useEffect(() => {
    const posOrgId = localStorage.getItem('posOrgId');
    if (
      posOrgId !== null &&
      posOrgId !== 'null' &&
      posOrgId !== 'undefined' &&
      posOrgId !== ''
    ) {
      if (posOrgId === orgDetails?.orgId) {
        navigate(`/app/pos?orgId=${posOrgId}`);
      } else {
        localStorage.removeItem('posOrgId');
      }
    }
    const setOrg = async () => {
      if (orgDetails?.orgId) {
        await getApps(`partner/apps`);
        await setSelectedOrganization(orgDetails);
        // await fetchCustomerOrgDevices(
        //   `device/orgs/${orgDetails?.orgId}/devices`
        // );
        await getCustomerOrgLocations(
          `partner/orgs/${orgDetails?.orgId}/locations`,
        );

        const sharedParams = JSON.parse(
          localStorage.getItem('sharedClipParams'),
        );

        // Viewing shared clip received from email takes priority
        if (sharedParams?.o && sharedParams?.clipId) {
          navigate(
            `/clips/details.html?o=${sharedParams?.o}&clipId=${sharedParams?.clipId}`,
          );
        }
        setShowLoader(false);
        setLaunchPadOnly(false);
      }
    };
    setOrg();
  }, [orgDetails?.orgId]);

  useEffect(() => {
    if (
      orgDetails?.orgId &&
      actionExpiredLicenseWarning === false &&
      !warningShown
    ) {
      const allLicenses = getLicenses();
      const allActiveLicenses = getLicenses(true);
      const currentTimestamp = Date.now();
      const isPopUpClicked = localStorage.getItem('expired-license-action');
      if (
        allLicenses?.length > 0 &&
        orgDetails?.orgId &&
        orgDetails?.orgType === AppDefaults.ORG_TYPE_CUSTOMER &&
        userPolicies?.view_license_exp_msg &&
        isPopUpClicked === 'false'
      ) {
        setShowLoader(true);
        const filteredExpiredLicenses = allLicenses?.filter(
          (item) =>
            item.licenseStatus === LicenseStatuses.EXPIRED &&
            item.serviceId === ServiceTypeEnum?.VMS_SERVICE &&
            item.expiryDate > currentTimestamp &&
            item.deviceId &&
            item.offeringType !== OfferingTypeEnum.ONBOARDING &&
            item.offeringType !== OfferingTypeEnum.EVAL,
        );
        // filter and ignore those licenses which has another ACTIVE liecnses stacked
        const expiredLicensesDeviceIds = filteredExpiredLicenses?.map(
          (item) => item.deviceId,
        );
        const activeLicensesData = allLicenses?.filter(
          (item) =>
            item.licenseStatus === LicenseStatuses.ACTIVE &&
            expiredLicensesDeviceIds?.includes(item.deviceId),
        );
        const activeLicensesDeviceIds = activeLicensesData?.map(
          (item) => item.deviceId,
        );
        const filteredExpiredLicensesData = filteredExpiredLicenses?.filter(
          (item) => !activeLicensesDeviceIds?.includes(item.deviceId),
        );
        if (filteredExpiredLicensesData?.length > 0) {
          setShowExpiredLicenseWarning(true);
          setWarningShown(true);
          setFilteredExpiredLicenses(filteredExpiredLicensesData);
        } else {
          setShowExpiredLicenseWarning(false);
        }
      }
    }
  }, [getLicenses()]);

  useEffect(() => {
    setEditView(isEditModeActive);
  }, [isEditModeActive]);

  useEffect(() => {
    if (cameraWallDevices?.length) {
      fetchSavedViews();
    }
  }, [cameraWallDevices?.length]);

  useEffect(() => {
    if (orgDetails?.orgId) {
      const linkingData = getLinkingData();
      if (
        Object.keys(linkingData).length != 0 &&
        orgDetails?.orgId === linkingData?.orgId
      ) {
        gotoCameraDashboard(linkingData);
      }
    }
  }, [orgDetails, getLinkingData()]);

  const gotoCameraDashboard = async (linkingData) => {
    await setSelectedEventStore(parseInt(linkingData?.eventTime));
    setEventFromSearch(true);
    setEventCVRMode(true);
    setLinkingData({});
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: linkingData?.deviceId,
      },
    });
  };

  useEffect(() => {
    isTimerRunning.current = true;
    if (orgDetails?.orgId) {
      fetchOrgUsers();
    }
    setCurrentSession(uuidv4());
    dispatch(setIsOnDeviceWallPage(true));
    dispatch(setKeepAliveRequest(false));
    dispatch(setSubscribeAreasRequest(false));
    if (wssConnections) {
      Object.keys(wssConnections).forEach((key) => {
        dispatch(removeRemoteStreams(key));
        dispatch(removeRemoteStreamsAudio(key));
        dispatch(removeMQTTPeerConnections(key));
        disconnectWithWebSocket(key);
      });
    }

    return () => {
      setIsRequestSent(false);
      dispatch(setIsOnDeviceWallPage(false));
      const devicesToUnsubscribe = JSON.parse(
        JSON.stringify(selectedView?.devices ? selectedView?.devices : []),
      );
      // cleanTimerForLiveEvents(devicesToUnsubscribe, true);
      dispatch(resetViewsData());
      if (wssConnections) {
        Object.keys(wssConnections).forEach((key) => {
          dispatch(removeRemoteStreams(key));
          dispatch(removeRemoteStreamsAudio(key));
          dispatch(removeMQTTPeerConnections(key));
          disconnectWithWebSocket(key);
        });
      }
    };
  }, [orgDetails?.orgId]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (
      (!wssConnections || selectedViewDevices) &&
      orgDetails?.orgId &&
      !editView
    ) {
      if (selectedView?.devices?.length > 0 && viewId) {
        if (selectedView?.viewId === viewId && !isRequestSent) {
          setTimeout(() => {
            checkTokenSendWebSocketRequest(signal);
          }, 1500);
        }
      }
    }
    return () => {
      controller.abort();
      if (notificationAbortCtrlrRef.current) {
        notificationAbortCtrlrRef.current.abort();
      }
      if (getSavedViewAbortCtrlrRef.current) {
        getSavedViewAbortCtrlrRef.current.abort();
      }
      if (addViewAbortCtrlrRef.current) {
        addViewAbortCtrlrRef.current.abort();
      }
      if (updateViewAbortCtrlrRef.current) {
        updateViewAbortCtrlrRef.current.abort();
      }
      if (deleteViewAbortCtrlrRef.current) {
        deleteViewAbortCtrlrRef.current.abort();
      }
      if (renameViewAbortCtrlrRef.current) {
        renameViewAbortCtrlrRef.current.abort();
      }
      if (moveToAbortCtrlrRef.current) {
        moveToAbortCtrlrRef.current.abort();
      }
      entitlementControllersRef.current = [];
    };
  }, [
    selectedViewDevices,
    JSON.stringify(selectedView),
    JSON.stringify(orgDetails),
    editView,
  ]);

  useEffect(() => {
    const tempDevices = selectedView?.devices?.length
      ? selectedView?.devices
      : [];
    setSelectedViewDevices([...new Set(tempDevices)]);
    const editViewItems =
      Utils.getRemainingItemsCount(tempDevices.length) || [];
    setCurrentViewDeviceList([...tempDevices, ...editViewItems]);
  }, [selectedView?.devices]);

  useEffect(() => {
    setIsDevicesOptionSelected(!viewId);
    if (notificationAbortCtrlrRef.current) {
      notificationAbortCtrlrRef.current.abort();
    }
    if (getSavedViewAbortCtrlrRef.current) {
      getSavedViewAbortCtrlrRef.current.abort();
    }
    if (addViewAbortCtrlrRef.current) {
      addViewAbortCtrlrRef.current.abort();
    }
    if (updateViewAbortCtrlrRef.current) {
      updateViewAbortCtrlrRef.current.abort();
    }
    if (deleteViewAbortCtrlrRef.current) {
      deleteViewAbortCtrlrRef.current.abort();
    }
    if (renameViewAbortCtrlrRef.current) {
      renameViewAbortCtrlrRef.current.abort();
    }
    if (moveToAbortCtrlrRef.current) {
      moveToAbortCtrlrRef.current.abort();
    }
    if (entitlementControllersRef.current.length > 0) {
      entitlementControllersRef.current.forEach((controller) =>
        controller.abort(),
      );
    }
    entitlementControllersRef.current = [];
  }, [viewId]);

  useEffect(() => {
    setCategoryDashboard(eventCategory);
  }, [eventCategory]);

  const checkTokenSendWebSocketRequest = (signal) => {
    const token_expiry = getPlatformDetails?.timeline_server?.expiry;
    if (token_expiry < new Date() / 1000) {
      setIsRequestSent(true);
      axios
        .get(`/partner/platform/v2`, {
          withCredentials: true,
          signal: signal,
          ...Utils.requestHeader(),
        })
        .then((response) => {
          if (response?.data?.data) {
            const result = Utils.transformBandwidthPlans(response?.data?.data);
            dispatch(setPlatformInfo(result));
            selectedViewDevices?.forEach((deviceId) => {
              connectWithWebSocket(
                response?.data?.data,
                deviceId,
                orgDetails?.orgId,
                accountId,
              );
            });
          }
        });
    } else {
      setIsRequestSent(true);
      selectedViewDevices?.forEach((deviceId) => {
        connectWithWebSocket(
          getPlatformDetails,
          deviceId,
          orgDetails?.orgId,
          accountId,
        );
      });
    }
  };

  useEffect(() => {
    if (selectedViewDevices?.length > 0 && devicesDetails?.length > 0) {
      let entitleDatas = {};
      const dateArray = selectedViewDevices?.map(async (deviceId, index) => {
        const device = devicesDetails?.find(
          (device) => device.deviceId === deviceId,
        );
        const url = device?.entitlement?.url;
        if (!url) {
          const entitlementDate = new Date().getTime();
          return entitlementDate;
        }
        if (entitlementControllersRef?.current?.[index]) {
          entitlementControllersRef?.current?.[index]?.abort();
        }
        const controller = new AbortController();
        const { signal } = controller;
        entitlementControllersRef.current[index] = controller;
        const date = await fetch(url, {
          withCredentials: true,
          signal: signal,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              const expiryDate = data?.term?.expiry;
              const durationInDays = data?.term?.data?.durationInDays;
              const startDate =
                expiryDate - (durationInDays + 1) * 24 * 60 * 60 * 1000;

              entitleDatas = {
                ...entitleDatas,
                [deviceId]: data.entitlements,
              };
              const cloudStorageCVR = data.entitlements?.find(
                (item) => item.type === EntitlementsTypeEnum.CLOUD_STORAGE_CVR,
              );
              if (cloudStorageCVR && cloudStorageCVR?.data?.durationInDays) {
                const cvrStartDate =
                  Date.parse(new Date()) -
                  cloudStorageCVR.data?.durationInDays * 24 * 60 * 60 * 1000;
                return cvrStartDate;
              }
            }
          });
        return date;
      });
      Promise.all(dateArray).then((values) => {
        const sortValue = Math.min(...values);
        setAllStartDate(sortValue);
        setEntitleData(entitleDatas);
      });
    }

    return () => {
      setEventCVRMode('null');
      setEventFromSearch(false);
    };
  }, [
    JSON.stringify(selectedViewDevices),
    // JSON.stringify(selectedView?.devices),
    JSON.stringify(devicesDetails),
  ]);

  useEffect(() => {
    if (selectedView?.devices?.length) {
      dispatch(setLatestEventsForDots(null));
      dispatch(setMetaDataForEvents(null));
      dispatch(setMetaData(null));
      // let subscribeForEvents = {};
      // const eventTopics = [];

      // selectedView?.devices?.forEach((device) => {
      //   const subscribeForMetaData = {
      //     topic: `b/streams/${device}`,
      //     qos: 0,
      //   };
      //   // Subscribe to the app topic
      //   mqttSubscribe(subscribeForMetaData);

      //   const deviceDetail = cameraWallDevices.find(
      //     (cameraWallDevice) => cameraWallDevice.deviceId === device
      //   );
      //   if (deviceDetail) {
      //     eventTopics.push(`d/rt-events/${deviceDetail?.gatewayId}`);
      //   }
      // });

      // if (eventTopics.length) {
      //   subscribeForEvents = {
      //     topic: eventTopics,
      //     qos: 0,
      //   };
      //   // Subscribe  to the device topic
      //   mqttSubscribe(subscribeForEvents);
      // }

      // return () => {
      //   mqttUnsubscribe(subscribeForEvents);
      //   selectedViewDevices?.forEach((device) => {
      //     const subscribeForMetaData = {
      //       topic: `b/streams/${device}`,
      //       qos: 0,
      //     };
      //     // Unsubscribe to the app topic
      //     mqttUnsubscribe(subscribeForMetaData);
      //   });
      //   dispatch(resetEvents);
      // };
    }
  }, [selectedView?.devices]);

  useEffect(() => {
    if (selectedView && !viewId) {
      handleDevicesHeaderClick();
    }
  }, [viewId]);

  useEffect(() => {
    dispatch(setIsLeftMenuOpen(open));
  }, [open]);

  const handleDevicesHeaderClick = async () => {
    const devicesToUnsubscribe = JSON.parse(
      JSON.stringify(selectedView?.devices ? selectedView?.devices : []),
    );
    // cleanTimerForLiveEvents(devicesToUnsubscribe);
    disconnectWithMQTT();
    if (selectedView?.devices) {
      await selectedView?.devices?.forEach((deviceId) => {
        sendPauseCVR(deviceId);
        disconnectWithWebSocket(deviceId);
      });
    }
    setEditView(false);
    dispatch(setIsEditModeActive(false));
    dispatch(setSelectedView(null));
    setSelectedViewDevices([]);
    setIsAddNewViewClicked(false);
    setNewViewDragMode(false);
  };

  const showErrorToaster = (mesg) => {
    setShowToast(true);
    setUserMsg(mesg);
    setTimeout(() => {
      setShowToast(false);
      setUserMsg('');
    }, 5500);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setIsOpenBeforeDragOver(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setIsOpenBeforeDragOver(false);
  };

  const combineDeviceAndLocationData = async (allDevices) => {
    const locationAreas = [];

    const cameraWallDevices = JSON.parse(JSON.stringify(allDevices));
    const custOrgLocations = getCustomerOrgLocationsData();
    cameraWallDevices.forEach((cameraDevice) => {
      const currentArea = custOrgLocations.find(
        (orgLocation) => orgLocation.locationId === cameraDevice?.locationId,
      );

      if (
        locationAreas.findIndex(
          (locationArea) =>
            locationArea.locationId === cameraDevice?.locationId,
        ) === -1
      ) {
        const deviceArea = {
          deviceId: cameraDevice?.deviceId,
          areas: currentArea?.areas.length > 0 ? [...currentArea?.areas] : [],
        };

        locationAreas.push({
          locationId: cameraDevice?.locationId,
          areas: [...deviceArea.areas],
        });
      }

      cameraDevice.locationName = currentArea?.locationName;
      const deviceAreaName =
        currentArea?.areas.find((area) => area.areaId === cameraDevice.areaId)
          ?.areaName || '';

      cameraDevice.areaName = deviceAreaName;
      cameraDevice.locationAreaNames =
        currentArea?.locationName + ' - ' + deviceAreaName;

      const filteredLocationArea = locationAreas.find(
        (locationArea) => locationArea?.locationId === cameraDevice?.locationId,
      ).areas;

      cameraDevice.areas = filteredLocationArea
        ? [...filteredLocationArea]
        : [];
      cameraDevice.displayDeviceStatus = Utils.getDeviceStatus(
        cameraDevice.deviceStatus,
        cameraDevice.connectionStatus,
      );
    });
    const updatedAllDevices = allDevices.map((d) => {
      const foundDevice = cameraWallDevices.find(
        (c) => c.deviceId === d.deviceId,
      );
      return foundDevice ? foundDevice : d;
    });
    if (updatedAllDevices?.length > 0) {
      setDevicesDetails([...updatedAllDevices]);
    }
  };

  const navigateFromNotification = async (notificationData, navigateStatus) => {
    const isFromPush = searchParams.get('fromPush');
    const param = {
      notificationIds: [notificationData?.nId],
    };
    if (notificationAbortCtrlrRef.current) {
      notificationAbortCtrlrRef.current.abort();
    }
    notificationAbortCtrlrRef.current = new AbortController();
    const signal = notificationAbortCtrlrRef.current.signal;

    try {
      let response = await axios.put(
        `partner/orgs/${orgDetails?.orgId}/notifications/status/read`,
        param,
        { ...Utils.requestHeader(), signal: signal },
      );
      const responseData = response?.data;
      if (responseData?.meta?.code === 200) {
      }
    } catch (err) {
    } finally {
      notificationAbortCtrlrRef.current = null;
    }

    if (isFromPush || navigateStatus) {
      if (notificationData?.orgId === orgDetails?.orgId) {
        if (
          notificationData?.nType === 'motion_detection' ||
          notificationData?.nType === 'object_detection' ||
          notificationData?.nType === 'system_action' ||
          notificationData?.nType === 'audio_detection'
        ) {
          moveToCamDetails(notificationData);
        } else if (
          notificationData?.nType === 'connectivity' ||
          notificationData?.nType === IncidentTypeEnum?.NOTIFICATION_TYPE
        ) {
          setUserPushData({});
          navigate(`/notificationdashboard.html`);
        } else if (
          notificationData?.nType === ClipTypeEnum?.NOTIFICATION_CLIP
        ) {
          setUserPushData({});
          navigate(`/notificationdashboard.html`);
        } else if (
          notificationData?.nType === ClipTypeEnum?.NOTIFICATION_CLIP
        ) {
          setUserPushData({});
          navigate(`/notificationdashboard.html`);
        }
      }
    }
  };

  const fetchOrgUsers = useCallback(async () => {
    await getCustomerOrgUsers(`partner/orgs/${orgDetails?.orgId}/accounts`);
  });

  const getSavedViews = useCallback(async () => {
    if (!orgDetails?.orgId) {
      return;
    }
    if (getSavedViewAbortCtrlrRef.current) {
      getSavedViewAbortCtrlrRef.current.abort();
    }
    setIsUserViewLoading(true);
    getSavedViewAbortCtrlrRef.current = new AbortController();
    const signal = getSavedViewAbortCtrlrRef.current.signal;
    let userViews = [];
    try {
      const response = await axios.get(
        `/partner/orgs/${orgDetails?.orgId}/userViews`,
        {
          params: {
            clientPlatform: 'web',
            orgId: orgDetails?.orgId,
            locationId: '',
            requestTime: Date.now(),
            sessionId: currentSession,
          },
          ...Utils.requestHeader(currentSession),
          timeout: 60000,
          credentials: 'include',
          withCredentials: true,
          signal: signal,
        },
      );

      if (response?.status === 200) {
        userViews = response.data?.data;
      } else {
        // setUserMsg(response?.data?.data.msg);
        setIsUserViewLoading(false);
      }
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      Utils.vmsLogger().error(err);
      setIsUserViewLoading(false);
    } finally {
      setShowLoader(false);
      getSavedViewAbortCtrlrRef.current = null;
      return userViews;
    }
  });

  const fetchSavedViews = useCallback(async () => {
    try {
      setShowLoader(true);
      // We always fetch all the saved views for the user irrespective
      // of the current location selected. We'll filter the saved views
      // on the client side.
      const userViews = JSON.parse(JSON.stringify(await getSavedViews()));
      const updatedViews = userViews.map((view) => {
        const fileContents = JSON.parse(atob(view?.file));
        const devicesInView = Object.hasOwn(fileContents, 'customLayout')
          ? fileContents.devices
          : fileContents;
        const cameraWallDeviceIds = [];
        const devices = [];
        cameraWallDevices.forEach((device) => {
          const displayDeviceStatus = Utils.getDeviceStatus(
            device.deviceStatus,
            device.connectionStatus,
          );
          if (
            displayDeviceStatus ===
              constants.DEVICES_RETURN_DEACTIVATED_STATUS ||
            (displayDeviceStatus !== constants.DEVICES_RETURN_CLAIMING_STATUS &&
              device?.apps?.vms)
          ) {
            cameraWallDeviceIds.push(device.deviceId);
          }
        });
        devicesInView.forEach((deviceId) => {
          if (cameraWallDeviceIds.includes(deviceId)) {
            devices.push(deviceId);
          }
        });
        return {
          ...view,
          devices:
            devices.length > 0 && typeof devices[0] === 'string'
              ? [...new Set(devices)]
              : [],
        };
      });
      setShowLoader(false);
      await new Promise((resolve) => {
        dispatch(setViewList([...updatedViews]));
        dispatch(
          setSelectedView(updatedViews.find((v) => v.viewId === viewId)),
        );
        resolve();
      });
      setIsUserViewLoading(false);
    } catch (err) {
      setShowLoader(false);
      setIsUserViewLoading(false);
      // setUserMsg(err.msg);
    }
  });

  const addView = async (newViewName) => {
    try {
      const deviceList = isAddNewViewClicked ? [] : selectedViewDevices;
      setIsAddingView(true);
      const reqBody = {
        clientPlatform: 'web',
        file: btoa(JSON.stringify([...new Set(deviceList)])),
        name: newViewName,
        locationId: AppDefaults.ALL_LOCATIONS_ID,
        viewType: 'DASHBOARD',
      };
      if (addViewAbortCtrlrRef.current) {
        addViewAbortCtrlrRef.current.abort();
      }
      addViewAbortCtrlrRef.current = new AbortController();
      const signal = addViewAbortCtrlrRef.current.signal;
      let response = await axios.post(
        `/partner/orgs/${orgDetails?.orgId}/userViews`,
        reqBody,
        {
          params: {
            orgId: orgDetails?.orgId,
          },
          signal: signal,
          ...Utils.requestHeader(),
        },
      );

      let resData = response?.data;

      if (resData?.meta.code === 200) {
        let newDevice = resData?.data;
        newDevice.devices = JSON.parse(atob(newDevice?.file));
        dispatch(setViewList([newDevice, ...viewList]));
        if (newDevice?.name !== 'Untitled View') {
          setIsAddNewViewClicked(false);
        } else {
          setIsRenamingView(true);
        }
        formikRef?.current?.resetForm();
        setIsAddingView(false);
        handleViewSelect(newDevice);
        setSelectedViewDevices([...new Set(newDevice?.devices)]);
        setIsDragged(false);
        setNewViewDragMode(false);
        handleDrawerOpen();
      } else {
        setIsAddingView(false);
        setIsDragged(false);
        // setUserMsg(response.data.data.msg);
      }
    } catch (err) {
      setIsAddingView(false);
      setIsDragged(false);
      // setUserMsg(err.msg);
    } finally {
      addViewAbortCtrlrRef.current = null;
    }
  };

  const updateView = async () => {
    try {
      if (
        selectedViewDevices.length >
        AppDefaults.CAMERAS_VIDEO_WALL_MAX_VIEW_DEVICES_COUNT
      ) {
        setShowMaxDevciesModal(true);
        return;
      }
      setIsRequestSent(false);
      setIsUpdatingView(true);
      const reqBody = {
        clientPlatform: 'web',
        file: btoa(JSON.stringify([...new Set(selectedViewDevices)])),
        name: selectedView?.name,
        locationId: AppDefaults.ALL_LOCATIONS_ID,
        viewType: 'DASHBOARD',
      };
      if (updateViewAbortCtrlrRef.current) {
        updateViewAbortCtrlrRef.current.abort();
      }
      updateViewAbortCtrlrRef.current = new AbortController();
      const signal = updateViewAbortCtrlrRef.current.signal;
      let response = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${selectedView.viewId}`,
        reqBody,
        {
          params: {
            orgId: orgDetails?.orgId,
          },
          signal: signal,
          ...Utils.requestHeader(),
        },
      );

      let resData = response?.data;

      if (resData?.meta.code === 200) {
        const devicesToUnsubscribe = JSON.parse(
          JSON.stringify(selectedView?.devices ? selectedView?.devices : []),
        );
        // cleanTimerForLiveEvents(devicesToUnsubscribe);
        handleDrawerClose();
        setNewViewDragMode(false);
        setEditView(false);
        dispatch(setIsEditModeActive(false));
        const newViewList = JSON.parse(JSON.stringify(viewList));
        newViewList.forEach((view) => {
          if (view.viewId === selectedView.viewId) {
            view.devices = [...new Set(selectedViewDevices)];
            view.file = resData?.data?.file;
          }
        });
        dispatch(setViewList(newViewList));
        let newView = resData?.data;
        newView.devices = [...new Set(selectedViewDevices)];
        dispatch(setSelectedView(newView));
        setIsUpdatingView(false);
        setIsDragged(false);
      } else {
        // setUserMsg(response.data.data.msg);
        setIsUpdatingView(false);
        setEditView(true);
        setIsDragged(false);
      }
    } catch (err) {
      // setUserMsg(err.msg);
      setIsUpdatingView(false);
      setEditView(true);
      setIsDragged(false);
    } finally {
      updateViewAbortCtrlrRef.current = null;
    }
  };

  const deleteView = async () => {
    try {
      if (deleteViewAbortCtrlrRef.current) {
        deleteViewAbortCtrlrRef.current.abort();
      }
      deleteViewAbortCtrlrRef.current = new AbortController();
      const signal = deleteViewAbortCtrlrRef.current.signal;
      let result = await axios.delete(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${selectedView?.viewId}`,
        { ...Utils.requestHeader(), signal: signal },
      );

      let resData = result?.data;

      if (resData?.meta.code === 200) {
        disconnectWithMQTT();
        const devicesToUnsubscribe = JSON.parse(
          JSON.stringify(selectedView?.devices ? selectedView?.devices : []),
        );
        // cleanTimerForLiveEvents(devicesToUnsubscribe);
        await fetchSavedViews();
        setSelectedViewDevices([]);
        dispatch(setSelectedView(null));
        setEditView(false);
        dispatch(setIsEditModeActive(false));
        setNewViewDragMode(false);
        setShowDeleteModal(false);
        setIsRenamingView(false);
        handleDrawerOpen();
        navigate({
          pathname: '/cameras/devices.html',
          search: '',
        });
      } else {
      }
    } catch (err) {
      Utils.vmsLogger().log(err);
    } finally {
      deleteViewAbortCtrlrRef.current = null;
    }
  };

  const renameView = async (newName) => {
    try {
      setIsAddingView(true);
      const reqBody = {
        clientPlatform: 'web',
        file: selectedView.file,
        name: newName,
        locationId: AppDefaults.ALL_LOCATIONS_ID,
        viewType: 'DASHBOARD',
      };
      if (renameViewAbortCtrlrRef.current) {
        renameViewAbortCtrlrRef.current.abort();
      }
      renameViewAbortCtrlrRef.current = new AbortController();
      const signal = renameViewAbortCtrlrRef.current.signal;
      let response = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${selectedView.viewId}`,
        reqBody,
        {
          params: {
            orgId: orgDetails?.orgId,
          },
          signal: signal,
          ...Utils.requestHeader(),
        },
      );

      let resData = response?.data;

      if (resData?.meta.code === 200) {
        setIsRenamingView(false);
        let renamedDevice;
        const newViewList = JSON.parse(JSON.stringify(viewList));
        newViewList.forEach((view) => {
          if (view.viewId === selectedView.viewId) {
            view.name = resData?.data?.name;
            renamedDevice = view;
          }
        });
        dispatch(setViewList(newViewList));
        dispatch(setSelectedView(renamedDevice));
        setIsAddingView(false);
      } else {
        // setUserMsg(response.data.data.msg);
        setIsAddingView(false);
      }
    } catch (err) {
      // setUserMsg(err.msg);
      setIsAddingView(false);
    } finally {
      renameViewAbortCtrlrRef.current = null;
    }
  };
  const moveToCamDetails = (notificationData) => {
    const endTime = new Date().getTime();
    const eventTimestamp = parseFloat(notificationData?.eventTimestamp);
    const startTime = eventTimestamp - 259200000;
    if (startTime && endTime) {
      try {
        if (moveToAbortCtrlrRef.current) {
          moveToAbortCtrlrRef.current.abort();
        }
        moveToAbortCtrlrRef.current = new AbortController();
        const signal = moveToAbortCtrlrRef.current.signal;
        axios
          .get(
            `timeline/device/${notificationData?.deviceId}/metadata?startTime=${startTime}&endTime=${endTime}`,
            { ...Utils.requestHeader(), signal: signal },
          )
          .then((response) => {
            if (response?.data?.data) {
              let largest = 0;
              const listMeta = response?.data?.data;
              for (let i = 0; i < listMeta?.length; i++) {
                if (listMeta[i].end > largest) {
                  largest = listMeta[i].end;
                }
              }
              setUserPushData({});
              if (eventTimestamp <= parseFloat(largest)) {
                setSelectedEventStore(eventTimestamp);
                setEventFromSearch(true);
                setEventCVRMode(true);
                navigate(`/cameras/dashboard.html`, {
                  state: {
                    id: notificationData?.deviceId,
                    cdnInfo: cdnInfo ? cdnInfo : {},
                  },
                });
              } else if (eventTimestamp > parseFloat(largest)) {
                setSelectedEventStore('null');
                setEventCVRMode('null');
                setEventFromSearch(false);
                navigate(`/cameras/dashboard.html`, {
                  state: {
                    id: notificationData?.deviceId,
                    cdnInfo: cdnInfo ? cdnInfo : {},
                  },
                });
              }
            }
          });
      } catch (err) {
      } finally {
        moveToAbortCtrlrRef.current = null;
      }
    }
  };

  const onMessageReceive = (notificationData) => {
    navigateFromNotification(notificationData, true);
  };

  const handleNewViewClick = () => {
    setIsAddNewViewClicked(true);
  };

  const handleCrossClick = () => {
    setIsAddNewViewClicked(false);
    setIsRenamingView(false);
  };

  const handleViewSelect = async (view) => {
    if (view.viewId === viewId) {
      return;
    }
    setSentOfferDevice();
    setCurrentViewDeviceList([]);
    setEntitleData(null);
    setIsRequestSent(false);
    const devicesToUnsubscribe = JSON.parse(
      JSON.stringify(selectedView?.devices ? selectedView?.devices : []),
    );
    // cleanTimerForLiveEvents(devicesToUnsubscribe);
    // Every time when view changes, old web socket and mqtt connection will close.
    disconnectWithMQTT();
    if (selectedView?.devices) {
      await selectedView?.devices?.forEach((deviceId) => {
        sendPauseCVR(deviceId);
        disconnectWithWebSocket(deviceId);
      });
    }
    handleDrawerClose();
    setNewViewDragMode(false);
    setIsAddingView(false);
    setIsAddNewViewClicked(false);
    dispatch(resetRecievedOffers());
    setSelectedViewDevices([...new Set(view?.devices)]);
    dispatch(setSelectedView(view));
    navigate({
      pathname: '/cameras/devices.html',
      search: `?viewId=${view.viewId}`,
    });
  };

  const handleDevicesButtonClick = async () => {
    const devicesToUnsubscribe = JSON.parse(
      JSON.stringify(selectedView?.devices ? selectedView?.devices : []),
    );
    // cleanTimerForLiveEvents(devicesToUnsubscribe);
    disconnectWithMQTT();
    if (selectedView?.devices) {
      await selectedView?.devices?.forEach((deviceId) => {
        sendPauseCVR(deviceId);
        disconnectWithWebSocket(deviceId);
      });
    }
    if (!viewId) {
      return;
    }
    setEditView(false);
    dispatch(setIsEditModeActive(false));
    navigate({
      pathname: '/cameras/devices.html',
      search: '',
    });
    dispatch(setSelectedView(null));
    setSelectedViewDevices([]);
    setIsAddNewViewClicked(false);
    setNewViewDragMode(false);
  };

  const validateForm = Yup.object({
    newView: Yup.string()
      .max(40, constants.VIEW_NAME_MAX_LENGTH_MESSAGE)
      .required(constants.VIEW_NAME_REQUIRED_ERROR_MESSAGE)
      .test(
        'is-unique',
        constants.VIEW_NAME_DUPLICATE_ERROR_MESSAGE,
        (value = '') => {
          const list = isRenamingView
            ? viewList.filter(
                (v) =>
                  v.name.trim().toLowerCase() !==
                  selectedView.name.trim().toLowerCase(),
              )
            : viewList;
          const trimmedLowerCaseValue = value.trim().toLowerCase();
          return !list.some(
            (view) => view.name.trim().toLowerCase() === trimmedLowerCaseValue,
          );
        },
      ),
  });

  const onBeforeCapture = () => {
    setIsDragging(true);
  };
  const handleDragEnd = (result) => {
    setIsDragging(false);
    if (
      selectedViewDevices.length >
      AppDefaults.CAMERAS_VIDEO_WALL_MAX_VIEW_DEVICES_COUNT
    ) {
      setShowMaxDevciesModal(true);
      return;
    }
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId !== source?.droppableId) {
      setIsDragged(true);
      setNewViewDragMode(false);
      setEditView(false);
      dispatch(setIsEditModeActive(false));
      if (viewId) {
        updateView();
      } else {
        const uniqueName = getUniqueViewName('Untitled View');
        addView(uniqueName);
      }
    }
  };

  const getUniqueViewName = (viewName) => {
    let newName = viewName;
    let count = 1;

    const existingNames = viewList.map((view) => view.name);
    while (existingNames.includes(newName)) {
      newName = `${viewName} ${count}`;
      count++;
    }

    return newName;
  };

  const getParentDeviceTimeZone = (deviceId) => {
    const parentDevices = Utils.getTotalParentDevices(customerOrgDevices);
    const curDevice = devicesDetails.find(
      (device) => device.deviceId === deviceId,
    );
    const parentDevice = parentDevices.find(
      (device) => device.deviceId === curDevice?.gatewayId,
    );
    return parentDevice?.properties?.timezone;
  };

  const onEditView = async () => {
    setSentOfferDevice();
    disconnectWithMQTT();
    if (selectedView?.devices) {
      await selectedView?.devices?.forEach((deviceId) => {
        sendPauseCVR(deviceId);
        disconnectWithWebSocket(deviceId);
      });
    }
    setEditView(true);
    setNewViewDragMode(false);
    handleDrawerOpen();
  };

  return (
    <div className="App device-wall">
      {showLoader && launchPadOnly ? (
        <div
          className="d-flex flex-column align-items-center position-absolute top-50"
          style={{ left: '47%' }}
        >
          <SiteSpinner
            height="50px"
            width="50px"
            backgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
          />
          <div className="mt-2 text-dark">{constants.LOADING}</div>
        </div>
      ) : (
        <>
          <Header
            isFromVideoWall={true}
            onPushReceive={(value) => {
              onMessageReceive(value);
            }}
            showLocation={showLocation}
            showCart={false}
          />
          <SiteToast
            customCss="licenses-list-toast"
            position="top-end"
            show={showToast}
            title="Uh-oh!"
            body={userMsg}
            delay={5000}
          />
          <DragDropContext
            onBeforeCapture={onBeforeCapture}
            onDragEnd={handleDragEnd}
          >
            <div className="main-wrapper">
              {cameraWallDevices ? (
                <Box sx={{ display: 'flex' }}>
                  <CssBaseline />
                  <Drawer
                    className={`device-wall-drawer ${
                      showDeleteModal ||
                      showMaxDevciesModal ||
                      showJumpToDateModal
                        ? 'popup-opacity'
                        : ''
                    }`}
                    variant="permanent"
                    open={open}
                    onMouseEnter={() => setOpen(true)}
                    onDragOver={() => {
                      setOpen(!isOpenBeforeDragOver);
                    }}
                    onDragLeave={() => {
                      setOpen(isOpenBeforeDragOver);
                    }}
                  >
                    <DrawerHeader className="device-wall-drawer-header">
                      <div
                        className={`device-wall-drawer-header-title ${
                          open ? 'visible' : 'hidden'
                        } ${isRenamingView || editView ? 'disabled' : ''}`}
                      >
                        {constants.CAMERAS_VIDEO_WALL_TITLE}
                      </div>
                      <IconButton
                        className="device-wall-drawer-header-toggle-icon"
                        onClick={() => {
                          open ? handleDrawerClose() : handleDrawerOpen();
                        }}
                        disabled={isRenamingView || editView}
                      >
                        {open ? (
                          <HiArrowDownTray
                            className="device-wall-drawer-toggle-icon toggle-close"
                            size={'24'}
                            color={
                              isRenamingView || editView
                                ? getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--greyscale_56')
                                : getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--brand_white')
                            }
                          />
                        ) : (
                          <HiArrowDownTray
                            className="device-wall-drawer-toggle-icon toggle-open"
                            size={'24'}
                            color={
                              isRenamingView || editView
                                ? getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--greyscale_56')
                                : getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--brand_white')
                            }
                          />
                        )}
                      </IconButton>
                    </DrawerHeader>
                    <List className="device-wall-drawer-list mt-2">
                      <ListItem
                        className={`device-wall-drawer-list-item 
                  ${isDevicesOptionSelected ? `active-list-item` : ''}
                  ${open ? 'visible' : 'icon-only'}`}
                        disablePadding
                        sx={{ display: 'block' }}
                      >
                        <ListItemButton
                          className={`device-wall-drawer-list-item-button device-wall-drawer-list-item-not-active-button ${
                            isRenamingView || editView ? 'disabled' : ''
                          }`}
                          sx={{
                            minHeight: 40,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                          }}
                          onClick={() => handleDevicesButtonClick()}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            {isRenamingView || editView ? (
                              <CameraDisabled />
                            ) : (
                              <>
                                {process.env.REACT_APP_PROJECT ===
                                AppDefaults.PROJECT_MEGATRON ? (
                                  <CiGrid41 color="white" size={24} />
                                ) : (
                                  <CameraIcon />
                                )}
                              </>
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={constants.CAMERAS_VIDEO_WALL_DEVICES_LABEL}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                    <Divider
                      className={`device-wall-drawer-divider ${
                        open ? 'visible' : 'hidden'
                      }`}
                    />
                    <div
                      className={`device-wall-drawer-section-item ${
                        !isDevicesOptionSelected ? `active-list-item` : ''
                      }`}
                    >
                      {isRenamingView || editView ? (
                        <VideoCameraIconDisabled />
                      ) : (
                        <VideoCameraIcon
                          onClick={() => handleDrawerOpen()}
                          className={!open ? 'cam-pointer' : ''}
                        />
                      )}
                      <div
                        className={`device-wall-drawer-section-title ${
                          open ? 'visible' : 'hidden'
                        } ${isRenamingView || editView ? 'disabled' : ''}`}
                      >
                        {constants.CAMERAS_VIDEO_WALL_MY_VIEWS_TITLE}
                      </div>
                    </div>
                    {!isAddNewViewClicked ? (
                      <Droppable droppableId={'drawer'} key={'drawer'}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <Button
                              className={`device-wall-drawer-new-view-btn ${
                                open ? 'visible' : 'hidden'
                              } ${isRenamingView || editView ? 'disabled' : ''}
                          ${isDragging && !viewId ? 'drag-over' : ''}`}
                              disabled={isRenamingView || editView}
                              onClick={() => handleNewViewClick()}
                              onDragOver={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const el = e.currentTarget;
                                el.classList.add('drag-over');
                              }}
                            >
                              <span
                                className={`device-wall-drawer-new-view-btn-label-plus ${
                                  open ? 'visible' : 'hidden'
                                } ${
                                  isRenamingView || editView ? 'disabled' : ''
                                }`}
                                disabled={isRenamingView || editView}
                              >
                                +
                              </span>
                              <span
                                className={`device-wall-drawer-new-view-btn-label ${
                                  open ? 'visible' : 'hidden'
                                } ${
                                  isRenamingView || editView ? 'disabled' : ''
                                }`}
                                disabled={isRenamingView || editView}
                              >
                                {
                                  constants.CAMERAS_VIDEO_WALL_NEW_VIEW_BUTTON_LABEL
                                }
                              </span>
                            </Button>
                          </div>
                        )}
                      </Droppable>
                    ) : (
                      <Formik
                        innerRef={formikRef}
                        validationSchema={validateForm}
                        initialValues={{
                          newView: '',
                        }}
                        onSubmit={(values) => {
                          addView(values.newView);
                        }}
                      >
                        {({ handleSubmit, submitForm }) => (
                          <Form
                            className="device-wall-drawer-add-view-form"
                            onSubmit={handleSubmit}
                          >
                            <TextField
                              removebottommargin="0"
                              removetopmargin="0"
                              afterinputicon={
                                !isAddingView ? (
                                  <div className="icon-container">
                                    <CrossBackgroudIcon
                                      className="cross-icon"
                                      onClick={() => {
                                        handleCrossClick();
                                      }}
                                    />
                                    <CheckedIcon
                                      className={`checked-icon`}
                                      onClick={() => {
                                        submitForm();
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <SiteSpinner
                                      height="20px"
                                      width="20px"
                                      backgroundColor={getComputedStyle(
                                        document.documentElement,
                                      ).getPropertyValue('--brand_white')}
                                    />
                                  </div>
                                )
                              }
                              autoFocused={true}
                              placeholder={
                                constants.CREATE_NEW_VIEW_FIELD_PLACEHOLDER
                              }
                              name="newView"
                              type="text"
                            />
                          </Form>
                        )}
                      </Formik>
                    )}
                    <List
                      style={{
                        pointerEvents: isUserViewLoading ? 'none' : 'auto',
                      }}
                      className={`device-wall-drawer-list view-list ${
                        open ? 'visible' : 'hidden'
                      }`}
                    >
                      {/* Saved Views */}
                      {viewList.map((view) =>
                        isRenamingView &&
                        selectedView &&
                        view.viewId === selectedView?.viewId ? (
                          <Formik
                            innerRef={formikRef}
                            validationSchema={validateForm}
                            initialValues={{
                              newView: selectedView?.name
                                ? selectedView?.name
                                : '',
                            }}
                            enableReinitialze={true}
                            onSubmit={(values) => {
                              renameView(values.newView);
                            }}
                          >
                            {({ handleSubmit, submitForm }) => (
                              <Form
                                className="device-wall-drawer-add-view-form"
                                onSubmit={handleSubmit}
                              >
                                <TextField
                                  removebottommargin="0"
                                  removetopmargin="0"
                                  afterinputicon={
                                    !isAddingView ? (
                                      <div className="icon-container">
                                        <CrossBackgroudIcon
                                          className="cross-icon"
                                          onClick={() => {
                                            handleCrossClick();
                                          }}
                                        />
                                        <CheckedIcon
                                          className={`checked-icon`}
                                          onClick={() => {
                                            submitForm();
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <SiteSpinner
                                          height="20px"
                                          width="20px"
                                          backgroundColor={getComputedStyle(
                                            document.documentElement,
                                          ).getPropertyValue('--brand_white')}
                                        />
                                      </div>
                                    )
                                  }
                                  autoFocused={true}
                                  placeholder={
                                    constants.CREATE_NEW_VIEW_FIELD_PLACEHOLDER
                                  }
                                  name="newView"
                                  type="text"
                                />
                              </Form>
                            )}
                          </Formik>
                        ) : (
                          <Droppable
                            droppableId={view.viewId}
                            key={view?.viewId}
                            isDropDisabled={
                              view?.viewId !== selectedView?.viewId
                            }
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                <ListItem
                                  className="device-wall-drawer-list-item"
                                  key={view?.viewId}
                                  disablePadding
                                  sx={{ display: 'block' }}
                                >
                                  <ListItemButton
                                    className={`device-wall-drawer-list-item-button ${
                                      view.viewId === selectedView?.viewId
                                        ? 'active'
                                        : ''
                                    } ${isRenamingView ? 'disabled' : ''}
                                ${
                                  editView &&
                                  view?.viewId !== selectedView?.viewId
                                    ? 'disabled'
                                    : ''
                                }
                                  ${
                                    isDragging &&
                                    view?.viewId === selectedView?.viewId
                                      ? 'drag-over'
                                      : ''
                                  }`}
                                    sx={{
                                      justifyContent: open
                                        ? 'initial'
                                        : 'center',
                                      px: 2.5,
                                    }}
                                    data-role="drag-drop-container"
                                    onDragOver={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();

                                      // TODO: need to disable firing on drag over event from the drawer

                                      const el = e.currentTarget;
                                      // TODO: change color
                                      Utils.vmsLogger().log(
                                        'drag over event received',
                                      );
                                      el.classList.add('drag-over');
                                      Utils.vmsLogger().log(
                                        'el.classList: ',
                                        el.classList,
                                      );
                                    }}
                                    onDrop={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();

                                      // TODO: invoke on-drop handler
                                      Utils.vmsLogger().log(
                                        'drop event reveived',
                                      );
                                    }}
                                    onClick={() => handleViewSelect(view)}
                                  >
                                    <ListItemText
                                      primary={view?.name}
                                      className="list-item-span"
                                      sx={{ opacity: open ? 1 : 0, margin: 0 }}
                                    ></ListItemText>
                                    {view?.numOfNotices > 0 && (
                                      <div className="device-wall-drawer-list-item-notifications">
                                        {view?.numOfNotices}
                                      </div>
                                    )}
                                  </ListItemButton>
                                </ListItem>
                              </div>
                            )}
                          </Droppable>
                        ),
                      )}
                    </List>
                    <div
                      className={`device-wall-drawer-help-container  ${
                        open ? 'visible' : 'icon-only'
                      }`}
                    >
                      <div
                        className="device-wall-drawer-help-section device-drawer-link"
                        onClick={() => window.open(helpData?.common, '_blank')}
                      >
                        <PiQuestionLight
                          className="device-wall-drawer-help-icon"
                          size={24}
                        />
                        <span
                          className={`device-wall-drawer-help-text ${
                            open ? 'visible' : 'hidden'
                          }`}
                        >
                          {constants.CAMERAS_VIDEO_WALL_HELP_LABEL}
                        </span>
                      </div>
                    </div>
                  </Drawer>
                  <div className="camera-wall-wrapper" ref={fullscreenRef}>
                    <FullScreen handle={handle}>
                      <Box
                        className={`device-wall-main-container ${
                          handle?.active ? 'fullscreen-mode' : ''
                        }`}
                        component="main"
                        sx={{ flexGrow: 1, p: 3 }}
                      >
                        {/* Header Section */}
                        <div className="device-wall-main-container-header">
                          <div className="page-title">
                            {viewId && selectedView && !editView
                              ? selectedView?.name
                                ? selectedView?.name
                                : 'Untitled View'
                              : selectedView && editView
                                ? `Edit <${selectedView.name}> View`
                                : constants.CAMERAS_VIDEO_WALL_TITLE}
                          </div>
                          <div className="page-monitor-container">
                            {viewId && selectedView && editView ? (
                              <PrimaryButton
                                className="btn btn-primary device-wall-drawer-edit-view-btn"
                                type="submit"
                                width="91px"
                                height="32px"
                                fontSize="0.75rem"
                                lineHeight="16px"
                                borderWidth="0px"
                                hoverBorderWidth="0px"
                                backgroundColor={getComputedStyle(
                                  document.documentElement,
                                ).getPropertyValue('--primary_40')}
                                color={getComputedStyle(
                                  document.documentElement,
                                ).getPropertyValue('--brand_white')}
                                onClick={() => updateView()}
                                loader={isUpdatingView}
                                loaderClassName={'edit-view-loader'}
                              >
                                {
                                  constants.CAMERAS_VIDEO_WALL_SAVE_VIEW_BUTTON_LABEL
                                }
                              </PrimaryButton>
                            ) : null}
                            {(viewId && selectedView && editView) ||
                            newViewDragMode ? (
                              <div className="page-monitor">
                                <span>
                                  {selectedViewDevices?.length}/
                                  {
                                    AppDefaults.CAMERAS_VIDEO_WALL_MAX_VIEW_DEVICES_COUNT
                                  }
                                </span>
                                selected
                                <HiOutlineCheckCircle
                                  size={16}
                                  color={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--primary_64')}
                                />
                              </div>
                            ) : null}
                            {viewId && selectedView && editView ? (
                              <PrimaryButton
                                className="btn btn-primary px-2"
                                type="submit"
                                width="55px"
                                height="32px"
                                fontSize="0.75rem"
                                lineHeight="16px"
                                onClick={() => {
                                  setIsRequestSent(false);
                                  setEditView(false);
                                  dispatch(setIsEditModeActive(false));
                                  setNewViewDragMode(false);
                                  setSelectedViewDevices([
                                    ...new Set(selectedView?.devices),
                                  ]);
                                  dispatch(setSelectedView(selectedView));
                                }}
                                backgroundColor={getComputedStyle(
                                  document.documentElement,
                                ).getPropertyValue('--greyscale_08')}
                                hoverBackgroundColor={getComputedStyle(
                                  document.documentElement,
                                ).getPropertyValue('--greyscale_08')}
                                borderWidth="0px"
                                hoverBorderWidth="0px"
                                color={getComputedStyle(
                                  document.documentElement,
                                ).getPropertyValue('--brand_white')}
                                hoverColor={getComputedStyle(
                                  document.documentElement,
                                ).getPropertyValue('--brand_white')}
                              >
                                {
                                  constants.CAMERAS_LIVE_ACTION_BUTTONS_CANCEL_LABEL
                                }
                              </PrimaryButton>
                            ) : null}
                          </div>
                          {!handle?.active &&
                          viewId &&
                          selectedView &&
                          !editView ? (
                            <NavDropdown
                              className="edit-view-dropdown"
                              title={
                                <div>
                                  <img src={editViewButton} alt=""></img>
                                </div>
                              }
                            >
                              <NavDropdown.Item
                                onClick={() => onEditView()}
                                className={isRenamingView ? 'disabled' : ''}
                                disabled={isRenamingView}
                              >
                                <HiOutlinePencil
                                  size={20}
                                  color={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--greyscale_64')}
                                />
                                {constants.CAMERAS_VIDEO_EDIT_VIEW_LABEL}
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                onClick={() => {
                                  handleDrawerOpen();
                                  setIsRenamingView(true);
                                }}
                              >
                                <HiOutlinePencilAlt
                                  size={20}
                                  color={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--greyscale_64')}
                                />
                                {constants.CAMERAS_VIDEO_RENAME_VIEW_LABEL}
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                onClick={() => setShowDeleteModal(true)}
                              >
                                <TbTrash
                                  size={20}
                                  color={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--error_64')}
                                />
                                {constants.CAMERAS_VIDEO_DELETE_VIEW_LABEL}
                              </NavDropdown.Item>
                            </NavDropdown>
                          ) : null}
                        </div>
                        {(!selectedView && !isDragged && !viewId) ||
                        (viewId && selectedView && editView && !isDragged) ? (
                          <>
                            <div className="device-wall-main-container-content-body">
                              <CameraWallDevices
                                viewDetails={editView ? selectedView : null}
                                setSelectedViewDevices={setSelectedViewDevices}
                                setTotalDeviceCount={setTotalDeviceCount}
                                setShowMaxDevciesModal={setShowMaxDevciesModal}
                                newViewDragMode={newViewDragMode}
                                setNewViewDragMode={setNewViewDragMode}
                                editView={editView}
                                isDragging={isDragging}
                                selectedViewDevices={selectedViewDevices}
                                orgDetails={orgDetails}
                                custOrgDevices={cameraWallDevices}
                                allDevices={customerOrgDevices}
                              />
                            </div>
                          </>
                        ) : viewId &&
                          selectedView?.devices?.length > 0 &&
                          selectedViewDevices?.length > 0 &&
                          !isDragged ? (
                          <>
                            <DeviceWallTimeline
                              internalEventFromSearch={false}
                              category={categoryDashboard}
                              eventCVRMode={getEventCVRMode()}
                              showErrorToaster={showErrorToaster}
                              timezone={
                                selectedViewDevices?.length > 1
                                  ? moment.tz.guess()
                                  : getParentDeviceTimeZone(
                                      selectedViewDevices?.[0],
                                    )
                              }
                              startDate={allStartDate}
                              viewId={selectedView?.viewId}
                              enterFullScreen={() => handle.enter()}
                              exitFullScreen={() => handle.exit()}
                              customMetaData={deviceMetaData}
                              eventDotsData={eventsData}
                              allDeviceIds={[...new Set(selectedViewDevices)]}
                              appliedFilter={''}
                              setShowJumpToDateModal={setShowJumpToDateModal}
                              orgDetails={orgDetails}
                              allDevices={devicesDetails}
                              currentViewDeviceList={currentViewDeviceList}
                              setCurrentViewDeviceList={
                                setCurrentViewDeviceList
                              }
                              entitleData={entitleData}
                            />
                          </>
                        ) : viewId && selectedView && !isDragged ? (
                          <>
                            <div className="no-cameras-container">
                              <div className="no-cameras-icon-container">
                                <FiCameraOff
                                  stroke={getComputedStyle(
                                    document.documentElement,
                                  ).getPropertyValue('--greyscale_56')}
                                  size={32}
                                />
                              </div>
                              <div className="no-cameras-notice">
                                {
                                  constants.CAMERAS_VIDEO_WALL_EDIT_VIEW_BUTTON_DESCRIPTION
                                }
                              </div>
                              <div className="no-cameras-cta">
                                <Button
                                  className="no-cameras-edit-view-btn"
                                  onClick={(e) => {
                                    setEditView(true);
                                    setNewViewDragMode(false);
                                  }}
                                >
                                  <span>
                                    <HiOutlinePencil
                                      stroke={getComputedStyle(
                                        document.documentElement,
                                      ).getPropertyValue('--brand_white')}
                                      size={16}
                                    />
                                  </span>
                                  {
                                    constants.CAMERAS_VIDEO_WALL_EDIT_VIEW_BUTTON_LABEL
                                  }
                                </Button>
                              </div>
                            </div>
                          </>
                        ) : isDragged ? (
                          <div className="spinner-container">
                            <SiteSpinner
                              height="100px"
                              width="100px"
                              backgroundColor={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--brand_white')}
                            />
                          </div>
                        ) : (
                          <div
                            className="d-flex flex-column align-items-center position-absolute top-50"
                            style={{ left: '52%' }}
                          >
                            <SiteSpinner
                              height="50px"
                              width="50px"
                              backgroundColor={getComputedStyle(
                                document.documentElement,
                              ).getPropertyValue('--primary_40')}
                            />
                            <div className="mt-2">{constants.LOADING}</div>
                          </div>
                        )}
                      </Box>
                    </FullScreen>
                  </div>
                </Box>
              ) : (
                <div className="no-cameras-container">
                  <div className="no-cameras-icon-container">
                    <FiCameraOff
                      stroke={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--greyscale_56')}
                      size={32}
                    />
                  </div>
                  <div className="no-cameras-notice">
                    {constants.CAMERAS_VIDEO_WALL_NO_CAMERAS_AVAILABLE_LABEL}
                  </div>
                  {userPolicies.install_device && userPolicies.claim_device && (
                    <div className="no-cameras-cta">
                      <Button
                        className="no-cameras-add-device-btn"
                        onClick={(e) => {
                          navigate(
                            `/devices/listing.html?orgId=${orgDetails?.orgId}`,
                          );
                        }}
                      >
                        <span>
                          <MdOutlineAddAPhoto
                            stroke={getComputedStyle(
                              document.documentElement,
                            ).getPropertyValue('--brand_white')}
                            size={16}
                          />
                        </span>
                        {constants.CAMERAS_VIDEO_WALL_ADD_CAMERA_LABEL}
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <SiteModal
                key={'delete-view-modal'}
                classes="save-view-modal"
                wrapperClass="save-view-modal"
                modalTitle={
                  constants.CAMERAS_VIDEO_WALL_DELETE_VIEW_MODAL_TITLE
                }
                showModal={showDeleteModal}
                hideModal={() => setShowDeleteModal(false)}
              >
                <DeleteView
                  deleteView={deleteView}
                  cancelDeleting={() => setShowDeleteModal(false)}
                  selectedView={selectedView}
                ></DeleteView>
              </SiteModal>
              <SiteModal
                key={'view-max-device-modal'}
                classes="view-max-device-modal"
                wrapperClass="view-max-device-modal"
                showModal={showMaxDevciesModal}
                hideModal={() => setShowMaxDevciesModal(false)}
                modalHeader={true}
              >
                <MaxViewDevices
                  closeModal={() => setShowMaxDevciesModal(false)}
                  selectedViewDevices={selectedViewDevices}
                ></MaxViewDevices>
              </SiteModal>
              <SiteModal
                modalTitle={''}
                showModal={
                  showExpiredLicenseWarning &&
                  getLicensesExpiredPopUpShow() === true &&
                  TOSPolicyAcceptionFlag
                }
                classes="device-license-expire-style"
              >
                <div className="text-center">
                  <LicenseExpireWarning
                    className="mb-4 icon-warning-color"
                    size={96}
                  />
                </div>
                <div className="heading-h5-bold">
                  {constants.DEVICE_EXPIRED_LICENSE_CONTENT}
                </div>
                <div className="subheading-16px">
                  {constants.DEVICE_LICESNSE_EXPIRE_SUBTITLE}
                </div>
                <>
                  <div className="button-style">
                    <PrimaryButton
                      className="btn btn-primary-outline mb-4"
                      type="button"
                      width="100%"
                      borderWidth="1.5px"
                      hoverBorderWidth="1.5px"
                      hoverBorderColor={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--primary_40')}
                      color={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--primary_40')}
                      hoverColor={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--brand_white')}
                      hoverBackgroundColor={getComputedStyle(
                        document.documentElement,
                      ).getPropertyValue('--primary_40')}
                      backgroundColor="transparent"
                      height="56px"
                      fontSize="1.125rem"
                      lineHeight="24px"
                      onClick={(e) => {
                        localStorage.setItem('expired-license-action', 'true');
                        setLicensesExpiredPopUpShow(false);
                        localStorage.setItem('filterExpiredLicenses', true);
                        navigate(
                          `/devices/listing.html?orgId=${orgDetails?.orgId}`,
                          {
                            state: { filteredExpiredLicenses },
                          },
                        );
                      }}
                    >
                      {
                        constants.DEVICE_REVIEW_EXPIRE_LICENSES_DEVICE_MODAL_BUTTON_TITLE
                      }
                    </PrimaryButton>
                    <div className="button-dismiss-style">
                      <PrimaryButton
                        className="btn btn-primary-outline mb-4"
                        type="button"
                        width="100%"
                        borderWidth="1.5px"
                        hoverBorderWidth="1.5px"
                        hoverBorderColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--primary_40')}
                        color={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--primary_40')}
                        hoverColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--brand_white')}
                        hoverBackgroundColor={getComputedStyle(
                          document.documentElement,
                        ).getPropertyValue('--primary_40')}
                        backgroundColor="transparent"
                        height="56px"
                        fontSize="1.125rem"
                        lineHeight="24px"
                        onClick={() => {
                          setLicensesExpiredPopUpShow(false);
                          setShowExpiredLicenseWarning(false);
                          setActionExpiredLicenseWarning(true);
                          localStorage.setItem(
                            'expired-license-action',
                            'true',
                          );
                        }}
                      >
                        {
                          constants.DEVICES_REVIEW_EXPIRED_LICENSES_DISMISS_BUTTON_TITLE
                        }
                      </PrimaryButton>
                    </div>
                  </div>
                </>
              </SiteModal>
            </div>
          </DragDropContext>
        </>
      )}
    </div>
  );
};

export default Cameras;
