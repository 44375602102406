import Image from 'react-bootstrap/Image';

const AvatarStyles = {
  rounded: {
    smaller: {
      height: '18px',
      width: '18px',
      lineHeight: '18px',
      borderRadius: '6px',
      fontSize: '0.5rem',
    },
    small: {
      height: '24px',
      width: '24px',
      lineHeight: '24px',
      borderRadius: '6px',
      fontSize: '0.75rem',
    },
    medium: {
      height: '32px',
      width: '32px',
      lineHeight: '32px',
      borderRadius: '8px',
    },
    large: {
      height: '48px',
      width: '48px',
      lineHeight: '48px',
      borderRadius: '12px',
    },
  },
  roundedCircle: {
    smaller: {
      height: '18px',
      width: '18px',
      lineHeight: '18px',
      borderRadius: '50%',
      fontSize: '0.5rem',
    },
    small: {
      height: '24px',
      width: '24px',
      lineHeight: '24px',
      borderRadius: '50%',
      fontSize: '0.75rem',
    },
    semiMedium: {
      height: '29px',
      width: '29px',
      lineHeight: '1.75rem',
      borderRadius: '50%',
      fontSize: '0.75rem',
    },
    medium: {
      height: '32px',
      width: '32px',
      lineHeight: '32px',
      borderRadius: '50%',
    },
    semiLarge: {
      height: '40px',
      width: '40px',
      lineHeight: '40px',
      borderRadius: '50%',
    },
    large: {
      height: '48px',
      width: '48px',
      lineHeight: '48px',
      borderRadius: '50%',
    },
    extraLarge: {
      height: '88px',
      width: '88px',
      lineHeight: '88px',
      borderRadius: '50%',
    },
  },
};

const AvatarBW = ({ size, avatarStyle, valueType, value }) => {
  const color = getComputedStyle(document.documentElement).getPropertyValue(
    '--primary_40',
  );
  const backgroundColor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--primary_96');

  let styleData = Object.assign(
    { backgroundColor: backgroundColor, color: color },
    getAvatarStyles(size, avatarStyle),
  );

  return (
    <div className="site-avatars large-avatar">
      {valueType === 'icon' && (
        <Image
          src={value}
          alt="Avatar"
          style={getAvatarStyles(size, avatarStyle)}
        />
      )}
      {valueType === 'text' && (
        <div className={`site-avatar-number`} style={styleData}>
          {value}
        </div>
      )}
    </div>
  );
};

const getAvatarStyles = (size, style) => {
  if (!size || !style || !AvatarStyles[style]) {
    return null;
  }

  return AvatarStyles[style][size];
};

export default AvatarBW;
