import constants from './en';

// All Global Constants and Enum Types should be stored here
const AppDefaults = {
  ALL_LOCATIONS_ID: 'Location0',
  ERR_CANCELED: 'ERR_CANCELED',
  CAMERA_VIDEO_TIMELINE_MAX_DURATION_IN_SECONDS: 300,
  // Max mumber of ticks on tImeline
  CAMERA_VIDEO_TIMELINE_MAX_NUMBER_OF_TICKS: 46.5,
  // Number of seconds per tick
  CAMERA_VIDEO_TIMELINE_NUMBER_OF_SECONDS_PER_TICK: 6,
  // Min Duration: 10 seconds
  INCIDENT_EVIDENCE_CLIP_MIN_DURATION_IN_SECONDS: 10,
  // Max Duration: 240 seconds
  INCIDENT_EVIDENCE_CLIP_MAX_DURATION_IN_SECONDS: 240,
  // Default Duration: 30 seconds
  INCIDENT_EVIDENCE_CLIP_DEFAULT_DURATION_IN_SECONDS: 30,
  // Width of clipper handle in pixels
  INCIDENT_EVIDENCE_CLIP_HANDLE_WIDTH: 8,
  INCIDENT_EVIDENCE_SUPPORTED_FILE_EXTENSIONS:
    '.pdf,.doc,.docx,.jpeg,.jpg,.png,.raw,.mp4,.zip',
  INCIDENT_EVIDENCE_FILE_MAX_SIZE: 10,
  INCIDENT_DATE_FORMAT: 'll',
  INCIDENT_EVIDENCE_NOTE_DATE_FORMAT: 'lll',
  INCIDENT_EVIDENCE_NOTE_ORIGINAL_VIDEO_FILENAME_RETAINED: false,
  INCIDENT_FINAL_ANALYSIS_MAX_CHARACTERS: 1000,
  PROJECT_DUCLO: 'DUCLO',
  PROJECT_MEGATRON: 'MEGATRON',
  CAMERAS_VIDEO_WALL_MAX_VIEW_DEVICES_COUNT: 12,
  ORG_TYPE_CUSTOMER: 'CUSTOMER_ORG',
  ORG_TYPE_INSTALLER: 'INSTALLER_ORG',
  DEFAULT_TIMER_TIME: 1800000,
  DATA_TYPE_DATE: 'date',
  ONEGB: 1024,
  POS_FILE_EXTENSIONS: '.csv,.xls',
  POS_DEFUALT_STORE_ID: 'POS-STORE0',
  POS_STATUS_FAILED: 'FAILED',
  POS_STATUS_IN_PROGRESS: 'IN_PROGRESS',
  POS_STATUS_COMPLETED: 'COMPLETED',
  POS_STATUS_STALLED: 'STALLED',
  FROM_TOS_PP: 3,
  FROM_TOS: 1,
  FROM_PP: 2,
  NOTIFICATION_CHAR_LIMIT: 40,
  POS_NOTIFICATION_STATUS_FAILED: 'pos_uploaded_failed',
  POS_NOTIFICATION_STATUS_PARTIALLY: 'pos_uploaded_partially',
  POS_NOTIFICATION_STATUS_SUCCESS: 'pos_uploaded_success',
  POS_POLICY_ADD_UPDATE_MAPPING: 'add_update_pos_mapping',
  AUDIO_CODE_AAC: 'aac',
  ACTIVE_USER: 'ACTIVE',
  ONBOARDING: 'ONBOARDING',
  DAY_IN_MILLISECONDS: 60 * 60 * 24 * 1000,
  BANDWIDTH_DATE_FORMAT: 'MMM Do, YYYY, h:mm A',
  BANDWIDTH_PAGE_SIZE: 10,
  LICENSE_EXPIRING_SOON_LIMIT: 30,
  LICENSE_WRAPPER_LABEL_LIMIT: 30,
  DAYS: constants.DAYS_LABEL,
  RANGE_SLIDER_THUMB_MAX: 1.2,
  RANGE_SLIDER_THUMB_MIN: 7,
};

const ClipTypeEnum = {
  CLIP_VIDEO_CLIP_AVAILABLE: 'video-clip-available',
  NOTIFICATION_CLIP: 'clip',
};

const DeviceStatusEnum = {
  ADDED_N_CLAIMED: 'Claimed',
  ADDED_NOT_CLAIMED: 'Pending Claimed',
  ALREADY_ASSIGNED: 'Already Assigned',
  AUTHENTICATED: 'Add Device',
  DEACTIVATED: 'Reclaim Device',
  NOT_AUTHENTICATED: 'Authenticate',
  CON_OFFLINE: 'Offline',
};

const DeviceTypeEnum = {
  IPCam: 'IPCAM',
  NVR: 'NVR',
};

const EntitlementsTypeEnum = {
  CLOUD_STORAGE_CVR: 'CLOUD_STORAGE_CVR',
  LIVE_VIEW: 'LIVE_VIEW',
  BITRATE: 'BITRATE',
  RESOLUTION: 'RESOLUTION',
  FPS: 'FPS',
};

const IncidentTypeEnum = {
  INCIDENT_VIDEO_CLIP: 'incident-video-clip-available',
  INCIDENT_ZIP_CLIP: 'incident-zip-available',
  NOTIFICATION_TYPE: 'incident',
};

const LicenseStatuses = {
  ACTIVE: 'ACTIVE',
  ACTIVE_UNASSIGNED: 'ACTIVE_UNASSIGNED',
  ASSIGNED_ACTIVATION_PENDING: 'ASSIGNED_ACTIVATION_PENDING',
  EXPIRED: 'EXPIRED',
  UNASSIGNED: 'UNASSIGNED',
};

const LineDirectionEnum = {
  LEFT: 1,
  RIGHT: 2,
  BOTH: 3,
};

const OfferingTypeEnum = {
  EVAL: 'EVAL',
  ONBOARDING: 'ONBOARDING',
  PAID: 'PAID',
};

const RolesEnum = {
  ROLE0VMS: 'role0#vms',
  ROLE1VMS: 'role1#vms',
  ROLE2VMS: 'role2#vms',
  ROLE3VMS: 'role3#vms',
  ROLE4VMS: 'role4#vms',
  ROLE5VMS: 'role5#vms',
};

const ServiceTypeEnum = {
  CLOUD_SERVICE: 'oncloud-service',
  CLOUD_STORAGE: 'cloud-storage',
  DM_PRO_SERVICE: 'dmpro-service',
  FLEXAI_SERVICE: 'flexai-service',
  SALESHUB_SERVICE: 'saleshub-service',
  SIGHT_MIND_SERVICE: 'sightmind-service',
  VMS_SERVICE: 'vms-service',
};

const CacheNameEnum = {
  CLIPS_CACHE: 'clips-snapshots',
  EVENTS_CACHE: 'events-snapshots',
  CAMERA_DETAILS_EVENTS_CACHE: 'camera-details-events-snapshots',
};

const EventType = {
  VEHICLE: constants.EVENT_TYPE_VEHICLE,
  OBJ_DETECTION: constants.EVENT_TYPE_OBJ_DETECTION,
  OBJ_TYPE: constants.EVENT_TYPE_OBJ_TYPE,
  CATEGORIES: constants.EVENT_TYPE_CATEGORIES,
  SUB_CATEGORIES: constants.EVENT_TYPE_SUB_CATEGORIES,
  SUB_OBJ_CATEGORIES: constants.EVENT_TYPE_SUB_OBJ_CATEGORIES,
};

const customEventName = [
  'ai.duclo.MotionDetected',
  'ai.duclo.virtualLineDetected',
  'ai.duclo.Intrusion',
  'ai.duclo.Appear/Disappear',
  'ai.duclo.Enter',
  'ai.duclo.Exit',
  'ai.duclo.Loitering',
];

const allEventNames = [
  'ai.duclo.MotionDetected',
  'ai.duclo.virtualLineDetected',
  'ai.duclo.Intrusion',
  'ai.duclo.Appear/Disappear',
  'ai.duclo.Enter',
  'ai.duclo.Exit',
  'ai.duclo.Loitering',
  'ai.duclo.Tampering',
  'ai.duclo.ShockDetection',
  'ai.duclo.FogDetection',
];

const getVirtualArea = () => {
  const area = {
    'va-enter': 'virtual-area-entering',
    'va-exit': 'virtual-area-exiting',
    intrusion: 'virtual-area-intrusion',
    loitering: 'virtual-area-loitering',
    'appear-disappear': 'virtual-area-appear-disappear',
    'motion-start': 'md-include-area',
  };
  return area;
};

export const regexAnalytics = /^[a-zA-Z0-9]+$/;

export {
  AppDefaults,
  CacheNameEnum,
  ClipTypeEnum,
  DeviceStatusEnum,
  DeviceTypeEnum,
  customEventName,
  allEventNames,
  EntitlementsTypeEnum,
  IncidentTypeEnum,
  LicenseStatuses,
  LineDirectionEnum,
  OfferingTypeEnum,
  RolesEnum,
  ServiceTypeEnum,
  EventType,
  getVirtualArea,
};
