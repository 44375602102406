import ReactSlider from 'react-slider';
import { ErrorMessage } from 'formik';
import { Unity } from 'react-bootstrap-icons';
import { constants } from '../../helpers';

const RangeInputField = ({
  unitType,
  classes,
  label,
  fieldName,
  currentValue,
  disabled,
  changeHandler,
  minValue = 1,
  maxValue = 100,
  minValueLabel,
  maxValueLabel,
  curValueLabel,
  isErrorMessage = true,
  thumbLabelHide = false,
  ...props
}) => {
  const formatValue = (value) => {
    if (value < 1024) {
      return `${value} ${constants.KBPS}`;
    } else {
      return `${(value / 1024).toFixed(1)} ${constants.MBPS}`;
    }
  };

  const generateClassName = (maxValue, currentValue, unitType) => {
    if (!curValueLabel && unitType) {
      if (currentValue <= maxValue / 9 || currentValue > maxValue * 0.85) {
        return 'range-slider-thumb-value-default-bitrate';
      } else {
        return 'range-slider-thumb-value';
      }
    } else {
      return 'range-slider-thumb-value';
    }
  };

  return (
    <div className={`range-field-wrap ${classes ? classes : ''}`}>
      <div className="range-field-container">
        {label && <label className="range-field-label">{label}</label>}
        <ReactSlider
          name={fieldName}
          value={currentValue}
          min={minValue}
          max={maxValue}
          disabled={disabled}
          className="range-slider"
          trackClassName="range-slider-track"
          thumbClassName="range-slider-thumb"
          renderTrack={(props, state) => (
            <div {...props}>
              {state?.index === 0 && (
                <span className="range-slider-start-value">
                  {minValueLabel && !unitType
                    ? minValueLabel
                    : minValue && !unitType
                    ? minValue
                    : formatValue(minValue)}
                </span>
              )}
              {state?.index === 1 && (
                <span className="range-slider-last-value">
                  {maxValueLabel && !unitType
                    ? maxValueLabel
                    : maxValue && !unitType
                    ? maxValue
                    : formatValue(maxValue)}
                </span>
              )}
            </div>
          )}
          renderThumb={(props, state) => (
            <div {...props}>
              {state?.valueNow !== minValue &&
                state?.valueNow !== maxValue &&
                !thumbLabelHide && (
                  <span
                    className={generateClassName(
                      maxValue,
                      state.valueNow,
                      unitType
                    )}
                  >
                    {curValueLabel
                      ? curValueLabel
                      : unitType
                      ? formatValue(state.valueNow)
                      : state.valueNow}
                  </span>
                )}
            </div>
          )}
          onAfterChange={changeHandler}
        />
        {isErrorMessage && (
          <ErrorMessage component="div" name={fieldName} className="error" />
        )}
      </div>
    </div>
  );
};

export default RangeInputField;
