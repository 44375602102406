import React from 'react';
import Timeline from 'react-visjs-timeline';
import moment from 'moment';
import 'moment-timezone';
import axios from 'axios';
import { dateClosest } from '../../../../helpers/dateClosest';
import '../../../../assets/css/timeline.scss';
import { play, pause, fullscreen } from '../../../../assets/images';
import { connect } from 'react-redux';
import {
  setMetaData,
  setCDNInfo,
  setLiveStream,
  setRemoteStream,
} from '../../../../store/reducers/StreamingReducer';
import { getUnixDate } from '../../../../helpers/commonUtils';
import {
  getEventsScubberData,
  getTimelineData,
  updatedTimelineData,
} from '../../../../helpers/timelineData';
import constants from '../../../../helpers/en';
import { Utils } from '../../../../helpers';
import {
  disconnectWithMQTT,
  downloadPlaybackStream,
  downloadStream,
} from '../../../../utils/connection/mqttConnection';
import useEventsStore from '../../../../store/EventsStore';
import MultiImageFetch from './MultiImageFetch';
import MultiControlsLive from './MultiControlsLive';
import MultiControlsPlayback from './MultiControlsPlayback';
import {
  sendPauseCVR,
  sendPlayCVR,
} from '../playback/wssConnection/wssConnection';
import { setSnapshotImage } from '../../../../store/StreamingStoreIDB';

class MultiTimelineControls extends React.Component {
  constructor(props) {
    super(props);
    this.timeline = React.createRef();
    // delete later: keeping this for static timeline
    // const currentTime = "November 8, 2022 03:35:52";
    const currentTime = new Date();
    const deviceStart = Utils.getDate(props?.startDate / 1000);
    this.state = {
      minsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: false,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 6 },
        format: {
          majorLabels: {
            second: 'hh:mm',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment().subtract({ minutes: 4, seconds: 20 }).toDate(),
        end: moment(new Date(currentTime))
          .add({ minutes: 4, seconds: 20 })
          .toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime))
          .add({ minutes: 4, seconds: 20 })
          .toDate(),
        selectable: false,
      },
      daysOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'hour', step: 2 },
        format: {
          majorLabels: {
            hour: 'D MMM',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime))
          .subtract({ days: 1, hours: 6 })
          .toDate(),
        end: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ days: 1, hours: 6 }).toDate(),
        selectable: false,
      },
      secsOptions: {
        width: '100%',
        height: '50px',
        zoomKey: 'ctrlKey',
        stack: true,
        stackSubgroups: false,
        showMajorLabels: true,
        showMinorLabels: false,
        showCurrentTime: false,
        orientation: 'top',
        timeAxis: { scale: 'second', step: 1 },
        format: {
          majorLabels: {
            second: 'mm:ss',
          },
          minorLabels: {
            second: 's',
          },
        },
        zoomable: false,
        horizontalScroll: true,
        start: moment(new Date(currentTime)).subtract({ seconds: 40 }).toDate(),
        end: moment(new Date(currentTime)).add({ seconds: 40 }).toDate(),
        min: deviceStart,
        max: moment(new Date(currentTime)).add({ seconds: 40 }).toDate(),
        selectable: false,
      },
      selectedOption: 'Mins',
      activeTime: new Date(currentTime),
      timeZone: 'America/New_York',
      timezoneValue: moment.tz.guess(),
      offset: '-5',
      unixTime: moment(new Date(currentTime)).unix(),
      currentTimeNow: currentTime,
      posts: [],
      cdn: {},
      activeImage: '',
      metaData: [],
      minsView: true,
      secsView: false,
      daysView: false,
      clickAction: false,
      loading: false,
      cvrMode: false,
      CVRSnapMode: false,
      metaDataHere: [],
      minsMetaData: [],
      daysMetaData: [],
      secsMetaData: [],
      liveStreamMode: true,
      manualClicked: false,
      fullscreen: false,
      muteAudio: false,
      deviceId: null,
      startDate: null,
      eventsDataCheck: [],
      eventCategory: [],
      eventTimestamp: null,
      liveSnapshot: false,
      moveTimelineImage: false,
      internalEventFromSearch: false,
      eventCVRMode: null,
      fakeTime: true,
      customeDeviceMetaData: null,
      customEventsDotsData: null,
      holdEventsDotsData: [],
    };
  }

  componentDidMount = () => {
    this.updateTimeZone();
    this.moveTimeline();
    this.fetchEventsData();
    this.updateEventCVRMode();
    this.dynamicTimeLineInterval = setInterval(() => {
      this.setDynamicTimelineMove();
    }, 1000);
    this.setState({
      CVRSnapMode: false,
    });
  };

  componentDidUpdate = (props) => {
    if (this.state.deviceId !== props.deviceId) {
      this.setState({
        deviceId: props?.deviceId,
      });
      // this.props.setSnapshotImage(null);
      this.handleRemoveSnapshotImage();
      this.updateTimeZone();
      this.fetchEventsData();
      if (
        this.state.internalEventFromSearch === props.internalEventFromSearch
      ) {
        this.setState({
          loading: true,
        });
        this.onGoLive();
      }
    }
    if (this.state.startDate !== props.startDate && props?.startDate) {
      this.setState({
        startDate: props?.startDate,
      });
      this.fetchCVRMetaData();
    }

    if (this.state.timezoneValue !== props.timezone && props?.timezone) {
      this.setState({
        timezoneValue: props?.timezone,
      });
      this.updateTimeZone();
    }
    if (this.state.eventCategory !== props.category) {
      this.setState({
        eventCategory: props?.category,
      });
      this.updateEventsPlotting();
    }

    if (this.state.eventTimestamp !== props.eventTimestamp) {
      this.setState({
        loading: true,
      });
      if (this.state.cvrMode) {
        this.sendPauseCVR();
      }
      this.setState({
        eventTimestamp: props?.eventTimestamp,
      });
      if (this.MultiCVRAutoPlay) {
        clearInterval(this.MultiCVRAutoPlay);
      }

      if (
        this.state.internalEventFromSearch !== props.internalEventFromSearch
      ) {
        setTimeout(() => {
          disconnectWithMQTT();
          this.goToEvent(props?.eventTimestamp);
          this.setState({
            internalEventFromSearch: props.internalEventFromSearch,
          });
        }, 1000);
      } else {
        setTimeout(() => {
          this.goToEvent(props?.eventTimestamp);
        }, 1000);
      }
    }

    if (this.state.customEventsDotsData !== props.eventDotsData) {
      if (props.eventDotsData !== null && Array.isArray(props.eventDotsData)) {
        this.setState({
          customEventsDotsData: props.eventDotsData,
        });

        const output = props.eventDotsData?.map((item) => ({
          eventMeta: item.eventMeta,
          deviceId: item.src?.srcId,
          eventTimestamp: Number(item.t),
        }));

        const allData = [];

        output?.forEach((insideEvent, i) => {
          insideEvent?.eventMeta?.events?.forEach((actualEvent, ij) => {
            actualEvent?.event?.forEach((newEvent, index) => {
              if (this.props?.allDeviceIds.includes(insideEvent.deviceId)) {
                const eData = {
                  id: `${i}-${ij}-${index}`,
                  class: newEvent.eventClass.toLowerCase(),
                  timestamp: insideEvent?.eventTimestamp,
                };
                allData.push(eData);
              }
            });
          });
        });

        const eventData = allData.map(function (i) {
          return i.class;
        });
        const obj = {
          start: props?.eventDotsData[0]?.t,
          data: eventData,
          type: 'point',
        };

        this.setState({
          holdEventsDotsData: [...this.state.holdEventsDotsData, obj],
        });
      }
    }

    if (this.state.customeDeviceMetaData !== props.customMetaData) {
      if (props.customMetaData !== null) {
        const newObj = {
          start: props.customMetaData.start * 1000,
          end: props.customMetaData.end * 1000,
        };
        this.setState({
          customeDeviceMetaData: props.customMetaData,
        });

        if (props.customMetaData?.end > this.state.customeDeviceMetaData?.end) {
          this.plotUpdtedSlot(newObj);
        }
      }
    }
  };
  handleRemoveSnapshotImage = async () => {
    try {
      // Set the snapshot image to null
      await setSnapshotImage({});
      // Handle any state updates or UI changes here after successful update
    } catch (error) {}
  };
  getAgesWithGreaterDifference = (arr, endTime) => {
    // Sort the array based on age in ascending order
    arr.sort((a, b) => a.start - b.start);

    // Initialize the result array with the first object
    const result = [arr[0]];

    // Iterate over the remaining objects
    for (let i = 1; i < arr.length; i++) {
      const currentAge = arr[i].start;
      const previousAge = result[result.length - 1].start;

      // Check if the difference is greater than or equal to 3
      if (currentAge - previousAge >= 3000 && currentAge < endTime) {
        result.push(arr[i]);
      }
    }

    return result;
  };

  plotUpdtedSlot = (newObj) => {
    if (newObj?.end && this.state.holdEventsDotsData.length) {
      const newArray = this.getAgesWithGreaterDifference(
        this.state.holdEventsDotsData,
        newObj?.end,
      );
      const minsAdjustment = { minutes: 0 };
      const minsData = updatedTimelineData([newObj], minsAdjustment);
      const mergedItem = [...this.state.minsMetaData, ...minsData];
      this.setState({
        minsMetaData: mergedItem,
      });
      this.setState({
        holdEventsDotsData: [],
      });

      const massagedData = getEventsScubberData(newArray, minsAdjustment);
      const newData = [...this.state.eventsDataCheck, ...massagedData];
      this.setState({
        eventsDataCheck: newData,
      });
      setTimeout(() => {
        this.updateEventsPlotting(newData);
      }, 500);
      //this.timeline?.current?.$el?.setItems([...mergedItem]);
    } else if (newObj?.end) {
      const minsAdjustment = { minutes: 0 };
      const minsData = updatedTimelineData([newObj], minsAdjustment);
      this.setState({
        minsMetaData: [...this.state.minsMetaData, ...minsData],
      });
      this.updateEventsPlotting();
      // this.timeline?.current?.$el?.setItems([...mergedItem]);
    }
  };

  updateEventCVRMode = () => {
    if (!this.props?.eventCVRMode) {
      this.setState({
        CVRSnapMode: true,
        liveStreamMode: false,
        cvrMode: false,
        manualClicked: true,
      });
    }
  };

  goToEvent = (timestamp) => {
    setTimeout(() => {
      if (this.state.activeTime !== Utils.getDate(timestamp / 1000)) {
        this.timeline.current.$el.moveTo(Utils.getDate(timestamp / 1000), [
          { duration: 0 },
        ]);
      }
      if (!this.state.CVRSnapMode) {
        this.moveTimeline();
      }
      this.setState({
        loading: false,
      });
    }, 1500);
    if (this.state.liveStreamMode || this.state.cvrMode) {
      this.setState({
        cvrMode: true,
        liveStreamMode: false,
        liveSnapshot: false,
        CVRSnapMode: false,
      });
      this.sendPlayCVR(
        Utils.getDate(timestamp / 1000),
        this.props?.account?.deviceInformation?.deviceId,
      );
    } else {
      this.setState({
        CVRSnapMode: true,
        liveSnapshot: false,
      });
    }
    switch (this.state.selectedOption) {
      case 'Mins':
        this.fetchEventsDataInRange();
        this.fetchCVRMetaData();
        break;
      case 'Days':
        this.fetchEventsDataInDays();
        break;
      case 'Secs':
        this.fetchEventsDataInSecs();
        break;
      default:
        break;
    }
  };

  updateTimeZone = () => {
    // const getLocationSelected = timezones?.data?.find(
    //   (zone) =>
    //     zone.value ===
    //     this.props?.account?.deviceInformation?.properties?.timezone
    // );
    // const zone = getLocationSelected?.location || moment.tz.guess();
    const zone = moment.tz.guess();

    const UTCDate = moment(new Date()).tz(zone).format();

    if (zone) {
      this.setState({
        timeZone: zone,
        offset: UTCDate,
      });
    }
  };

  moveTimeline = () => {
    if (this.state.liveStreamMode) {
      this.setState({
        activeTime: new Date(),
      });
    }
    if (this.state.selectedOption === 'Days') {
      this.MultiCVRAutoPlay = setInterval(() => {
        this.setState({
          activeTime: moment(this.state.activeTime)
            .add({ seconds: 1 })
            .toDate(),
        });
      }, 1000);
    } else {
      this.MultiCVRAutoPlay = setInterval(() => {
        this.timeline.current.$el.moveTo(
          moment(this.state.activeTime).add({ seconds: 1 }).toDate(),
        );
      }, 1000);
    }
  };

  componentWillUnmount() {
    this.setState({
      internalEventFromSearch: false,
    });
    clearInterval(this.MultiCVRAutoPlay);
    clearInterval(this.dynamicTimeLineInterval);
    if (this.state.cvrMode) {
      this.sendPauseCVR();
    }
  }

  shouldComponentUpdate = () => {
    return true;
  };

  setDynamicTimelineMove = () => {
    const { offset } = this.state;
    this.timeline?.current?.$el.setOptions({
      moment: function (date) {
        return moment(date).utcOffset(offset);
      },
    });
    if (this.state.minsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ minutes: 4, seconds: 21 }).toDate(),
      });
    } else if (this.state.daysView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ days: 1, hours: 6, seconds: 1 }).toDate(),
      });
    } else if (this.state.secsView) {
      this.timeline?.current?.$el.setOptions({
        max: moment(new Date()).add({ seconds: 41 }).toDate(),
      });
    }
  };

  fetchCVRMetaData = () => {
    this.props.setMetaData([]);
    const startTime = this.props?.startDate;
    const endTime = getUnixDate(this.state.currentTimeNow);

    this.props?.allDeviceIds?.forEach((deviceId) => {
      if (startTime && startTime.length !== 0 && endTime) {
        axios
          .get(
            `timeline/device/${deviceId}/metadata?startTime=${startTime}&endTime=${endTime}000`,
          )
          .then((response) => {
            if (response?.data?.data) {
              const metaData = response?.data?.data;
              this.getItemsMetaData(metaData);
            }
          });
      }
    });
  };

  updateEventsPlotting = (eventsData) => {
    let data = [];
    if (eventsData) {
      data = eventsData;
    } else {
      data = this.state.eventsDataCheck;
    }
    // const newData = [];
    // data?.map((event) => {
    //   const found = event?.data?.some(
    //     (r) => this.props?.category.indexOf(r) >= 0
    //   );
    //   if (found) {
    //     newData.push(event);
    //   }
    // });

    const mergedItem = [...this.state.minsMetaData, ...data];
    // this.setState({
    //   minsMetaData: mergedItem,
    // });
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.timeline?.current?.$el?.setItems(filteredArr);
  };

  updateEventsPlottingInRange = (eventsData) => {
    let newData = [];
    if (eventsData) {
      newData = eventsData;
    }
    // eventsData?.map((event) => {
    //   const found = event?.data.some(
    //     (r) => this.props?.category.indexOf(r) >= 0
    //   );
    //   if (found) {
    //     newData.push(event);
    //   }
    // });

    // this.setState({
    //   minsMetaData: [...this.state.minsMetaData, ...newData],
    // });

    this.timeline?.current?.$el?.setItems([
      ...this.state.minsMetaData,
      ...newData,
    ]);
  };

  sendPlayCVR = () => {
    const { allDeviceIds } = this.props;
    const { activeTime } = this.state;
    allDeviceIds?.forEach((deviceId) => {
      Utils.vmsLogger().log('play', deviceId, activeTime);
      sendPlayCVR(activeTime, deviceId);
    });
  };

  sendPauseCVR = () => {
    const { allDeviceIds } = this.props;
    allDeviceIds?.forEach((deviceId) => {
      sendPauseCVR(deviceId);
    });
  };

  fetchEventsData = () => {
    const endTime = getUnixDate(this.state.currentTimeNow);
    const startTime = getUnixDate(
      moment(this.state.currentTimeNow).subtract({
        minutes: 8,
      }),
    );
    let personFilter = '';
    let vehicleFilter = '';
    let faceFilter = '';
    let licensePlateFilter = '';
    let soundFilter = '';
    if (this.props.appliedFilter !== '') {
      personFilter = this.props.appliedFilter.includes('person')
        ? 'person'
        : '';
      vehicleFilter = this.props.appliedFilter.includes('vehicle')
        ? 'vehicle'
        : '';
      faceFilter = this.props.appliedFilter.includes('face') ? 'Face' : '';
      licensePlateFilter = this.props.appliedFilter.includes('licensePlate')
        ? 'LicensePlate'
        : '';
      soundFilter = this.props.appliedFilter.includes('sound')
        ? 'scream, explosion, gun-shot, glass-breaking'
        : '';
    } else {
      personFilter = 'person';
      vehicleFilter = 'vehicle';
      faceFilter = 'Face';
      licensePlateFilter = 'LicensePlate';
      soundFilter = 'scream, explosion, gun-shot, glass-breaking';
    }
    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.streaming?.liveCameraIds?.length
    ) {
      // axios
      //   .get(
      //     `timeline/orgs/${
      //       this.props?.account?.userdata[0]?.orgId
      //     }/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.streaming?.liveCameraIds?.join(
      //       ','
      //     )}&ascOrder=false&aggBy=minute`
      //   )

      axios
        .get(
          `timeline/orgs/${
            this.props?.account?.userdata[0]?.orgId
          }/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.streaming?.liveCameraIds?.join(
            ',',
          )}&ascOrder=false&eventClasses=${personFilter ?? ''}&eventClasses=${
            vehicleFilter ?? ''
          }&eventClasses=${licensePlateFilter ?? ''}&eventClasses=${
            soundFilter ?? ''
          }&eventClasses=${faceFilter ?? ''}&aggBy=minute`,
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const minsAdjustment = { minutes: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              minsAdjustment,
            );
            this.setState({
              eventsDataCheck: massagedData,
            });
            this.updateEventsPlotting(massagedData);
          }
        });
    }
  };

  fetchEventsDataInRange = () => {
    const endTime = getUnixDate(
      moment(this.state.activeTime).add({
        minutes: 8,
      }),
    );
    const startTime = getUnixDate(
      moment(this.state.activeTime).subtract({
        minutes: 8,
      }),
    );
    let personFilter = '';
    let vehicleFilter = '';
    let faceFilter = '';
    let licensePlateFilter = '';
    let soundFilter = '';

    if (this.props.appliedFilter !== '') {
      personFilter = this.props.appliedFilter?.includes('person')
        ? 'person'
        : '';
      vehicleFilter = this.props.appliedFilter?.includes('vehicle')
        ? 'vehicle'
        : '';
      faceFilter = this.props.appliedFilter?.includes('face') ? 'Face' : '';
      licensePlateFilter = this.props.appliedFilter?.includes('licensePlate')
        ? 'LicensePlate'
        : '';
      soundFilter = this.props.appliedFilter?.includes('sound')
        ? 'scream, explosion, gun-shot, glass-breaking'
        : '';
    } else {
      personFilter = 'person';
      vehicleFilter = 'vehicle';
      faceFilter = 'Face';
      licensePlateFilter = 'LicensePlate';
      soundFilter = 'scream, explosion, gun-shot, glass-breaking';
    }
    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.streaming?.liveCameraIds?.length
    ) {
      axios
        .get(
          `timeline/orgs/${
            this.props?.account?.userdata[0]?.orgId
          }/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.streaming?.liveCameraIds?.join(
            ',',
          )}&ascOrder=false&eventClasses=${personFilter ?? ''}&eventClasses=${
            vehicleFilter ?? ''
          }&eventClasses=${licensePlateFilter ?? ''}&eventClasses=${
            soundFilter ?? ''
          }&eventClasses=${faceFilter ?? ''}&aggBy=minute`,
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const minsAdjustment = { minutes: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              minsAdjustment,
            );
            this.setState({
              eventsDataCheck: massagedData,
              holdEventsDotsData: [],
            });
            this.updateEventsPlottingInRange(massagedData);
          }
        });
    }
  };

  fetchEventsDataInDays = () => {
    const endTime = getUnixDate(
      moment(this.state.currentTimeNow).add({
        days: 4,
      }),
    );
    const startTime = getUnixDate(
      moment(this.state.currentTimeNow).subtract({
        days: 4,
      }),
    );
    let personFilter = '';
    let vehicleFilter = '';
    let faceFilter = '';
    let licensePlateFilter = '';
    let soundFilter = '';
    if (this.props.appliedFilter !== '') {
      personFilter = this.props.appliedFilter.includes('person')
        ? 'person'
        : '';
      vehicleFilter = this.props.appliedFilter.includes('vehicle')
        ? 'vehicle'
        : '';
      faceFilter = this.props.appliedFilter.includes('face') ? 'Face' : '';
      licensePlateFilter = this.props.appliedFilter.includes('licensePlate')
        ? 'LicensePlate'
        : '';
      soundFilter = this.props.appliedFilter.includes('sound')
        ? 'scream, explosion, gun-shot, glass-breaking'
        : '';
    } else {
      personFilter = 'person';
      vehicleFilter = 'vehicle';
      faceFilter = 'Face';
      licensePlateFilter = 'LicensePlate';
      soundFilter = 'scream, explosion, gun-shot, glass-breaking';
    }
    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.streaming?.liveCameraIds?.length
    ) {
      axios
        .get(
          `timeline/orgs/${
            this.props?.account?.userdata[0]?.orgId
          }/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.streaming?.liveCameraIds?.join(
            ',',
          )}&ascOrder=false&eventClasses=${personFilter ?? ''}&eventClasses=${
            vehicleFilter ?? ''
          }&eventClasses=${licensePlateFilter ?? ''}&eventClasses=${
            soundFilter ?? ''
          }&eventClasses=${faceFilter ?? ''}&aggBy=day`,
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const daysAdjustment = { days: 1, hours: 6 };
            const massagedData = getEventsScubberData(
              finalData,
              daysAdjustment,
            );
            this.setState({
              eventsDataCheck: massagedData,
            });
            this.updateEventsPlottingInRangeDays(massagedData);
          }
        });
    }
  };

  updateEventsPlottingInRangeDays = (eventsData) => {
    const newData = [];
    eventsData?.forEach((event) => {
      const found = event?.data.some(
        (r) => this.props?.category.indexOf(r) >= 0,
      );
      if (found) {
        newData.push(event);
      }
    });

    const mergedItem = [...this.state.daysMetaData, ...newData];
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.timeline?.current?.$el?.setItems(filteredArr);
    this.updateEventsPlotting(eventsData);
  };

  fetchEventsDataInSecs = () => {
    const endTime = getUnixDate(this.state.currentTimeNow);
    const startTime = getUnixDate(
      moment(this.state.currentTimeNow).subtract({
        seconds: 240,
      }),
    );
    let personFilter = '';
    let vehicleFilter = '';
    let faceFilter = '';
    let licensePlateFilter = '';
    let soundFilter = '';
    if (this.props.appliedFilter !== '') {
      personFilter = this.props.appliedFilter.includes('person')
        ? 'person'
        : '';
      vehicleFilter = this.props.appliedFilter.includes('vehicle')
        ? 'vehicle'
        : '';
      faceFilter = this.props.appliedFilter.includes('face') ? 'Face' : '';
      licensePlateFilter = this.props.appliedFilter.includes('licensePlate')
        ? 'LicensePlate'
        : '';
      soundFilter = this.props.appliedFilter.includes('sound')
        ? 'scream, explosion, gun-shot, glass-breaking'
        : '';
    } else {
      personFilter = 'person';
      vehicleFilter = 'vehicle';
      faceFilter = 'Face';
      licensePlateFilter = 'LicensePlate';
      soundFilter = 'scream, explosion, gun-shot, glass-breaking';
    }
    if (
      startTime &&
      endTime &&
      this.props?.account?.userdata[0]?.orgId &&
      this.props?.streaming?.liveCameraIds?.length
    ) {
      axios
        .get(
          `timeline/orgs/${
            this.props?.account?.userdata[0]?.orgId
          }/events/agg/count?endTime=${endTime}000&startTime=${startTime}000&deviceIds=${this.props?.streaming?.liveCameraIds?.join(
            ',',
          )}&ascOrder=false&eventClasses=${personFilter ?? ''}&eventClasses=${
            vehicleFilter ?? ''
          }&eventClasses=${licensePlateFilter ?? ''}&eventClasses=${
            soundFilter ?? ''
          }&eventClasses=${faceFilter ?? ''}&aggBy=sec`,
        )
        .then((response) => {
          if (response?.data?.data) {
            const scrubberEvents = Object.entries(response?.data?.data?.result);
            const finalData =
              scrubberEvents?.map((event) => ({
                start: event[0],
                data: Object.keys(event[1]),
                type: 'point',
              })) || [];
            const secsAdjustment = { seconds: 0 };
            const massagedData = getEventsScubberData(
              finalData,
              secsAdjustment,
            );
            this.setState({
              eventsDataCheck: massagedData,
            });
            this.updateEventsPlottingInRangeSecs(massagedData);
          }
        });
    }
  };

  updateEventsPlottingInRangeSecs = (eventsData) => {
    const newData = [];
    eventsData?.forEach((event) => {
      const found = event?.data.some(
        (r) => this.props?.category.indexOf(r) >= 0,
      );
      if (found) {
        newData.push(event);
      }
    });

    const mergedItem = [...this.state.secsMetaData, ...newData];
    const filteredArr = mergedItem.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    this.timeline?.current?.$el?.setItems(filteredArr);
  };

  // Todo: Delete later
  // currentTimeTickHandler = (event) => {Utils.vmsLogger().log("currentTimeTickHandler: ", event)};
  // clickHandler = (event) => {Utils.vmsLogger().log("clickHandler: ", event)};
  // mouseOverHandler = (event) => {Utils.vmsLogger().log("mouseOverHandler: ", event)};
  // mouseMoveHandler = (event) => {Utils.vmsLogger().log("mouseMoveHandler: ", event)};
  // timechangeHandler = (event) => {Utils.vmsLogger().log("timechangeHandler: ", event)};
  // timechangedHandler = (event) => {Utils.vmsLogger().log("timechangedHandler: ", event)};

  rangeChangeHandler = (event) => {
    let timeInMin = moment(event.start)
      .add({ minutes: 4, seconds: 20 })
      .toDate();
    let timeInDay = moment(event.start).add({ days: 1, hours: 6 }).toDate();
    let timeInSec = moment(event.start).add({ seconds: 40 }).toDate();
    if (event.byUser && this.state.fakeTime) {
      if (
        this.state.selectedOption === 'Mins' &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        this.setState({
          activeTime: timeInMin,
        });
      } else if (
        this.state.selectedOption === 'Days' &&
        timeInDay !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInDay,
        });
      } else if (
        this.state.selectedOption === 'Secs' &&
        timeInSec !== this.state.activeTime
      ) {
        this.setState({
          activeTime: timeInSec,
        });
      }
      if (
        moment(this.state.activeTime).unix() !==
          moment(this.currentTime).unix() &&
        !this.state.clickAction
      ) {
        this.currentTime = this.state.activeTime;
      }
      this.setState({
        CVRSnapMode: true,
        liveSnapshot: false,
        moveTimelineImage: true,
      });
      if (this.state.liveStreamMode) {
        disconnectWithMQTT();
        this.setState({
          liveStreamMode: false,
        });
      }
      this.sendPauseCVR();
    } else {
      if (
        this.state.minsView &&
        Utils.getUnixDate(timeInMin) !==
          Utils.getUnixDate(this.state.activeTime)
      ) {
        this.setState({
          activeTime: moment(event.start)
            .add({ minutes: 4, seconds: 20 })
            .toDate(),
        });
      } else if (this.state.daysView) {
        this.setState({
          activeTime: moment(event.start).add({ days: 1, hours: 6 }).toDate(),
        });
      } else if (this.state.secsView) {
        this.setState({
          activeTime: moment(event.start).add({ seconds: 40 }).toDate(),
        });
      }
    }
  };

  rangeChangedHandler = (event) => {
    if (event.byUser) {
      const time = moment(event.end)
        .subtract({ minutes: 4, seconds: 20 })
        .toDate();

      if (
        this.state.cvrMode ||
        (!this.state.manualClicked && time < new Date())
      ) {
        this.setState({
          CVRSnapMode: false,
        });
        this.sendPlayCVR(
          this.state.activeTime,
          this.props?.account?.deviceInformation?.deviceId,
        );
      }
      this.setState({
        moveTimelineImage: false,
      });

      if (!this.state.manualClicked) {
        this.setState({
          cvrMode: true,
        });

        if (this.state.manualClicked && this.state.cvrMode) {
          this.moveTimeline();
        }
      }
      switch (this.state.selectedOption) {
        case 'Mins':
          this.fetchEventsDataInRange();
          this.fetchCVRMetaData();
          break;
        case 'Days':
          this.fetchEventsDataInDays();
          break;
        case 'Secs':
          this.fetchEventsDataInSecs();
          break;
        default:
          break;
      }
      // if (time > new Date() && this.state.fakeTime) {
      //   this.setState({
      //     fakeTime: false,
      //   });
      //   this.onGoLive();
      //   setTimeout(() => {
      //     this.setState({
      //       fakeTime: true,
      //     });
      //   }, 1500);
      // }
    }
  };

  onClick = (dates, currentDate, type) => {
    const data = dateClosest(dates, currentDate);
    if (type === 'previous') {
      const previousTime = data.datesAfter[0].date;
      this.timeline.current.$el.moveTo(new Date(previousTime));
    } else {
      const nextTime = data.datesBefore[0].date;
      this.timeline.current.$el.moveTo(new Date(nextTime));
    }
  };

  onPreviousIconClick = () => {
    const newData = [];
    this.state.eventsDataCheck?.forEach((event) => {
      const found = event?.data.some(
        (r) => this.props?.category.indexOf(r) >= 0,
      );
      if (found) {
        newData.push(event);
      }
    });
    const activeScrubber = Utils.getUnixDate(this.state.activeTime);

    this.setState({
      liveSnapshot: false,
      moveTimelineImage: false,
    });

    let result = newData?.map((a) => Utils.getUnixDate(a.start));
    const data = dateClosest(result, activeScrubber);
    if (data) {
      const nextTime = data.datesAfter[0].date;
      useEventsStore.setState({
        selectedTimestamp: Utils.getUnixDate(nextTime) * 1000,
      });
      this.timeline.current.$el.moveTo(new Date(nextTime));
    }
  };

  onNextIconClick = () => {
    const newData = [];
    this.state.eventsDataCheck?.forEach((event) => {
      const found = event?.data.some(
        (r) => this.props?.category.indexOf(r) >= 0,
      );
      if (found) {
        newData.push(event);
      }
    });
    const activeScrubber = Utils.getUnixDate(this.state.activeTime);

    this.setState({
      liveSnapshot: false,
      moveTimelineImage: false,
    });

    let result = newData?.map((a) => Utils.getUnixDate(a.start));
    const data = dateClosest(result, activeScrubber);
    if (data) {
      const nextTime = data.datesBefore[0].date;
      useEventsStore.setState({
        selectedTimestamp: Utils.getUnixDate(nextTime) * 1000,
      });
      this.timeline.current.$el.moveTo(new Date(nextTime));
    }
  };

  onMuteIconClick = () => {
    this.setState({
      muteAudio: !this.state.muteAudio,
    });
  };

  onClickMins = () => {
    switch (this.state.selectedOption) {
      case 'Mins':
        this.fetchEventsDataInDays();
        this.setState({
          minsView: false,
          daysView: true,
          secsView: false,
          selectedOption: 'Days',
          clickAction: true,
        });
        break;
      case 'Days':
        this.fetchEventsDataInSecs();
        this.setState({
          minsView: false,
          daysView: false,
          secsView: true,
          selectedOption: 'Secs',
          clickAction: true,
        });
        break;
      case 'Secs':
        this.fetchEventsData();
        this.setState({
          minsView: true,
          daysView: false,
          secsView: false,
          selectedOption: 'Mins',
          clickAction: true,
        });
        break;
      default:
        break;
    }

    setTimeout(() => {
      this.setState({
        clickAction: false,
      });
    }, 300);
  };

  onGoLive = () => {
    this.setState({
      liveStreamMode: true,
      activeTime: new Date(),
      manualClicked: false,
      loading: true,
    });

    if (this.state.cvrMode) {
      this.sendPauseCVR();
      this.setState({
        cvrMode: false,
      });
    }
    this.timeline.current.$el.moveTo(new Date());

    setTimeout(() => {
      if (this.state.activeTime !== new Date()) {
        this.timeline.current.$el.moveTo(new Date());
      }
      this.setState({
        loading: false,
      });
    }, 2500);

    if (this.state.CVRSnapMode) {
      this.moveTimeline();
      this.setState({
        CVRSnapMode: false,
      });
    }
    // this.props.handleClick(constants.DEVICES_SWITCH_LIVE_TITLE);
    switch (this.state.selectedOption) {
      case 'Mins':
        this.fetchEventsDataInRange();
        this.fetchCVRMetaData();
        break;
      case 'Days':
        this.fetchEventsDataInDays();
        break;
      case 'Secs':
        this.fetchEventsDataInSecs();
        break;
      default:
        break;
    }
  };

  onPauseCVR = (e) => {
    if (this.state.cvrMode) {
      this.sendPauseCVR();
      this.setState({
        cvrMode: false,
        CVRSnapMode: true,
      });
    }
    if (this.state.liveStreamMode) {
      disconnectWithMQTT();
      this.setState({
        CVRSnapMode: true,
      });
      this.setState({
        liveStreamMode: false,
        liveSnapshot: true,
      });
    }
    this.setState({
      manualClicked: true,
    });
    clearInterval(this.MultiCVRAutoPlay);
    this.fetchEventsDataInRange();
    this.fetchCVRMetaData();
  };

  onPlayCVR = () => {
    this.setState({
      cvrMode: true,
      liveStreamMode: false,
      CVRSnapMode: false,
      eventCVRMode: true,
    });
    this.sendPlayCVR(
      this.state.activeTime,
      this.props?.account?.deviceInformation?.deviceId,
    );
    this.moveTimeline();
  };

  getOptions = () => {
    if (this.state.minsView) {
      return this.state.minsOptions;
    } else if (this.state.daysView) {
      return this.state.daysOptions;
    } else if (this.state.secsView) {
      return this.state.secsOptions;
    }
  };

  getItems = () => {
    if (this.state.minsView) {
      return this.state.minsMetaData;
    } else if (this.state.daysView) {
      return this.state.daysMetaData;
    } else if (this.state.secsView) {
      return this.state.secsMetaData;
    }
  };

  getItemsMetaData = (data) => {
    const minsAdjustment = { minutes: 0 };
    const daysAdjustment = { days: 1, hours: 6 };
    const secsAdjustment = { seconds: 40 };

    const minsData = getTimelineData(data, minsAdjustment);
    const daysData = getTimelineData(data, daysAdjustment);
    const secsData = getTimelineData(data, secsAdjustment);

    this.setState({
      minsMetaData: minsData,
      daysMetaData: daysData,
      secsMetaData: secsData,
    });
  };

  enterFullScreen = () => {
    this.setState({
      fullscreen: true,
    });
    this.props.enterFullScreen();
  };

  exitFullScreen = () => {
    this.setState({
      fullscreen: false,
    });
    this.props.exitFullScreen();
  };

  downloadLiveImage = () => {
    const deviceName = this.props?.account?.deviceInformation?.deviceName;
    const time = moment(this.state.activeTime).format(
      'YYYY-MM-DD - hh.mm.ss A',
    );
    downloadStream(`${deviceName} - ${time}`);
  };

  downloadPlaybackImage = async () => {
    const deviceName = this.props?.account?.deviceInformation?.deviceName;
    const time = moment(this.state.activeTime).format(
      'YYYY-MM-DD - hh.mm.ss A',
    );
    if (!this.state.CVRSnapMode) {
      downloadPlaybackStream(`${deviceName} - ${time}`);
    } else {
      fetch(this.state.activeImage, {
        credentials: 'include',
      })
        .then((response) => response.blob())
        .then((blob) => {
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.download = `${deviceName} - ${time}`;
          a.href = blobUrl;
          document.body.appendChild(a);
          a.click();
        });
    }
  };

  render() {
    return (
      <div className="wrapper-app live-streaming">
        <div className="container multiviewcontrols">
          {this.state.liveStreamMode && (
            <div className="liveview">
              {/* {this.state.loading && (
                <img
                  className="video-processing"
                  src={videoProcessing}
                  alt="video processing"
                />
              )} */}
              <MultiControlsLive
                onClick={(deviceId) =>
                  this.props.onCameraDetailsClick(
                    deviceId,
                    this.state.activeTime,
                    this.state.cvrMode,
                    true,
                  )
                }
                muteAudio={this.state.muteAudio}
              />
            </div>
          )}

          {!this.state.liveStreamMode && (
            <div className="stream-timeline-wrapper multi-stream-wrapper">
              {/* <div className="events-icons-wrapper">
                <span
                  className="icons-image previous-icon"
                  onClick={() => this.onPreviousIconClick()}
                >
                  <ArrowLeft width={18} />
                </span>
                <span
                  className="icons-image next-icon"
                  onClick={() => this.onNextIconClick()}
                >
                  <ArrowRight width={18} />
                </span>
              </div> */}
              <>
                {this.state.activeTime && this.state.CVRSnapMode && (
                  <div>
                    <MultiImageFetch
                      onClick={(deviceId) =>
                        this.props.onCameraDetailsClick(
                          deviceId,
                          this.state.activeTime,
                          this.state.cvrMode,
                        )
                      }
                      moveTimeline={this.state.moveTimelineImage}
                      liveSnapshot={this.state.liveSnapshot}
                      time={this.state.activeTime}
                      cdnValue={this.props?.streaming?.cdnInfo}
                    />
                  </div>
                )}

                {!this.state.CVRSnapMode && (
                  <MultiControlsPlayback
                    onClick={(deviceId) =>
                      this.props.onCameraDetailsClick(
                        deviceId,
                        this.state.activeTime,
                        this.state.cvrMode,
                      )
                    }
                    time={this.state.activeTime}
                    muteAudio={this.state.muteAudio}
                    cdnValue={this.props?.streaming?.cdnInfo}
                  />
                )}
              </>
            </div>
          )}
        </div>

        <div className="stream-timeline-wrapper timeline-controls-page multiview-timeline">
          <div className={`scrubber-wrapper`}>
            <div className="timelines-controls-wrapper">
              {this.state.liveStreamMode && (
                <>
                  <div className="timeline-controls-icons">
                    {!(this.state.cvrMode || this.state.liveStreamMode) && (
                      <span
                        onClick={() => this.onPlayCVR()}
                        className="icons-image"
                      >
                        <img width={16} src={play} alt="icon" />
                      </span>
                    )}

                    {(this.state.cvrMode || this.state.liveStreamMode) && (
                      <span
                        onClick={(e) => this.onPauseCVR(e)}
                        className="icons-image"
                      >
                        <img width={16} src={pause} alt="icon" />
                      </span>
                    )}
                    {/* <span
                      className="icons-image"
                      onClick={() => this.onMuteIconClick()}
                    >
                      <img
                        width={16}
                        src={this.state.muteAudio ? mute : unmute}
                        alt="icon"
                      />
                    </span> */}
                    {/* <span
                      className="icons-image"
                      onClick={() => this.downloadLiveImage()}
                    >
                      <img src={camera} alt="icon" />
                    </span> */}
                    <button onClick={() => this.onClickMins()} className="mins">
                      {this.state.selectedOption === 'Mins' ? 'Mins' : ''}
                      {this.state.selectedOption === 'Days' ? 'Days' : ''}
                      {this.state.selectedOption === 'Secs' ? 'Secs' : ''}
                    </button>
                  </div>
                  <div className="live">Live</div>
                  {/* <div onClick={() => this.onGoLive()}>Go Live</div> */}
                  <div className={`active_time`}>
                    {moment
                      .tz(moment(this.state.activeTime), this.state.timeZone)
                      .format('hh:mm:ss A z')}
                    <span
                      className="icons-image"
                      onClick={() =>
                        this.state.fullscreen
                          ? this.exitFullScreen()
                          : this.enterFullScreen()
                      }
                    >
                      <img src={fullscreen} alt="icon" />
                    </span>
                  </div>
                </>
              )}
              {!this.state.liveStreamMode && (
                <>
                  <div className="timeline-controls-icons">
                    {!(
                      this.state.cvrMode ||
                      this.state.liveStreamMode ||
                      !this.state.manualClicked
                    ) && (
                      <span
                        onClick={() => this.onPlayCVR()}
                        className="icons-image"
                      >
                        <img width={16} src={play} alt="icon" />
                      </span>
                    )}

                    {(this.state.cvrMode ||
                      this.state.liveStreamMode ||
                      !this.state.manualClicked) && (
                      <span
                        onClick={(e) => this.onPauseCVR(e)}
                        className="icons-image"
                      >
                        <img width={16} src={pause} alt="icon" />
                      </span>
                    )}

                    {/* <span
                      className="icons-image"
                      onClick={() => this.onPreviousIconClick()}
                    >
                      <img width={16} src={previous} alt="previous icon" />
                    </span>
                    <span
                      className="icons-image"
                      onClick={() => this.onNextIconClick()}
                    >
                      <img width={16} src={next} alt="next icon" />
                    </span>
                    <span
                      className="icons-image"
                      onClick={() => this.onMuteIconClick()}
                    >
                      <img
                        width={16}
                        src={this.state.muteAudio ? mute : unmute}
                        alt="icon"
                      />
                    </span> */}
                    {/* <span
                      className="icons-image"
                      onClick={() => this.downloadPlaybackImage()}
                    >
                      <img src={camera} alt="icon" />
                    </span> */}
                    <button onClick={() => this.onClickMins()} className="mins">
                      {this.state.selectedOption === 'Mins' ? 'Mins' : ''}
                      {this.state.selectedOption === 'Days' ? 'Days' : ''}
                      {this.state.selectedOption === 'Secs' ? 'Secs' : ''}
                    </button>
                  </div>
                  {/* <div className="live">Live</div>
                  <div onClick={() => this.onGoLive()}>Go Live</div> */}
                  <div className={`active_time`}>
                    {moment
                      .tz(moment(this.state.activeTime), this.state.timeZone)
                      .format('MMM DD, YYYY - hh:mm:ss A z')}
                  </div>
                  <div className="golive-view">
                    <button className="live" onClick={() => this.onGoLive()}>
                      Go Live
                    </button>
                    <span
                      className="icons-image"
                      onClick={() =>
                        this.state.fullscreen
                          ? this.exitFullScreen()
                          : this.enterFullScreen()
                      }
                    >
                      <img src={fullscreen} alt="icon" />
                    </span>
                  </div>
                </>
              )}
            </div>
            {!this.state.clickAction && this.state.metaDataHere && (
              <div className={`scrubber`}>
                <Timeline
                  ref={this.timeline}
                  // items={this.getItems()}
                  options={this.getOptions()}
                  rangechangeHandler={(event) => this.rangeChangeHandler(event)}
                  rangechangedHandler={(event) =>
                    this.rangeChangedHandler(event)
                  }
                />
              </div>
            )}
          </div>
          {this.state.liveStreamMode && (
            <div className={`timeline-icons golive`}>
              <button onClick={() => this.onGoLive()} className="golive-button">
                {constants.TOP_HEADER_LIVE_NAV_TITLE}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setMetaData,
  setCDNInfo,
  setLiveStream,
  setRemoteStream,
};

const mapStoreStateToProps = (state) => ({
  account: state.accounts,
  streaming: state.streaming,
});

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps,
)(MultiTimelineControls);
