import { useForm } from 'react-hook-form';
import { constants, Utils } from '../../../helpers';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { useState } from 'react';
import { PrimaryButton, SiteModal } from '../../../components/common';
import {
  addBandwidthEstimation,
  updateBandwidthEstimation,
} from './BandwidthHelper';

const SaveBWChangesForm = ({
  popupTitle,
  subscriptionPlans,
  liveStreamUsers,
  liveStreamHours,
  onHideModal,
  bitrate,
  orgId,
  name = '',
  bwEstimationDetails,
  onSavedSuccess,
  isUpdateAPI,
  isAllProperties,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid, isDirty },
    watch,
    reset,
    setError,
  } = useForm({
    mode: 'onChange', // Trigger validation on change
  });
  const watchedFields = watch();
  const [loading, setLoading] = useState(false);

  const handleSaveBandwidthEstimate = async (data) => {
    try {
      if (!isDirty || !isValid || name === watchedFields?.bwEstimationName) {
        return;
      }
      setLoading(true);
      let resData = undefined;
      let reqBody = undefined;

      if (isAllProperties) {
        const filteredPlans =
          subscriptionPlans
            ?.filter((plan) => plan.plan)
            ?.map((plan) => ({
              plan: bitrate.find((b) => b.planId === plan.plan)?.sname,
              planDetails: plan.planDetails
                .filter((detail) => detail.cameras > 0) // Keep only details with cameras > 0
                .map((x) => {
                  return { cameras: x.cameras, videoBitrate: x.videoBitrate };
                }),
            }))
            ?.filter((plan) => plan.planDetails.length > 0) || // Ensure 'planDetails' is not empty
          [];
        reqBody = {
          name: data.bwEstimationName.trim(),
          type: constants.BANDWIDTH_ADD_ESTIMATION_TYPE,
          liveStreamUsers: liveStreamUsers,
          liveStreamDuration: liveStreamHours,
          subscriptionPlan: [...filteredPlans],
        };
      } else {
        reqBody = {
          name: data.bwEstimationName.trim(),
          type: constants.BANDWIDTH_ADD_ESTIMATION_TYPE,
          liveStreamUsers: bwEstimationDetails.liveStreamUsers,
          liveStreamDuration: bwEstimationDetails.liveStreamDuration,
          subscriptionPlan: bwEstimationDetails.subscriptionPlan,
        };
      }

      if (isUpdateAPI) {
        resData = await updateBandwidthEstimation(
          orgId,
          bwEstimationDetails?.estimateId,
          reqBody,
        );
      } else {
        resData = await addBandwidthEstimation(orgId, reqBody);
      }

      if (resData?.meta.code === 200) {
        onSavedSuccess(resData?.data);
        reset({ bwEstimationName: '' });
      } else if (resData?.meta?.userMsg) {
        setError('bwEstimationName', {
          type: 'manual',
          message: resData?.meta?.userMsg,
        });
      }
    } catch (err) {
      Utils.vmsLogger().error('Error while save bandwidth estimation:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SiteModal
      key={'save-bw-estimation-modal'}
      classes="save-bw-estimation-modal bandwidth-popup"
      wrapperClass="bw-estimation-modal-wrapper"
      modalTitle={popupTitle}
      showModal={true}
      hideModal={onHideModal}
      modalFooter={
        <div className="bw-estimation-button-group mt-4">
          <PrimaryButton
            className="btn btn-primary"
            type="submit"
            minHeight="56px"
            height="56px"
            fontSize="1.125rem"
            lineHeight="1.5rem"
            fontWeight="600"
            disabled={
              !isDirty || !isValid || name === watchedFields?.bwEstimationName
            }
            loader={loading}
            onClick={handleSubmit(handleSaveBandwidthEstimate)}
          >
            {constants.BANDWIDTH_SAVE_ESTIMATE_MODAL_SAVE_BTN}
          </PrimaryButton>
          <PrimaryButton
            className="btn btn-primary-outline mt-2"
            type="button"
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            hoverBorderColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40',
            )}
            hoverColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--brand_white')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            backgroundColor="transparent"
            minHeight="56px"
            height="56px"
            fontSize="1.125rem"
            lineHeight="1.5rem"
            fontWeight="600"
            onClick={onHideModal}
          >
            {constants.BANDWIDTH_SAVE_ESTIMATE_MODAL_CANCEL_BTN}
          </PrimaryButton>
        </div>
      }
    >
      <form onSubmit={handleSubmit(handleSaveBandwidthEstimate)}>
        <div className="bw-name-container mt-4">
          <div className="placeholder-wrap">
            <input
              {...register('bwEstimationName', {
                required:
                  constants.BANDWIDTH_SAVE_ESTIMATE_MODAL_NAME_REQUIRE_MESSAGE,
              })}
              defaultValue={name || ''}
              className={`bw-estimation-name-input ${
                errors.bwEstimationName ? 'input-error' : ''
              } ${
                watchedFields?.bwEstimationName?.length > 0 ? 'has-value' : ''
              }`}
              autoFocus
              maxLength={100}
            />
            {!watchedFields?.bwEstimationName && (
              <div className="custom-placeholder">
                {constants.BANDWIDTH_SAVE_ESTIMATE_MODAL_NAME_PLACEHOLDER}
                <span className="required">*</span>
              </div>
            )}
          </div>
          <div className="helper-text-wrapper mt-1">
            <div className="helper-text">
              {errors.bwEstimationName && (
                <>
                  <HiOutlineExclamationCircle />
                  <div className="error">{errors.bwEstimationName.message}</div>
                </>
              )}
            </div>
            <div
              className={`text-count ${
                errors?.bwEstimationName ? 'error' : ''
              }`}
            >
              {watchedFields?.bwEstimationName?.length || 0}/100
            </div>
          </div>
        </div>
      </form>
    </SiteModal>
  );
};

export default SaveBWChangesForm;
