import { useState, useEffect } from 'react';
import { FaCircle } from 'react-icons/fa';
import { Tooltip, tooltipClasses } from '@mui/material';
import { constants, Utils } from '../../../../helpers';
import axios from 'axios';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import timezones from '../../../../data/support/timezone.json';

import moment from 'moment';
import { SiteSpinner } from '../../../../components/common';
import { getAllDevicesData } from '../../../../store/AccountStoreIDB';
// import { getAllDevicesData } from '../../../../store/reducers/AccountReducer';
import { findTimeZoneFromSelectedValue } from '../../../../helpers/commonUtils';

const RestoredDevicesTable = ({ deviceInfos, showRecordEvent }) => {
  const [restoredData, setRestoredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const timezone = deviceInfos?.properties?.timezone;
  // const devicesDetails = useSelector(getAllDevicesData);
  const [devicesDetails, setDevicesDetails] = useState([]);
  const zoneTimeList = timezones?.data;

  useEffect(() => {
    const fetchDevices = async () => {
      const allDevices = await getAllDevicesData();
      setDevicesDetails(allDevices);
    };

    fetchDevices();
  }, []);

  useEffect(() => {
    if (deviceInfos?.deviceId && devicesDetails?.length) {
      setIsLoading(true);
      fetchRestoredData(new Date().getTime());
    }
  }, [deviceInfos?.deviceId, devicesDetails?.length]);

  const fetchRestoredData = async (currentTime) => {
    try {
      const synChUrl = showRecordEvent
        ? `/device/orgs/${deviceInfos.orgId}/devices/${
            deviceInfos.gatewayId
          }/media/sync?size=${10}&currentTime=${currentTime}&deviceId=${
            deviceInfos?.deviceId
          }`
        : `/device/orgs/${deviceInfos.orgId}/devices/${
            deviceInfos.deviceId
          }/media/sync?size=${10}&currentTime=${currentTime}`;

      const res = await axios.get(synChUrl, Utils.requestHeader());
      if (
        parseInt(res?.status) === 202 ||
        parseInt(res?.data?.meta?.code) === 200
      ) {
        const rawData = JSON.parse(JSON.stringify(res?.data?.data));
        rawData.forEach((element) => {
          const deviceInfo = devicesDetails.find(
            (dev) => dev.deviceId === element.deviceId
          );
          element.dispalyName = deviceInfo?.deviceName;
          element.displayRestorationStartTime = getDisplayDate(
            element.restorationStartTime,
            element
          );
          element.displayRestorationEndTime = getDisplayDate(
            element.restorationEndTime,
            element
          );
          element.displayOutageStartTime = getDisplayDate(
            element.outageStartTime,
            element
          );
          element.displayOutageEndTime = getDisplayDate(
            element.outageEndTime,
            element
          );
        });
        setRestoredData([...restoredData, ...rawData]);
        setHasMore(res?.data?.data?.length >= 10);
        setIsLoading(false);
        setIsLoadingMore(false);
      } else {
        setIsLoading(false);
        setIsLoadingMore(false);
      }
    } catch (error) {
      // TODO : to print the error will update this later
      Utils.vmsLogger().error(error);
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const fetchRestoredDataMore = async () => {
    if (!isLoadingMore) {
      setIsLoadingMore(true);
      fetchRestoredData(
        showRecordEvent
          ? restoredData[restoredData?.length - 1]?.createdDate
          : restoredData[restoredData?.length - 1]?.createdDateDeviceId
      );
    }
  };

  const getDisplayDate = (rawDate, data) => {
    let displayValue;
    if (
      !rawDate &&
      data?.status ===
        constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_NOT_STARTED
    ) {
      displayValue =
        constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_END_TIME_PENDING;
    } else if (
      !rawDate &&
      data?.status ===
        constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_IN_PROGRESS
    ) {
      displayValue = '';
    } else if (
      !rawDate &&
      data?.status ===
        constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_FAILED
    ) {
      displayValue =
        constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_END_TIME_UNAVAILABLE;
    } else if (rawDate) {
      displayValue = moment
        .tz(
          moment(new Date(rawDate), 'DD MMM YYYY hh:mma'),
          timezone
            ? findTimeZoneFromSelectedValue(timezone)?.location
            : moment.tz.guess()
        )
        .format('DD MMM YYYY hh:mma');
    }
    return displayValue;
  };

  const getTooltipData = (row, column) => {
    if (column === 'status') {
      return (
        <div>
          {row?.status ===
          constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_NOT_STARTED ? (
            <div>
              <FaCircle
                className="status-indicator status-offline"
                style={{
                  color: `${getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_48')}`,
                }}
              />
              {
                constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_NOT_STARTED_LABEL
              }
            </div>
          ) : row?.status ===
            constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_FAILED ? (
            <div>
              <FaCircle
                className="status-indicator status-offline"
                style={{
                  color: `${getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_48')}`,
                }}
              />
              {
                constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_FAILED_LABEL
              }
            </div>
          ) : row?.status ===
            constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_COMPLETED ? (
            <div>
              <FaCircle
                className="status-indicator status-online"
                style={{
                  color: `${getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--success_48')}`,
                }}
              />
              {
                constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_COMPLETED_LABEL
              }
            </div>
          ) : row?.status ===
            constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_IN_PROGRESS ? (
            <div>
              <FaCircle
                className="status-indicator status-entered"
                style={{
                  color: `${getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--warning_48')}`,
                }}
              />
              {
                constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_IN_PROGRESS_LABEL
              }
            </div>
          ) : null}
        </div>
      );
    }
    return row?.[column];
  };

  const getRowWithTooltip = (row, column, classs) => {
    return (
      <Tooltip
        placement={'top-start'}
        arrow
        title={getTooltipData(row, column)}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -20],
                },
              },
            ],
            sx: {
              [`& .${tooltipClasses.tooltip}`]: {
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '16px',
                color: `var(--brand_white)`,
                background: `var(--brand_black)`,
                padding: '10px',
                fontFamily: 'Noto Sans',
                borderRadius: '8px',
              },
              [`& .${tooltipClasses.arrow}`]: {
                color: `var(--primary_08)`,
              },
            },
          },
        }}
      >
        {column === 'status' ? (
          <div className={`row-data ${classs}`}>
            {row?.status ===
            constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_NOT_STARTED ? (
              <>
                <FaCircle className="status-indicator status-offline" />
                {
                  constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_NOT_STARTED_LABEL
                }
              </>
            ) : row?.status ===
              constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_FAILED ? (
              <>
                <FaCircle className="status-indicator status-offline" />
                {
                  constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_FAILED_LABEL
                }
              </>
            ) : row?.status ===
              constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_COMPLETED ? (
              <>
                <FaCircle className="status-indicator status-online" />
                {
                  constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_COMPLETED_LABEL
                }
              </>
            ) : row?.status ===
              constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_IN_PROGRESS ? (
              <>
                <FaCircle className="status-indicator status-entered" />
                {
                  constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS_IN_PROGRESS_LABEL
                }
              </>
            ) : null}
          </div>
        ) : (
          <div className={`row-data ${classs}`}>{row?.[column]}</div>
        )}
      </Tooltip>
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="centred-loader">
          <SiteSpinner height="100px" width="100px"></SiteSpinner>
        </div>
      ) : restoredData?.length > 0 && !isLoading ? (
        <>
          <div className="table-data">
            <div className="table-header">
              <div className="header-title status">
                {constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_STATUS}
              </div>
              <div className="header-title channel-date">
                {constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_CHANNEL}
              </div>
              <div className="header-title channel-date">
                {constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_RESTORE_START}
              </div>
              <div className="header-title channel-date">
                {constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_RESTORE_END}
              </div>
              <div className="header-title channel-date">
                {constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_OUTAGE_START}
              </div>
              <div className="header-title channel-date">
                {constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_OUTAGE_END}
              </div>
            </div>
          </div>
          <div className="table-data">
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={fetchRestoredDataMore}
              hasMore={hasMore}
              useWindow={false}
              threshold={1000}
            >
              {restoredData.map((row) => (
                <div className="table-row">
                  {getRowWithTooltip(row, 'status', 'status')}
                  {getRowWithTooltip(row, 'dispalyName', 'channel-date')}
                  {getRowWithTooltip(
                    row,
                    'displayRestorationStartTime',
                    'channel-date'
                  )}
                  {getRowWithTooltip(
                    row,
                    'displayRestorationEndTime',
                    'channel-date'
                  )}
                  {getRowWithTooltip(
                    row,
                    'displayOutageStartTime',
                    'channel-date'
                  )}
                  {getRowWithTooltip(
                    row,
                    'displayOutageEndTime',
                    'channel-date'
                  )}
                </div>
              ))}
            </InfiniteScroll>
          </div>
          {isLoadingMore ? (
            <div className="loading">
              <SiteSpinner height={16} width={16} />
              {constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_LOADING}
            </div>
          ) : null}
        </>
      ) : (
        <div className="centred-loader mt-5 mb-5">
          {constants.CHANNELS_INFO_LOCAL_STORAGE_HISTORY_NO_DATA}
        </div>
      )}
    </>
  );
};

export default RestoredDevicesTable;
