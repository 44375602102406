import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { RiErrorWarningLine } from 'react-icons/ri';
import { RxCross1, RxPlus } from 'react-icons/rx';
import { ImCheckboxChecked } from 'react-icons/im';
import { PrimaryButton, SiteSpinner } from '../../../../components/common';
import { MdIndeterminateCheckBox } from 'react-icons/md';
import { Utils, constants } from '../../../../helpers';
import { checkUnslect } from '../../../../assets/images';
import { useSelector } from 'react-redux';
import {
  getAllMqttDataFromResponse,
  setDeviceInformation,
} from '../../../../store/reducers/AccountReducer';
import { ReactComponent as InformationCircle } from '../../../../assets/images/icons/InformationCircle.svg';
import { ReactComponent as CrossIcon } from '../../../../assets/images/icons/crossWithRound.svg';
import { ReactComponent as InvalidKeyIcon } from '../../../../assets/images/invalidKeyIcon.svg';
import publishDeviceSetting, {
  deleteDeviceSetting,
} from '../shared/PublishDeviceSettings';
import {
  getStreamBitmapData,
  getCDNInfo,
} from '../../../../store/reducers/StreamingReducer';
import nosnapshot from '../../../../assets/images/nosnapshot.svg';
import Line from './Line';
import {
  EventType,
  LineDirectionEnum,
  regexAnalytics,
} from '../../../../helpers/enums';
// import { getAllMqttDataFromResponse } from '../../../../store/AccountStoreIDB';
import { observerInstance } from '../../../../store/indexDB/observer';
import LineCrossingSnapShot from './LineCrossingSnapShot';
import CategoryEventSelection from './CategoryEventSelection';
import { v4 as uuidv4 } from 'uuid';

const LineCrossings = ({
  refResolution,
  accountId,
  deviceId,
  deviceInfos,
  devicePublishId,
  uuid,
  vehicleDetection,
  personDetection,
  capVirtualLine,
  lineCrossingsData,
  hideModelPopup,
}) => {
  const initialValues = {
    lineName: '',
    person: false,
    vehicle: false,
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
  });
  const dispatch = useDispatch();
  const watchedFields = watch();
  const formRef = useRef();
  const expiredTime = 20;
  const resourceList = Utils.getDeviceSettingResource(7);
  const MAX_REGION_TO_BE_ADDED_COUNT =
    +capVirtualLine?.['virtual-line-index']?.max + 1 || 1;
  const REGION_X_RANGE = +refResolution?.[0] || 1;
  const REGION_Y_RANGE = +refResolution?.[1] || 1;
  const colors = Utils.getAllVirtualLineColors();
  const [virtualLines, setVirtualLines] = useState(
    lineCrossingsData['virtual-lines'] || [],
  );
  const [lines, setLines] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [isAddLineClicked, setIsAddLineClicked] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [isLineCoordinateUpdated, setIsLineCoordinateUpdated] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [selectedLine, setSelectedLine] = useState(null);
  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  const [snapshot, setSnapshot] = useState('');
  const [virtualLineListObj, setVirtualLineListObj] = useState(
    Utils.getObjectTypesVirtualLine(capVirtualLine),
  );
  const [imageWidth, setImageWidth] = useState(0);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  // const [allMqttData, setAllMqttData] = useState([]);
  const bitmapObject = useSelector(getStreamBitmapData);
  const [isDeleteTriggered, setIsDeleteTriggered] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(1.7);
  const [isLineNameExist, setIsLineNameExist] = useState(false);
  const [lineDirection, setLineDirection] = useState(LineDirectionEnum.BOTH);
  const cdnInfo = useSelector(getCDNInfo);
  const watchedPerson = watch('person');
  const watchedVehicle = watch('vehicle');
  const [propertyCategory, setProperty] = useState({});
  const [propertyInitial, setPropertyInitial] = useState({});
  const [unsavedLine, setUnsavedLine] = useState('');
  const [initialZoneName, setInitialZoneName] = useState('');
  const [isObjectSelected, setIsAnyObjectSelected] = useState(false);
  const [islineDirectionClicked, setIslineDirectionClicked] = useState(false);

  useEffect(() => {
    const virtualLineIva = deviceInfos?.properties?.['virtual-line']
      ? JSON.parse(deviceInfos?.properties?.['virtual-line'])
      : { 'virtual-lines': [] };
    const selctedObjtTypes = virtualLineIva?.['virtual-lines']?.find(
      (item) => item?.['virtual-line-index'] == selectedLine?.index,
    );
    //setSelectedObjtType(selctedObjtTypes);
    const objeProperty = {
      'virtual-line-obj-person': selctedObjtTypes?.['virtual-line-obj-person'],
      'virtual-line-obj-vehicle':
        selctedObjtTypes?.['virtual-line-obj-vehicle'],
      'virtual-line-obj-vehicle_bicycle':
        selctedObjtTypes?.['virtual-line-obj-vehicle_bicycle'],
      'virtual-line-obj-vehicle_bus':
        selctedObjtTypes?.['virtual-line-obj-vehicle_bus'],
      'virtual-line-obj-vehicle_car':
        selctedObjtTypes?.['virtual-line-obj-vehicle_car'],
      'virtual-line-obj-vehicle_motorcycle':
        selctedObjtTypes?.['virtual-line-obj-vehicle_motorcycle'],
      'virtual-line-obj-vehicle_truck':
        selctedObjtTypes?.['virtual-line-obj-vehicle_truck'],
    };
    virtualLineListObj?.forEach((item) => {
      if (virtualLineIva && virtualLineIva?.['virtual-lines']?.length > 0) {
        const apiValue = selctedObjtTypes?.[item.keyName];
        if (apiValue !== undefined && !isDeleteTriggered) {
          item.isSlected = apiValue === 'true' ? '1' : '0'; // Update main category
        } else {
          item.isSlected = '0'; // Update vehicle type
        }
        // If the item is the Vehicle category, update vehicle types
        if (item.typeName === EventType?.VEHICLE && item?.vehicleTypes) {
          item?.vehicleTypes?.forEach((vehicle) => {
            const vehicleApiValue = selctedObjtTypes?.[vehicle.keyName];
            if (vehicleApiValue !== undefined && !isDeleteTriggered) {
              vehicle.isSlected = vehicleApiValue === 'true' ? '1' : '0'; // Update vehicle type
            } else {
              vehicle.isSlected = '0'; // Update vehicle type
            }
          });
        }
      } else {
        item.isSlected = '0'; // Update main category
        item?.vehicleTypes?.forEach((vehicle) => {
          vehicle.isSlected = '0'; // Update vehicle type
        });
      }
    });

    let anyUndefined = false;
    let objectSelected = false;
    for (let key in objeProperty) {
      if (objeProperty[key] === undefined) {
        anyUndefined = true;
        objeProperty[key] = 'false';
        // Change undefined values to "false"
      }
      if (objeProperty[key] === 'true') {
        objectSelected = true;
      }
    }
    if (objectSelected) {
      setIsAnyObjectSelected(false);
    } else {
      !islineDirectionClicked && setIsAnyObjectSelected(true);
    }
    if (anyUndefined && !islineDirectionClicked) {
      setPropertyInitial(objeProperty);
      setProperty(objeProperty);
    } else {
      setPropertyInitial(objeProperty);
    }
    setVirtualLineListObj(virtualLineListObj);
  }, [deviceInfos, selectedLine, isDeleteTriggered]);

  useEffect(() => {
    setVirtualLines(lineCrossingsData['virtual-lines'] || []);
  }, [isReload]);

  const eventTypeSelected = (publishData) => {
    if (Object.keys(propertyCategory)?.length == 0) {
      setPropertyInitial(publishData);
    }
    const isAnyPropertyTrue = Object.values(publishData).includes('true');
    if (isAnyPropertyTrue) {
      setIsAnyObjectSelected(false);
    } else {
      setIsAnyObjectSelected(true);
    }
    setProperty(publishData);
  };

  const isStateChangedProperty = () => {
    return Object.keys(propertyInitial).some((key) => {
      return propertyInitial[key] !== propertyCategory[key];
    });
  };

  useEffect(() => {
    if (isStateChangedProperty()) {
      setInitialZoneName('');
    } else {
      //TODO DELETE LATER
      //  setInitialZoneName(selectedLine?.lineName)
    }
  }, [propertyCategory]);

  useEffect(() => {
    const resource = allMqttData?.msg?.resource;
    if (resource?.includes(resourceList[6]) && resource?.includes(deviceId)) {
      const properties = allMqttData?.msg?.properties;
      const action = allMqttData?.msg?.action;
      if (action === 'error') {
        setRemainingTime(0);
        setErrorMsg(properties.desc);
      } else if (action === 'is') {
        setRemainingTime(0);
        const localStoreProperty =
          JSON.parse(localStorage.getItem('localStoreProperty')) || {};
        const updatedProperties = {
          ...localStoreProperty,
          'virtual-line': properties['virtual-line'],
        };
        localStorage.setItem(
          'localStoreProperty',
          JSON.stringify(updatedProperties),
        );
        dispatch(
          setDeviceInformation({
            ...deviceInfos,
            properties: {
              ...deviceInfos.properties,
              'virtual-line': properties['virtual-line'],
            },
          }),
        );
        setVirtualLines(
          JSON.parse(properties['virtual-line'])['virtual-lines'],
        );
        if (isDeleteTriggered) {
          setTimeout(() => {
            setIsReload(!isReload);
          }, 1000);
          setIsDeleteTriggered(false);
        } else if (showLoader) {
          hideModelPopup();
        }
      }
    }
  }, [allMqttData]);

  useEffect(() => {
    const updatedLines = lines.map((reg) => {
      return {
        ...reg,
        lineStyle: {
          ...reg.lineStyle,
          display: selectedLine?.lineColor === reg.lineColor ? 'block' : 'none',
        },
      };
    });
    setLines(updatedLines);
  }, [selectedLine]);

  useEffect(() => {
    const snapshotURL = fetchSnapshotCanvas(`canvas`, `video`);
    setSnapshot(snapshotURL);
  }, []);

  const fetchSnapshotCanvas = (typeCanvas, typeVideo) => {
    let canvas = document.getElementById(typeCanvas);
    let video = document.getElementById(typeVideo);
    const context = canvas?.getContext('2d');
    const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
    const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
    context?.drawImage(video, 0, 0, bitmapObjectWidth, bitmapObjectHeight);
    const data = canvas?.toDataURL('image/png');
    return data;
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setShowLoader(false);
        clearInterval(timerInterval);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [remainingTime]);

  useEffect(() => {
    setLineDirection(selectedLine?.lineDirection);
  }, [selectedLine]);

  const resetCoords = (linesData) => {
    const regionList = linesData?.map((reg) => {
      const r = virtualLines?.find(
        (item) => item?.['virtual-line-index'] == reg?.index,
      );
      if (r) {
        const dimensions = getNormalCoordinates(
          ...r['virtual-line-coordinates']?.split(','),
        );
        const index = r['virtual-line-index'];
        const color = colors[index];
        return {
          index: index,
          lineName: r['virtual-line-name'],
          person:
            r['virtual-line-obj-person'] === constants.PROPERTY_STATUS_TRUE,
          vehicle:
            r['virtual-line-obj-vehicle'] === constants.PROPERTY_STATUS_TRUE,
          lineDirection: getLineDirectionValue(r['virtual-line-direction']),
          lineColor: color,
          lineStyle: {
            border: `2px solid ${color}`,
          },
          isChanging: false,
          ...dimensions,
        };
      } else {
        return reg;
      }
    });
    if (regionList?.length) {
      setLines(regionList);
    }
  };

  useEffect(() => {
    if (REGION_X_RANGE && REGION_Y_RANGE && imageWidth) {
      setAspectRatio(REGION_X_RANGE / REGION_Y_RANGE);
      const lineList = virtualLines.map((r) => {
        const dimensions = getNormalCoordinates(
          ...r['virtual-line-coordinates']?.split(','),
        );
        const index = r['virtual-line-index'];
        const color = colors[index];
        return {
          index: index,
          lineName: r['virtual-line-name'],
          person:
            r['virtual-line-obj-person'] === constants.PROPERTY_STATUS_TRUE,
          vehicle:
            r['virtual-line-obj-vehicle'] === constants.PROPERTY_STATUS_TRUE,
          lineDirection: getLineDirectionValue(r['virtual-line-direction']),
          lineColor: color,
          lineStyle: {
            border: `2px solid ${color}`,
          },
          isChanging: false,
          ...dimensions,
        };
      });
      if (virtualLines.length === 0) {
        setSelectedLine(null);
        setLines([]);
      }
      if (lineList.length) {
        setLines(lineList);
        const updatedSelectedLine = lineList.find(
          (region) => region.lineColor === selectedLine?.lineColor,
        );
        updateLineAndForm(
          updatedSelectedLine
            ? updatedSelectedLine
            : lineList.length > 0
              ? lineList[0]
              : null,
        );
      }
    }
  }, [imageWidth, virtualLines]);

  const handleAddLineClick = () => {
    // If there is any region without name then it should not allow to add new region
    const withoutName = lines.filter(
      (x) =>
        x.lineName === '' || x.lineName === 'LineName' || x?.isAreaNotSaved,
    );
    if (withoutName.length > 0) {
      const unsavedObject = lines.find((item) => item.isAreaNotSaved);
      setUnsavedLine(unsavedObject.lineName);
      // formRef.current.requestSubmit();
      return;
    }
    const occupiedIndices = virtualLines.map((x) => x['virtual-line-index']);
    const index = Utils.getMinAvailableIndex(occupiedIndices);
    const color = colors[index];
    const initialLine = {
      startPoint: { x: 20, y: 100 },
      endPoint: { x: 100, y: 100 },
      lineName: 'LineName',
      isAreaNotSaved: true,
      person: false,
      vehicle: false,
      lineDirection: LineDirectionEnum.BOTH,
      lineColor: color,
      lineStyle: {
        border: `2px solid ${color}`,
      },
      isChanging: false,
      index: index,
    };
    setLines([...lines, initialLine]);
    resetCoords([...lines, initialLine]);
    updateLineAndForm(initialLine);
    setIsAddLineClicked(true);
  };

  useEffect(() => {
    if (lines?.length > 0) {
      setValue('lineName', getSelectedLine(false));
    }
    setIsAddLineClicked(false);
  }, [isAddLineClicked]);

  const onLineChange = (coordinates) => {
    const updatedLines = lines.map((x) => {
      if (x.lineColor === selectedLine?.lineColor) {
        if (
          selectedLine.startPoint.x !== coordinates.startPoint.x ||
          selectedLine.startPoint.y !== coordinates.startPoint.y ||
          selectedLine.endPoint.x !== coordinates.endPoint.x ||
          selectedLine.endPoint.y !== coordinates.endPoint.y
        ) {
          setInitialZoneName('');
          setIsLineCoordinateUpdated(true);
        }
        return { ...x, ...coordinates };
      } else {
        return x;
      }
    });
    setLines(updatedLines);
  };

  const getNextDirection = (direction) => {
    return (direction % 3) + 1;
  };

  const getLineDirectionKeyByValue = (value) => {
    switch (value) {
      case LineDirectionEnum.LEFT:
        return 'left';
      case LineDirectionEnum.RIGHT:
        return 'right';
      case LineDirectionEnum.BOTH:
        return 'both';
      default:
        return null;
    }
  };

  const getLineDirectionValue = (value) => {
    switch (value) {
      case 'left':
        return LineDirectionEnum.LEFT;
      case 'right':
        return LineDirectionEnum.RIGHT;
      case 'both':
        return LineDirectionEnum.BOTH;
      default:
        return null;
    }
  };

  const onLineDirectionChange = () => {
    const updatedLines = lines.map((x) => {
      if (x.lineColor === selectedLine?.lineColor) {
        setInitialZoneName('');
        setIsLineCoordinateUpdated(true);
        setLineDirection(getNextDirection(x.lineDirection));
        return { ...x, lineDirection: getNextDirection(x.lineDirection) };
      } else {
        return x;
      }
    });
    setLines(updatedLines);
    const updatedSelectedLine = updatedLines.find(
      (x) => x.lineColor === selectedLine?.lineColor,
    );
    if (updatedSelectedLine) {
      setSelectedLine({
        ...updatedSelectedLine,
        lineDirection: getNextDirection(selectedLine?.lineDirection),
      });
      setLineDirection(getNextDirection(selectedLine?.lineDirection));
    } else {
      setSelectedLine({
        ...selectedLine,
        lineDirection: getNextDirection(selectedLine?.lineDirection),
      });
      setLineDirection(getNextDirection(selectedLine?.lineDirection));
    }
  };

  const getActualCoordinates = (startPoint, endPoint) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const scaleX = parseInt(REGION_X_RANGE - 1) / divResolutionWidth;
    const scaleY = parseInt(REGION_Y_RANGE - 1) / divResolutionHeight;

    const actualStartPoint = {
      x: clampCoordinate('x', Math.round(Math.ceil(startPoint.x) * scaleX)),
      y: clampCoordinate('y', Math.round(Math.ceil(startPoint.y) * scaleY)),
    };

    const actualEndPoint = {
      x: clampCoordinate('x', Math.round(Math.ceil(endPoint.x) * scaleX)),
      y: clampCoordinate('y', Math.round(Math.ceil(endPoint.y) * scaleY)),
    };

    return { startPoint: actualStartPoint, endPoint: actualEndPoint };
  };

  const clampCoordinate = (type, coordinate) => {
    const maxValue = type === 'x' ? REGION_X_RANGE - 1 : REGION_Y_RANGE - 1;
    if (coordinate < 0) {
      return 0;
    } else if (coordinate > maxValue) {
      return maxValue;
    } else {
      return coordinate;
    }
  };

  const getNormalCoordinates = (SPx, SPy, EPx, EPy) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const scaleX = divResolutionWidth / parseInt(REGION_X_RANGE - 1);
    const scaleY = divResolutionHeight / parseInt(REGION_Y_RANGE - 1);

    const normalStartPoint = {
      x: SPx * scaleX,
      y: SPy * scaleY,
    };

    const normalEndPoint = {
      x: EPx * scaleX,
      y: EPy * scaleY,
    };

    return { startPoint: normalStartPoint, endPoint: normalEndPoint };
  };

  const getMsgProperties = (line) => {
    const index = colors.indexOf(line.lineColor) || 0;
    const jsonObject = {};
    const keyPrefix = `virtual-line`;
    jsonObject[`${keyPrefix}-index`] = index;
    jsonObject[`${keyPrefix}-uuid`] = uuidv4();
    jsonObject[`${keyPrefix}-index-enable`] = 'true';
    jsonObject[`${keyPrefix}-name`] = line.lineName;
    jsonObject[`${keyPrefix}-coordinates`] =
      `${line.startPoint.x},${line.startPoint.y},${line.endPoint.x},${line.endPoint.y}`;
    jsonObject[`${keyPrefix}-obj-person`] = line.person ? 'true' : 'false';
    jsonObject[`${keyPrefix}-obj-vehicle`] = line.vehicle ? 'true' : 'false';
    jsonObject[`${keyPrefix}-direction`] = line.lineDirection;
    return jsonObject;
  };

  const saveLineHandler = (values) => {
    setErrorMsg('');
    setShowLoader(true);
    setRemainingTime(expiredTime);
    try {
      const linesWithUpdatedCoordinates = lines.find(
        (line) => line.lineColor === selectedLine?.lineColor,
      );
      const actualCoordinates = getActualCoordinates(
        linesWithUpdatedCoordinates.startPoint,
        linesWithUpdatedCoordinates.endPoint,
      );
      let allObjectValues = {};
      const updatedSelectedLine = {
        ...linesWithUpdatedCoordinates,
        ...values,
        lineDirection: getLineDirectionKeyByValue(selectedLine?.lineDirection),
      };
      const msgProperties = getMsgProperties({
        ...updatedSelectedLine,
        ...actualCoordinates,
      });
      const mergeCategory = { ...msgProperties, ...propertyCategory };
      publishDeviceSetting(mergeCategory, resourceList[6], publishPayload);
      setSelectedLine(updatedSelectedLine);
    } catch (error) {
      Utils.vmsLogger().log('error', error);
      setShowLoader(false);
    }
  };

  const handleLineClick = (line) => {
    const unsavedObject = lines.find((item) => item.isAreaNotSaved);
    if (unsavedObject) {
      const updatedLines = lines.map((x) => {
        if (x.isAreaNotSaved) {
          x.lineName = `LineName ${lines?.length}`;
        }
      });
      setLines([...lines, updatedLines]);
    }
    setIsLineCoordinateUpdated(false);
    setIsAddLineClicked(true);
    updateLineAndForm(line);
    setUnsavedLine('');
    if (line?.isAreaNotSaved) {
      setInitialZoneName('');
    }
    resetCoords(lines);
  };

  const updateLineAndForm = (line) => {
    reset();
    const zoneName = line.lineName === 'LineName' ? '' : line.lineName;
    setInitialZoneName(zoneName); // Save the initial zone name when the region is selected
    setValue('lineName', zoneName);
    setValue('person', line.person);
    setValue('vehicle', line.vehicle);
    setSelectedLine(line);
  };

  const handleRemoveZone = (e, line) => {
    e.stopPropagation();
    try {
      const currentIndex = colors.indexOf(line.lineColor);
      if (virtualLines.find((q) => q['virtual-line-index'] === currentIndex)) {
        setErrorMsg('');
        setShowLoader(true);
        setRemainingTime(expiredTime);
        const msgProperties = {
          'virtual-line-index': currentIndex,
        };
        deleteDeviceSetting(msgProperties, resourceList[6], publishPayload);
        setIsDeleteTriggered(true);
      } else {
        const updatedLines = lines.filter(
          (item) => item.lineColor !== line.lineColor,
        );
        setLines(updatedLines);
        setSelectedLine(updatedLines.length > 0 ? updatedLines[0] : null);
        updateLineAndForm(updatedLines.length > 0 ? updatedLines[0] : null);
      }
    } catch (error) {
      Utils.vmsLogger().log('error', error);
      setShowLoader(false);
      setRemainingTime(0);
    }
  };

  const handleErrorMsgClose = () => {
    setErrorMsg('');
  };

  // useEffect(() => {
  //   if(!watchedFields?.lineName?.length){
  //     validateLineName(watchedFields?.lineName)
  //   }
  // },[watchedFields?.lineName])

  const validateLineName = (value) => {
    if (value.trim().length === 0) {
      return constants.LINE_CROSSING_POPUP_CREATE_LINE_ERROR;
    }

    if (
      lines.length &&
      lines.some(
        (item) => item.lineName.toUpperCase() === value.toUpperCase(),
      ) &&
      selectedLine?.lineName.toUpperCase() !== value.toUpperCase()
    ) {
      setIsLineNameExist(true);
      return constants.LINE_CROSSING_POPUP_DUPLICATE_LINE_NAME_ERROR;
    }
    setIsLineNameExist(false);
    return true;
  };

  const getSelectedLine = (isRemove) => {
    if (isRemove) {
      return lines?.find((line) => line?.lineColor === selectedLine?.lineColor);
    }
    // Check if lines exist
    if (!lines || lines?.length <= 0) {
      return '';
    }
    // Find the selected area based on region color
    const selectedLineArea = lines?.find(
      (line) => line?.lineColor === selectedLine?.lineColor,
    );
    // Return the appropriate Area based on conditions
    if (selectedLineArea) {
      return watchedFields.lineName || `LineName ${lines?.length}`;
    } else if (selectedLine?.lineName) {
      return selectedLine.lineName;
    }
    return '';
  };

  const updateLineDirection = (direction) => {
    const updatedLines = lines.map((x) => {
      if (x.lineColor === selectedLine?.lineColor) {
        setIsLineCoordinateUpdated(true);
        return { ...x, lineDirection: direction };
      } else {
        return x;
      }
    });
    setLines(updatedLines);
    const updatedSelectedLine = updatedLines.find(
      (x) => x.lineColor === selectedLine?.lineColor,
    );
    if (updatedSelectedLine) {
      setSelectedLine({
        ...updatedSelectedLine,
        lineDirection: direction,
      });
    } else {
      setSelectedLine({
        ...selectedLine,
        lineDirection: direction,
      });
    }
  };

  const handleSelectDirection = (direction) => {
    setInitialZoneName('');
    if (
      (direction === LineDirectionEnum.RIGHT &&
        lineDirection === LineDirectionEnum.LEFT) ||
      (direction === LineDirectionEnum.LEFT &&
        lineDirection === LineDirectionEnum.RIGHT)
    ) {
      setLineDirection(LineDirectionEnum.BOTH);
      updateLineDirection(LineDirectionEnum.BOTH);
    } else if (
      direction === LineDirectionEnum.RIGHT &&
      lineDirection !== LineDirectionEnum.LEFT
    ) {
      setLineDirection(LineDirectionEnum.RIGHT);
      updateLineDirection(LineDirectionEnum.RIGHT);
    } else if (
      direction === LineDirectionEnum.LEFT &&
      lineDirection !== LineDirectionEnum.RIGHT
    ) {
      setLineDirection(LineDirectionEnum.LEFT);
      updateLineDirection(LineDirectionEnum.LEFT);
    } else {
      setLineDirection(direction);
      updateLineDirection(direction);
    }
    setIslineDirectionClicked(true);
  };

  const handleDeSelectDirection = (direction) => {
    setInitialZoneName('');
    if (
      direction === LineDirectionEnum.RIGHT &&
      lineDirection === LineDirectionEnum.BOTH
    ) {
      setLineDirection(LineDirectionEnum.LEFT);
      updateLineDirection(LineDirectionEnum.LEFT);
    } else if (
      direction === LineDirectionEnum.LEFT &&
      lineDirection === LineDirectionEnum.BOTH
    ) {
      setLineDirection(LineDirectionEnum.RIGHT);
      updateLineDirection(LineDirectionEnum.RIGHT);
    }
    setIslineDirectionClicked(true);
  };

  const handleChangeZoneName = (e, selectedL) => {
    setValue('lineName', e.target.value);
    setLines((prevRegions) => {
      return prevRegions.map((region) =>
        region?.isAreaNotSaved && region?.index === selectedL?.index
          ? { ...region, lineName: e.target.value, isFieldAdded: true }
          : region,
      );
    });
  };

  const isZoneNameChanged = watchedFields.lineName !== initialZoneName;
  const selectedLineData = lines?.find(
    (line) => line.lineColor === selectedLine?.lineColor,
  );
  const checkLineName = selectedLineData
    ? watchedFields.lineName
    : selectedLineData?.isFieldAdded
      ? selectedLineData?.lineName
      : selectedLineData?.isAreaNotSaved
        ? `${selectedLineData?.lineName} ${lines?.length}`
        : selectedLineData?.lineName;
  return (
    <div className="component-container">
      <div className={`${showLoader ? 'disable-bg' : ''}`}></div>
      {showLoader && (
        <SiteSpinner
          height="50px"
          width="50px"
          styles={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            bottom: '50%',
            right: '50%',
            zIndex: 15,
          }}
        ></SiteSpinner>
      )}
      <div className="activity-zone-info">
        {constants.VIRTUAL_LINES_POPUP_TITLE_INFO}
      </div>
      <div className="hr-devider"></div>
      {errorMsg && (
        <div className="mb-4 error-conatiner">
          <div className="error-message-container">
            <RiErrorWarningLine className="icon-warning-col" />
            <div className="">{errorMsg}</div>
          </div>
          <RxCross1
            onClick={() => handleErrorMsgClose()}
            className="icon-warning-col"
          />
        </div>
      )}
      {!unsavedLine && lines && lines.length > 0 ? (
        <></>
      ) : (
        (unsavedLine || lineCrossingsData?.length > 0) && (
          <div className="mb-4 error-conatiner">
            <div className="error-message-container">
              <InformationCircle className="icon-error-col" />
              <div className="error-headline">
                {constants.YOU_HAVE_UNSAVED_LINES}
              </div>
            </div>
            <RxCross1
              onClick={() => setUnsavedLine('')}
              className="icon-warning-col"
            />
            <div className="unsaved">
              {checkLineName ? checkLineName : unsavedLine}
            </div>
          </div>
        )
      )}
      <div className="modal-sub-heading">
        {constants.LINE_CROSSING_POPUP_CREATE_LINES}
        {lines.length < MAX_REGION_TO_BE_ADDED_COUNT && (
          <PrimaryButton
            className="add-zone-btn add-line-btn"
            type="button"
            width="112px"
            fontSize="14px"
            fontWeight="600"
            lineHeight="16px"
            borderWidth="0"
            height="8px"
            marginLeft="auto"
            hoverBorderWidth="0"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40',
            )}
            hoverColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--primary_40')}
            disabledBackgroundColor="transparent"
            disabledColor={getComputedStyle(
              document.documentElement,
            ).getPropertyValue('--greyscale_72')}
            hoverBackgroundColor="transparent"
            backgroundColor="transparent"
            onClick={handleAddLineClick}
          >
            <RxPlus className="plus-icon-btn" strokeWidth={'1.5px'} size={16} />
            {constants.ANALYTICS_SETTINGS_POPUP_ADD_LINE}
          </PrimaryButton>
        )}
      </div>
      {lines.length ? (
        <div className="activity-zone-info">
          {' '}
          {constants.LINE_CROSSING_POPUP_CREATE_LINES_INFO}{' '}
        </div>
      ) : null}
      {
        <div className="regions-container mt-3 mb-3">
          <span className="regions-button-container">
            {lines.map((line, index) => (
              <PrimaryButton
                key={index}
                className={`region-btn ${
                  line.lineColor === selectedLine?.lineColor &&
                  'selected-region'
                }`}
                type="button"
                fontSize="14px"
                width="fit-content"
                lineHeight="16px"
                borderWidth="0"
                hoverBorderWidth="0"
                color={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('#656667')}
                hoverColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('#F6F6F6')}
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('#F6F6F6')}
                backgroundColor={getComputedStyle(
                  document.documentElement,
                ).getPropertyValue('#F6F6F6')}
                onClick={() => handleLineClick(line)}
              >
                <div
                  className="circle-dot"
                  style={{ backgroundColor: line.lineColor }}
                ></div>
                {line.lineColor === selectedLine?.lineColor
                  ? watchedFields.lineName
                  : line?.isFieldAdded
                    ? line?.lineName
                    : line?.isAreaNotSaved
                      ? `${line?.lineName}`
                      : line?.lineName}
              </PrimaryButton>
            ))}
          </span>
          {/* {lines.length < MAX_REGION_TO_BE_ADDED_COUNT && (
          <PrimaryButton
            className="add-zone-btn"
            type="button"
            width="112px"
            fontSize="14px"
            fontWeight="600"
            lineHeight="16px"
            borderWidth="0"
            hoverBorderWidth="0"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            disabledBackgroundColor="transparent"
            disabledColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--greyscale_72')}
            hoverBackgroundColor="transparent"
            backgroundColor="transparent"
            onClick={handleAddLineClick}
          >
            <RxPlus className="plus-icon-btn" strokeWidth={'1.5px'} size={16} />
            {constants.ANALYTICS_SETTINGS_POPUP_ADD_LINE}
          </PrimaryButton>
        )} */}
        </div>
      }
      {/* <div className="activity-zone-info">
      {lines?.length ? constants.LINE_CROSSING_POPUP_CREATE_LINES_INFO
      :constants.LINE_CROSSING_POPUP_CREATE_LINES_INFO_EDIT}
      </div> */}
      <div className="snap-shot-container">
        <div className="live-snapshot">
          {/* <img
            id="img-snapshot"
            src={snapshot}
            alt=""
            className={`live-snapshot-image ${
              aspectRatio === 1 ? 'image-aspect' : ''
            }`}
            onLoad={() => {
              setImageWidth(
                document.getElementById('img-snapshot')?.offsetWidth
              );
            }}
            onError={(event) => {
              event.target.src = nosnapshot;
            }}
          /> */}
          <LineCrossingSnapShot
            key={deviceInfos.deviceId}
            deviceElemId={`camera${deviceInfos.deviceId}`}
            orgId={deviceInfos?.orgId}
            cdnInfo={cdnInfo}
            locationAreaName={deviceInfos.locationAreaNames}
            isHighlighted={false}
            showZoomOptions={false}
            conStatus={deviceInfos.connectionStatus || 'offline'}
            hubId={deviceInfos.gatewayId}
            displayDeviceStatus={Utils.getDeviceStatus(
              deviceInfos?.deviceStatus,
              deviceInfos?.connectionStatus,
            )}
            setImageWidth={setImageWidth}
            aspectRatio={aspectRatio}
            {...deviceInfos}
          />
        </div>
        {selectedLine && (
          <Line
            containerWidth={
              document.getElementById('img-snapshot')?.offsetWidth
            }
            containerHeight={
              document.getElementById('img-snapshot')?.offsetHeight
            }
            selectedLine={selectedLine}
            onLineChange={(e) => onLineChange(e)}
            onChangeDirection={onLineDirectionChange}
          />
        )}
        {lines.length > 0 && (
          <svg
            width={document.getElementById('img-snapshot')?.offsetWidth}
            height={document.getElementById('img-snapshot')?.offsetHeight}
            style={{
              position: 'absolute',
              zIndex: 10,
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '50%',
            }}
          >
            {lines
              .filter((line) => line.lineColor !== selectedLine?.lineColor)
              .map((line) => (
                <line
                  key={line.lineColor}
                  x1={line.startPoint.x}
                  y1={line.startPoint.y}
                  x2={line.endPoint.x}
                  y2={line.endPoint.y}
                  stroke={line.lineColor}
                  strokeWidth="2"
                />
              ))}
          </svg>
        )}
      </div>
      <form ref={formRef} onSubmit={handleSubmit(saveLineHandler)}>
        {selectedLine && (
          <div className="add-zone-container">
            <div className="mb-4 mt-4 zone-input-wrapper">
              <div className="placeholder-wrap">
                <input
                  {...register('lineName', {
                    maxLength: 25,
                    //validate: validateLineName,
                    // pattern: {
                    //   value: regex,
                    //   message: constants.LINE_CROSSING_POPUP_SPECIAL_CHAR_ERROR,
                    // },
                  })}
                  onKeyDown={(e) => {
                    if (!regexAnalytics.test(e.key)) {
                      e.preventDefault();
                      return false;
                    }
                  }}
                  defaultValue={selectedLine?.lineName}
                  className="input-box"
                  autoFocus
                  maxLength={25}
                  onChange={(e) => handleChangeZoneName(e, selectedLine)} // Manually handle input changes
                />
                {!watchedFields.lineName && (
                  <div className="custom-placeholder">
                    {constants.LINE_CROSSING_POPUP_CREATE_LINES_PLACEHOLDER}
                    <span className="required">*</span>
                  </div>
                )}
              </div>
              <div className="line-crossing-count">
                {watchedFields.lineName.length || 0}/25
              </div>
              {errors.lineName && (
                <div className="error">{errors.lineName.message}</div>
              )}
            </div>
            <div className="modal-sub-heading">
              {constants.ANALYTICS_SETTINGS_POPUP_DETECTION_TYPE}
            </div>
            <div className="activity-zone-info">
              {constants.ANALYTICS_SETTINGS_POPUP_DETECTION_TYPE_INFO}
            </div>
            <CategoryEventSelection
              selectedRegion={selectedLine}
              saveBtnEnable={false}
              title=""
              deviceInfosProperty={deviceInfos}
              callBackMethod={(publishData) => {
                eventTypeSelected(publishData);
              }}
              eventListData={virtualLineListObj}
              islineDirectionClicked={islineDirectionClicked}
              setIslineDirectionClicked={setIslineDirectionClicked}
            />
            <div className="modal-sub-heading mt-4">
              {constants.VIRTUAL_LINE_DIRECTION}
            </div>
            <div className="activity-zone-info">
              {constants.VIRTUAL_LINE_DIRECTION_SUB_HEADING}
            </div>
            <div
              className={`direction-container mt-3 ${
                isObjectSelected ? 'disable' : ''
              }`}
            >
              <div className="direction-row">
                <div>
                  <label className="input-label" htmlFor={`A_TO_B`}>
                    {constants.DIRECTION_A_TO_B}
                  </label>
                </div>
                {lineDirection === LineDirectionEnum.RIGHT ||
                lineDirection === LineDirectionEnum.BOTH ? (
                  <ImCheckboxChecked
                    onClick={() => {
                      handleDeSelectDirection(LineDirectionEnum.RIGHT);
                    }}
                    size={20}
                    color={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--brand_primary')}
                  />
                ) : (
                  <img
                    className="img-icon"
                    alt=""
                    src={checkUnslect}
                    onClick={() => {
                      handleSelectDirection(LineDirectionEnum.RIGHT);
                    }}
                  />
                )}
              </div>
              <div className="direction-row mt-2">
                <div>
                  <label className="input-label" htmlFor={`B_TO_A`}>
                    {constants.DIRECTION_B_TO_A}
                  </label>
                </div>
                {lineDirection === LineDirectionEnum.LEFT ||
                lineDirection === LineDirectionEnum.BOTH ? (
                  <ImCheckboxChecked
                    onClick={() => {
                      handleDeSelectDirection(LineDirectionEnum.LEFT);
                    }}
                    size={20}
                    color={getComputedStyle(
                      document.documentElement,
                    ).getPropertyValue('--brand_primary')}
                  />
                ) : (
                  <img
                    className="img-icon"
                    alt=""
                    src={checkUnslect}
                    onClick={() => {
                      handleSelectDirection(LineDirectionEnum.LEFT);
                    }}
                  />
                )}
              </div>
            </div>
            {/* <div className="checkbox-container mt-3">
              <div>
                <label className="checkbox-container-label" htmlFor={`person`}>
                  {constants.DIRECTION_A_TO_B}
                </label>
              </div>
              <input
                className="checkbox-container-input-checkbox"
                {...register('person')}
                id={`person`}
                type="checkbox"
                defaultChecked={selectedLine?.person}
                disabled={!personDetection}
                checked={watchedPerson}
              />
            </div>
            <div className="checkbox-container">
              <div>
                <label className="checkbox-container-label" htmlFor={`person`}>
                  {constants.DIRECTION_B_TO_A}
                </label>
                {!personDetection && (
                  <div className="info-message-container">
                    <InvalidKeyIcon />
                    <div className="info-message">
                      {constants.DIRECTION_B_TO_A}
                    </div>
                  </div>
                )}
              </div>
              <input
                className="checkbox-container-input-checkbox"
                {...register('person')}
                id={`person`}
                type="checkbox"
                defaultChecked={selectedLine?.person}
                disabled={!personDetection}
                checked={watchedPerson}
              />
            </div> */}
            {/* <div className="checkbox-container mt-3">
              <div>
                <label className="checkbox-container-label" htmlFor={`person`}>
                  {constants.ANALYTICS_SETTINGS_POPUP_PERSON_LABEL}
                </label>
                {!personDetection && (
                  <div className="info-message-container">
                    <InvalidKeyIcon />
                    <div className="info-message">
                      {constants.ANALYTICS_SETTINGS_POPUP_PERSON_INFO_MESSAGE}
                    </div>
                  </div>
                )}
              </div>
              <input
                className="checkbox-container-input-checkbox"
                {...register('person')}
                id={`person`}
                type="checkbox"
                defaultChecked={selectedLine?.person}
                disabled={!personDetection}
                checked={watchedPerson}
              />
            </div>
            <div className="checkbox-container mt-3">
              <div>
                <label className="checkbox-container-label" htmlFor={`vehicle`}>
                  {constants.ANALYTICS_SETTINGS_POPUP_VEHICLE_LABEL}
                </label>
                {!vehicleDetection && (
                  <div className="info-message-container">
                    <InvalidKeyIcon />
                    <div className="info-message">
                      {constants.ANALYTICS_SETTINGS_POPUP_VEHICLE_INFO_MESSAGE}
                    </div>
                  </div>
                )}
              </div>
              <input
                className="checkbox-container-input-checkbox"
                {...register('vehicle')}
                id={`vehicle`}
                type="checkbox"
                defaultChecked={selectedLine?.vehicle}
                disabled={!vehicleDetection}
                checked={watchedVehicle}
              />
            </div> */}
          </div>
        )}
        {/* <PrimaryButton
          className="btn btn-primary mt-4"
          type="submit"
          width="100%"
          disabled={
            !isLineNameUpdated ||
            !selectedLine ||
            !watchedFields.lineName ||
            watchedFields?.lineName?.length > 64 ||
            !regex.test(watchedFields?.lineName.trim()) ||
            isLineNameExist
            // (!watchedFields.person && !watchedFields.vehicle)
          }
        >
          {constants.ANALYTICS_SETTINGS_POPUP_SAVE}
        </PrimaryButton> */}
        {lines.length ? (
          <div className="button-conatiner-excluded-area">
            <PrimaryButton
              className="btn btn-primary mt-4"
              type="button"
              width="auto"
              color={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--primary_40')}
              backgroundColor={getComputedStyle(
                document.documentElement,
              ).getPropertyValue('--brand_white')}
              disabled={
                !selectedLine ||
                !watchedFields.lineName ||
                watchedFields?.lineName?.length > 25
              }
              onClick={(e) => handleRemoveZone(e, getSelectedLine(true))}
            >
              {constants.DEVICES_TAB_NETWORK_MAXIMUM_DELETE +
                ' ' +
                getSelectedLine(false)}
            </PrimaryButton>
            <PrimaryButton
              className="btn btn-primary mt-4 btn-margin-save"
              type="submit"
              width="20%"
              disabled={
                !selectedLine ||
                !watchedFields.lineName ||
                watchedFields?.lineName?.length > 25 ||
                !isZoneNameChanged ||
                isObjectSelected
              }
            >
              {constants.ANALYTICS_SETTINGS_POPUP_SAVE}
            </PrimaryButton>
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default LineCrossings;
