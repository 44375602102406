import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { constants, Utils, roles } from '../../helpers';
import { Col, Form, Row } from 'react-bootstrap';
import { TextField } from '../../components/forms';
import * as Yup from 'yup';
import {
  AvatarList,
  PrimaryButton,
  SiteToast,
  TextBlock,
} from '../../components/common';
import Stepper from '../../components/common/Stepper';
import { HiUserCircle } from 'react-icons/hi2';
import { AiOutlineSearch } from 'react-icons/ai';
import useCustomRoles from '../../store/CustomRolesStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import axios from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import './NewRole.scss';

const NewRole = (props) => {
  const [step2Active, setStep2Active] = useState(false);
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [activeStep, setActiveStep] = useState('entitleSteps');
  const [textCount, setTextCount] = useState(0);
  const [loader, showLoader] = useState(false);
  const [loaderSkip, showLoaderSkip] = useState(false);
  const { entitlementData } = useCustomRoles();
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );
  let resultData = getCustomerOrgUsersData();
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  const [usersData, setUsersData] = useState([]);
  const [filterUserData, setFilterUserData] = useState(usersData);
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');

  const initialValues = {
    roleName: '',
    groupPolicies: [],
    accounts: [],
  };

  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required(constants.ROLE_NAME_REQUIRE_MESSAGE),
  });

  const steps = [
    {
      id: 'entitleSteps',
      active: true,
    },
    {
      id: 'userSteps',
      active: step2Active,
    },
  ];

  useEffect(() => {
    const users = resultData?.filter(
      (user) =>
        loggedInUser?.accountId !== user?.accountId &&
        user.role !== roles.ROLE1VMS
    );
    users.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    setUsersData(users);
    setFilterUserData(users);
  }, []);

  const handleCheckboxChange = (e, setFieldValue, values, id) => {
    const { checked, name, value } = e.target;

    if (activeStep === 'entitleSteps') {
      // for entitlement step
      if (checked) {
        setFieldValue('groupPolicies', [...values.groupPolicies, id]);
      } else {
        setFieldValue(
          'groupPolicies',
          values.groupPolicies.filter((v) => v !== id)
        );
      }
    } else {
      // for user step
      if (checked) {
        setFieldValue('accounts', [...values.accounts, id]);
      } else {
        setFieldValue(
          'accounts',
          values.accounts.filter((v) => v !== id)
        );
      }
    }
  };

  const handleTextChange = (event, setFieldValue) => {
    const valueLength = event.target.value.length;
    setTextCount(valueLength);
    setFieldValue('roleName', event.target.value);
  };

  const handleSearchChange = (event, setFieldValue) => {
    setFieldValue('search', event.target.value);
    if (event.target.value !== '') {
      const searchedData = usersData.filter((user) =>
        user.name.toLocaleLowerCase().includes(event.target.value.toLowerCase())
      );
      if (searchedData !== undefined || searchedData !== null) {
        setFilterUserData(searchedData);
      }
    } else {
      setFilterUserData(usersData);
    }
  };

  const submitCreateRole = async (values, setSubmitting) => {
    setSubmitting(false);
    setUserMsg('');
    if (activeStep === 'entitleSteps') {
      setActiveStep('userStep');
      setStep2Active(true);
    } else {
      const usersDataAdmin = usersData?.filter(
        (item) => item?.role === roles?.ROLE2VMS
      );
      let updateUserList = usersDataAdmin?.map((userAdmin, index) => {
        if (values?.accounts?.length > 0) {
          const accountIdExist = values?.accounts?.find(
            (item) => item === userAdmin?.accountId
          );
          if (accountIdExist) {
            return { ...userAdmin, isAdmin: true };
          } else {
            return { ...userAdmin, isAdmin: false };
          }
        } else {
          return {
            ...userAdmin,
            isAdmin: false,
          };
        }
      });
      let finalList = await Promise.all(updateUserList);
      const usersDataAdminSelected = finalList?.filter((item) => item?.isAdmin);
      if (usersDataAdmin?.length === usersDataAdminSelected?.length) {
        setUserMsg(constants.ALL_ADMIN_SELECTED_MESSAGE);
      } else {
        setSubmitting(true);
        showLoader(true);
        axios
          .post(`/partner/orgs/${orgId}/roles/withGroupPolicies`, values, {
            ...Utils.requestHeader(),
          })
          .then(async (res) => {
            setSubmitting(false);
            const response = res.data;
            if (response?.meta?.code === 200) {
              showLoader(false);
              await props.reloadData();
              props.hideModal();
            } else {
              setUserMsg(response?.meta?.userMsg);
              showLoader(false);
            }
          })
          .catch((err) => {
            setSubmitting(false);
            Utils.vmsLogger().log(err);
            setUserMsg(err);
            showLoader(false);
          });
      }
    }
  };

  const handleSkipRole = async (values) => {
    const newValues = { ...values };
    delete newValues['accounts'];
    setUserMsg('');
    showLoaderSkip(true);
    axios
      .post(`/partner/orgs/${orgId}/roles/withGroupPolicies`, newValues, {
        ...Utils.requestHeader(),
      })
      .then(async (res) => {
        const response = res.data;
        if (response?.meta?.code === 200) {
          showLoaderSkip(false);
          await props.reloadData();
          props.hideModal();
        } else {
          setUserMsg(response?.meta?.userMsg);
          showLoaderSkip(false);
        }
      })
      .catch((err) => {
        Utils.vmsLogger().log(err);
        setUserMsg(err);
        showLoaderSkip(false);
      });
  };

  const sourceString = constants.CREATE_ROLE_ENTITLEMENT_TEXT;
  const helpText = Utils.replaceTextWithLink(
    sourceString,
    '$help',
    constants.HELP_TEXT,
    Utils.helpClick
  );

  return (
    <section>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) =>
          submitCreateRole(values, setSubmitting)
        }
        enableReinitialize={false}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleBlur,
          handleSubmit,
          isSubmitting = false,
          isValid,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column gap-1">
              <TextField
                required={true}
                removebottommargin="true"
                removetopmargin="true"
                label=""
                placeholder={constants.NAME_TABLE_HEADER}
                name="roleName"
                onChange={(e) => handleTextChange(e, setFieldValue)}
                type="text"
                maxlength="20"
              />
              <TextBlock
                className="text-end pe-4"
                fontWeight="500"
                fontSize="0.75rem"
                lineHeight="1rem"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--greyscale_40')}
              >
                {`${textCount}`}/20
              </TextBlock>
            </div>
            <Stepper
              steps={steps}
              borderRadius="16px"
              background={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_primary')}
              height="4px"
              width="100%"
            ></Stepper>

            <TextBlock
              className="mt-4"
              fontWeight="600"
              fontSize="1.25rem"
              lineHeight="1.75rem"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_black')}
            >
              {activeStep === 'entitleSteps'
                ? constants.SELECT_ENTITLEMENT_TITLE
                : constants.SELECT_USER_TITLE}
            </TextBlock>

            <TextBlock
              className="mt-1"
              fontWeight="400"
              fontSize="0.875rem"
              lineHeight="1.25rem"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--greyscale_40')}
            >
              {activeStep === 'entitleSteps'
                ? helpText
                : constants.CREATE_ROLE_USER_TEXT}
            </TextBlock>

            {activeStep === 'entitleSteps' ? (
              <div className="partner-customers-list-wrap mt-2">
                {entitlementData?.map((entitlement) => {
                  return (
                    <div
                      className="partner-customers-list-item"
                      key={entitlement.groupId}
                    >
                      <label
                        className="partner-customers-list-item-label"
                        htmlFor={`customer-${entitlement.groupId}`}
                      >
                        {entitlement.groupName}
                      </label>
                      <input
                        className="role-checkbox"
                        name={`entitlements${entitlement.groupId}`}
                        type="checkbox"
                        checked={values.groupPolicies.includes(
                          entitlement.groupId
                        )}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            setFieldValue,
                            values,
                            entitlement.groupId
                          )
                        }
                        value={entitlement.groupId}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="partner-customers-list-wrap mt-3">
                <TextField
                  beforeinputicon={
                    <AiOutlineSearch
                      style={{ height: '20px', width: '20px' }}
                    />
                  }
                  label=""
                  placeholder={constants.TOP_HEADER_SEARCH_NAV_TITLE}
                  name="search"
                  type="text"
                  onChange={(e) => handleSearchChange(e, setFieldValue)}
                  style={{ marginBottom: '0.5rem' }}
                />
                {filterUserData?.map((user) => {
                  return (
                    <div
                      className="partner-customers-list-item"
                      key={user.accountId}
                    >
                      <label
                        className="partner-customers-list-item-label d-flex flex-row align-items-center"
                        htmlFor={`customer-${user.accountId}`}
                      >
                        {user?.image && user.image?.url !== '' ? (
                          <AvatarList
                            avatarValueType="icon"
                            avatarValue={user.image?.url}
                          />
                        ) : (
                          <HiUserCircle
                            style={{
                              height: '32px',
                              width: '32px',
                              marginRight: '8px',
                            }}
                          />
                        )}
                        {user.name}
                      </label>
                      <input
                        className="role-checkbox"
                        name={`entitlements${user.accountId}`}
                        type="checkbox"
                        checked={values.accounts.includes(user.accountId)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            setFieldValue,
                            values,
                            user.accountId
                          )
                        }
                        value={user.accountId}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            <Form.Group as={Row} className="mt-3">
              <Col>
                <PrimaryButton
                  className="btn btn-primary w-100"
                  type="submit"
                  disabled={
                    !(dirty && isValid) ||
                    (activeStep === 'entitleSteps' &&
                      values.groupPolicies.length === 0) ||
                    (activeStep !== 'entitleSteps' &&
                      usersData?.length === 0) ||
                    (activeStep !== 'entitleSteps' &&
                      values.accounts?.length === 0)
                  }
                  loader={loader}
                  height="44px"
                  fontSize="0.875rem"
                >
                  {activeStep === 'entitleSteps'
                    ? constants.CREATE_ROLE_NEXT_BUTTON_TEXT
                    : constants.CREATE_ROLE_SUBMIT_BUTTON_TEXT}
                </PrimaryButton>
              </Col>
              {activeStep !== 'entitleSteps' && (
                <Col>
                  <PrimaryButton
                    className="btn btn-primary-outline-NewRole w-100"
                    type="button"
                    height="44px"
                    fontSize="0.875rem"
                    loader={loaderSkip}
                    onClick={() => handleSkipRole(values)}
                  >
                    {constants.NOTIFICATION_CREATE_SKIP_BTN}
                  </PrimaryButton>
                </Col>
              )}
            </Form.Group>
            <SiteToast
              title={
                variant === 'error'
                  ? constants.ERROR_TOAST_TITLE
                  : constants.SUCCESS_TOAST_TITLE
              }
              show={!!userMsg}
              body={userMsg}
              variant={variant}
              position="top-center"
            />
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default NewRole;
